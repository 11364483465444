import { Auth, Storage } from 'aws-amplify';

export function configureAmplify() {
  Auth.configure({
    mandatorySignIn: false, // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    identityPoolId: process.env.REACT_APP_identityPoolId,
    region: process.env.REACT_APP_COGNITO_REGION, // REQUIRED - Amazon Cognito Region
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID, // OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure fl ag is set to true, then the cookie transmission
    // requires a secure protocol
    // cookieStorage: {
    //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //   domain: 'localhost', // domain: '.yourdomain.com',
    //   // OPTIONAL - Cookie path
    //   path: '/',
    //   // OPTIONAL - Cookie expiration in days
    //   expires: 365,
    //   // OPTIONAL - Cookie secure flag
    //   // Either true or false, indicating if the cookie transmission requires
    //   // a secure protocol (https).
    //   secure: false
    // }
  });

  Storage.configure({
    bucket: process.env.REACT_APP_Bucket_name,
    region: process.env.REACT_APP_region,
    identityPoolId: process.env.REACT_APP_identityPoolId
  });
}

// Configure Storage with S3 bucket information
export function SetS3Config(bucket, level) {
  Storage.configure({
    AWSS3: {
      bucket,
      level,
      region: 'us-east-1',
      identityPoolId: process.env.REACT_APP_identityPoolId
    }
  });
}

export function getCurrentDate() {
  const date = new Date();
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : `0${month}`;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : `0${day}`;

  let hours = date.getHours().toString();
  hours = hours.length > 1 ? hours : `0${hours}`;

  let minutes = date.getMinutes().toString();
  minutes = minutes.length > 1 ? minutes : `0${minutes}`;

  let seconds = date.getSeconds().toString();
  seconds = seconds.length > 1 ? seconds : `0${seconds}`;

  let milliseconds = date.getMilliseconds().toString();
  milliseconds = milliseconds.length > 1 ? milliseconds : `0${milliseconds}`;

  return `${month}${day}${year}_${hours}${minutes}${seconds}${milliseconds}`;
}

export function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
}
