import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';

import styles from './styles';
import { matchingNdcWasteClassSelector } from '../../../../../redux/reducers/controlCenter/ndcBounceReducer';

const headerTexts = ['category', 'count', 'percentage'];

const headerCells = headerTexts.map(headerText => {
  const textAlign = headerText === 'category' ? 'left' : 'right';
  return (
    <Table.HeaderCell
      key={headerText}
      style={{ ...styles.tableCell, ...styles.headerCell }}
      rowSpan="1"
      textAlign={textAlign}
    >
      {headerText}
    </Table.HeaderCell>
  );
});

const rows = rowData =>
  rowData.map((record, _) => (
    <Table.Row key={record.name} style={styles.tableRow}>
      <Table.Cell key={`${record.name}`} style={styles.tableCell}>
        {record.name}
      </Table.Cell>
      <Table.Cell textAlign="right" key={`${record.count}`} style={styles.tableCell}>
        {record.count}
      </Table.Cell>
      <Table.Cell textAlign="right" key={`${record.percent}`} style={styles.tableCell}>
        {record.percent}
      </Table.Cell>
    </Table.Row>
  ));

const AnalysisCategoryTable = ({ rowData }) => {
  return (
    <Table basic="very" className="table" columns={3} compact singleLine size="small" style={styles.table}>
      <Table.Header style={styles.tableHeader}>
        <Table.Row style={styles.tableRow}>{headerCells}</Table.Row>
      </Table.Header>

      <Table.Body style={styles.tableBody}>{rows(rowData)}</Table.Body>
    </Table>
  );
};

AnalysisCategoryTable.propTypes = {
  rowData: PropTypes.array.isRequired
};

const mapState = state => {
  const rowData = matchingNdcWasteClassSelector(state.ndcBounce);

  return { rowData };
};

export default connect(mapState)(AnalysisCategoryTable);
