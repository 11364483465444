import React, { useState, useEffect, useCallback } from 'react';
import { Table, Checkbox, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { readString, CSVDownloader } from 'react-papaparse';

import CustomTable from '../../../../components/presentational/ControlCenterTable';
import colors from '../../../../styles/colors';
import FormularyNdcBounce from '../Formulary/NdcBounce';
import Paginator from '../../../../components/presentational/Pagination';
import SearchBar from './SearchBar';
import OrgPlanModal from './OrgPlanModal';
import ButtonText from '../../../../components/presentational/ButtonText/ButtonText';
import ConfirmationModal from '../../../../components/presentational/ConfirmationModal';
import ProgessModal from '../../../../components/presentational/ProgessModal';

import { LOCATION_HEADERS, LOCATION_ORDER_FIELDS, locationToTable } from './mappers';
import AllowedDomainsModal from '../../../../components/container/AllowedEmailDomainsModal/AllowedEmailDomainsModal';
import { getOrgPlans } from '../../../../API/plans';
import { removeOrgSite } from '../../../../API/organizations';

const accept = '.csv';

const SEARCH_BY_OPTIONS = [
  { key: 'all', text: 'All', value: 'all' },
  { key: 'parent', text: 'Parent Name', value: 'parent' },
  { key: 'name', text: 'Name of site', value: 'name' },
  { key: 'address', text: 'Address', value: 'address' },
  { key: 'city', text: 'City', value: 'city' },
  { key: 'state', text: 'State', value: 'state' },
  { key: 'customer', text: 'Trilogy Customer #', value: 'customer' },
  { key: 'siteId', text: 'Trilogy Site ID', value: 'siteId' }
];

const OrgDetail = ({ organization }) => (
  <div className="list-container fade flex-2">
    <Table basic="very" className="table main-table" columns={3} compact singleLine size="small">
      <Table.Body className="body">
        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            Name
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.name}
          </Table.Cell>
        </Table.Row>

        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            Facility Name
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.facility_name}
          </Table.Cell>
        </Table.Row>

        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            Parent 1
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.parent_org_1}
          </Table.Cell>
        </Table.Row>

        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            Parent 2
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.parent_org_2}
          </Table.Cell>
        </Table.Row>

        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            Main Phone
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.phone_main}
          </Table.Cell>
        </Table.Row>

        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            Account Type
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.account_type &&
              `${organization.account_type.name} (${organization.account_type.abbreviation})`}
          </Table.Cell>
        </Table.Row>

        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            Subscription Active
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.subscription_active ? 'Yes' : 'No'}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
    <Table basic="very" className="table main-table" columns={3} compact singleLine size="small">
      <Table.Body className="body">
        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            Address
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.address.address_line_1} {organization.address.address_line_2}
          </Table.Cell>
        </Table.Row>

        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            City
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.address.city}
          </Table.Cell>
        </Table.Row>

        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            State
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.address.state}
          </Table.Cell>
        </Table.Row>

        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            Zip
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.address.zip_code}
          </Table.Cell>
        </Table.Row>

        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            Tax ID #
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.tax_id_num}
          </Table.Cell>
        </Table.Row>

        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            BIRDEYES CUSTOMER #
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.behc_org_num}
          </Table.Cell>
        </Table.Row>

        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            TRILOGY CUSTOMER #
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.trilogy_org_num}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  </div>
);

OrgDetail.propTypes = {
  organization: PropTypes.object.isRequired
};

const handleUploadFile = (file, setFileFilters) => {
  const reader = new FileReader();

  reader.onload = () => {
    const csvData = readString(reader.result).data;
    const headers = csvData.shift();
    const result = {};
    headers.forEach((header, idx) => {
      result[header.replace(/\s/g, '_')] = csvData.map(row => row[idx]);
    });
    setFileFilters(result);
  };
  reader.readAsText(file);
};

const OrgDetailView = ({
  data,
  organization,
  selectItem,
  getOrgDetail,
  locations,
  onSearch,
  filterValue,
  setFilterValue,
  filterName,
  setFilterName,
  onSort,
  orderBy,
  setError
}) => {
  const [showDomainModal, setShowDomainModal] = useState(false);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [currentPlan, setCurrentPlan] = useState();
  const [errors, setErrors] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showProcessModal, setShowProcessModal] = useState(false);
  const [currentProcess, setCurrentProcess] = useState(0);
  const [filterFileData, setFilterFileData] = useState();

  const getCurrentPlan = async () => {
    const plans = await getOrgPlans(data.id);
    getOrgDetail(data.id);
    setCurrentPlan(plans.data && plans.data[0]);
  };
  const setFileFilters = file => {
    onSearch(data.id, { file }, 1);
    setFilterFileData(file);
  };

  const onDropAccepted = useCallback(acceptedFiles => {
    handleUploadFile(acceptedFiles[0], setFileFilters);
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDropAccepted,
    accept,
    noClick: true,
    noKeyboard: true
  });

  const deleteLocation = async ({ id, name }) => {
    try {
      await removeOrgSite(data.id, id, 'deleteOrphanUsers=true');
    } catch (_e) {
      setErrors([...errors, `Something went wrong removing ${name}, please try again!`]);
    }
  };

  const deleteLocations = async (locationsToDelete, counter) => {
    if (!locationsToDelete.length) {
      return;
    }
    await deleteLocation(locationsToDelete.shift());
    setCurrentProcess(counter + 1);
    await deleteLocations(locationsToDelete, counter + 1);
  };

  const deleteSelectedLocations = async () => {
    setShowProcessModal(true);
    await deleteLocations([...selectedLocations], 0);
    const params = filterValue ? { search: { name: filterName, value: filterValue } } : {};
    onSearch(data.id, params, locations.currentPage);
  };

  useEffect(() => {
    getOrgDetail(data.id);
    getCurrentPlan();
    if (!locations) onSearch(data.id, {}, 1);
  }, []);

  const search = (name, value) => {
    const params = value ? { search: { name, value } } : {};
    onSearch(data.id, params, 1);
  };

  const paginate = page => {
    const params = filterValue ? { search: { name: filterName, value: filterValue } } : {};
    onSearch(data.id, params, page);
  };

  return (
    <div>
      <div className="detail-header">
        <div>
          <ButtonText
            className="formulary-link"
            customStyles={{
              backgroundColor: colors.blueThree,
              color: colors.white
            }}
            onClick={() => {
              const { org_domain } = organization.organization_subdomain;
              const hostParts = window.location.host.split('.');
              const baseHost = hostParts.slice(hostParts.length - 2).join('.');

              window.open(`${window.location.protocol}//${org_domain}.${baseHost}/home/apps`, '_blank');
            }}
          >
            Formulary App
          </ButtonText>
          <ButtonText
            className="formulary-link"
            customStyles={{
              backgroundColor: colors.blueThree,
              color: colors.white,
              marginLeft: '0.5em'
            }}
            onClick={() => setShowDomainModal(true)}
          >
            Email Domains
          </ButtonText>
          <div className="plan-detail">
            <span>
              Plan:{' '}
              {currentPlan && organization && !organization.has_full_access ? currentPlan.plan.name : 'Full Access'}
            </span>
            <ButtonText
              className="formulary-link"
              customStyles={{
                backgroundColor: colors.blueThree,
                color: colors.white
              }}
              onClick={() => setShowPlanModal(true)}
            >
              Set plan
            </ButtonText>
          </div>
        </div>
        <Table basic="very" columns={2} compact singleLine size="small" className="table main-table totals-table">
          <Table.Body className="body">
            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                Total Locations
              </Table.HeaderCell>
              <Table.Cell
                className="cell"
                style={{
                  backgroundColor: colors.blueDarkTwo,
                  color: colors.white
                }}
              >
                {locations ? locations.total : 0}
              </Table.Cell>
            </Table.Row>
            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                Total Users
              </Table.HeaderCell>
              <Table.Cell
                className="cell"
                style={{
                  backgroundColor: colors.blueDarkTwo,
                  color: colors.white
                }}
              >
                {organization ? organization.member_count : 0}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>

      {!!organization && <OrgDetail organization={organization} />}

      {locations && (
        <div className="list-container fade">
          <div
            {...getRootProps({
              className: `dropzone search-container ${isDragActive ? 'active' : ''}`
            })}
          >
            <input {...getInputProps()} />
            {!isDragActive && !filterFileData && (
              <div className="search-bar">
                <SearchBar
                  search={search}
                  setName={setFilterName}
                  setValue={setFilterValue}
                  name={filterName}
                  value={filterValue}
                  dropdownOption={SEARCH_BY_OPTIONS}
                />
                <div className="upload-container">
                  <button type="button" onClick={open} className="upload-btn" style={{ color: colors.blueThree }}>
                    Upload CSV
                  </button>
                  <span>or Drag and Drop the file here.</span>
                  <CSVDownloader
                    filename="site-filters-csv-template"
                    data={[
                      {
                        name: '(Optional) site name, acronym or facility_name',
                        address: '(Optional) site full Address',
                        city: '(Optional) site address city',
                        state: '(Optional) site address state',
                        customer: '(Optional) site customer #',
                        siteId: '(Optional) site id'
                      }
                    ]}
                    style={{ cursor: 'pointer' }}
                  >
                    {' '}
                    Download the Filter Template
                  </CSVDownloader>
                </div>
              </div>
            )}
            {isDragActive && (
              <div className="hint-message">
                <label>Drop the file here to use it to filter user...</label>
              </div>
            )}
            {!isDragActive && filterFileData && (
              <div className="hint-message">
                <label>Filtered by file</label>
                {Object.keys(filterFileData).map(key => (
                  <div>
                    {key}: {filterFileData[key].length}
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() => setFileFilters(null)}
                  className="upload-btn"
                  style={{ color: colors.blueThree }}
                >
                  Clear
                </button>
              </div>
            )}
          </div>
          <h2>Locations: ({locations.total})</h2>
          <div className="actions">
            <ButtonText
              className="submit-btn"
              customStyles={{
                backgroundColor: selectedLocations.length ? colors.red : colors.grayLightTwo,
                color: colors.white,
                width: '180px'
              }}
              disabled={!selectedLocations.length}
              onClick={() => setShowDeleteConfirmation(true)}
            >
              Delete Selected ({selectedLocations.length})
            </ButtonText>
          </div>
          <CustomTable
            headers={[
              <Checkbox
                checked={selectedLocations.length >= 1}
                onClick={() => {
                  setSelectedLocations(selectedLocations.length >= locations.data.length ? [] : locations.data);
                }}
                label={
                  selectedLocations.length >= 1 && selectedLocations.length < locations.data.length ? (
                    <Icon name="minus" />
                  ) : null
                }
              />,
              ...LOCATION_HEADERS
            ]}
            onSort={onSort}
            orderBy={orderBy}
            className="sortable locations selectable"
            availablesOrders={LOCATION_ORDER_FIELDS}
            data={locations.data.map(row => {
              const isSelected = selectedLocations.some(location => location.id === row.id);
              return {
                cells: [
                  <Checkbox
                    checked={isSelected}
                    onClick={e => {
                      e.stopPropagation();
                      if (isSelected) {
                        setSelectedLocations(selectedLocations.filter(location => location.id !== row.id));
                      } else {
                        setSelectedLocations([...selectedLocations, row]);
                      }
                    }}
                  />,
                  ...locationToTable(row)
                ],
                onSelect: () => selectItem(row)
              };
            })}
          />
          <div className="paginator-container">
            <Paginator
              onNext={() => paginate(locations.currentPage + 1)}
              onPrev={() => paginate(locations.currentPage - 1)}
              page={locations.currentPage}
              totalPages={locations.totalPages}
            />
          </div>
        </div>
      )}

      {!!organization && (
        <div className="fade">
          <FormularyNdcBounce hideHeaders orgId={organization.id} orgName={organization.name} />
        </div>
      )}

      {showDomainModal && organization ? (
        <AllowedDomainsModal organization={organization || null} onClose={() => setShowDomainModal(false)} />
      ) : null}
      {!!showPlanModal && (
        <OrgPlanModal
          onClose={() => setShowPlanModal(false)}
          onUpdate={() => {
            getCurrentPlan();
            setShowPlanModal(false);
          }}
          currentPlan={currentPlan}
          orgId={data.id}
          setError={setError}
        />
      )}
      {showDeleteConfirmation && (
        <ConfirmationModal onClose={() => setShowDeleteConfirmation(false)} onAccept={deleteSelectedLocations}>
          Are you sure you want to remove these users?
        </ConfirmationModal>
      )}
      {(showProcessModal || errors.length) && (
        <ProgessModal
          errors={errors}
          total={selectedLocations.length}
          current={currentProcess}
          resourceName="locations"
          onClose={() => {
            setShowProcessModal(false);
            setErrors([]);
            setSelectedLocations([]);
            setCurrentProcess(0);
          }}
        />
      )}
    </div>
  );
};

OrgDetailView.propTypes = {
  data: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  selectItem: PropTypes.func.isRequired,
  getOrgDetail: PropTypes.func.isRequired,
  locations: PropTypes.object.isRequired,
  onSearch: PropTypes.func.isRequired,
  filterValue: PropTypes.string.isRequired,
  setFilterValue: PropTypes.func.isRequired,
  filterName: PropTypes.string.isRequired,
  setFilterName: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  setError: PropTypes.func.isRequired
};

export default OrgDetailView;
