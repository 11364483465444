import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actionCreators';

import colors from '../../../styles/colors';
import PageHeader from '../../presentational/PageHeader/PageHeader';
import BEDropdownIcon from '../../presentational/BEDropdownIcon/BEDropdownIcon';
import PageContent from '../../presentational/PageContent/PageContent';
import ControlsResultsWrapper from '../../presentational/ControlsResultsWrapper/ControlsResultsWrapper';
import FilterContainer from '../FilterContainer/FilterContainer';
import ResultsContainer from '../ResultsContainer/ResultsContainer';
import iconList from '../../../Assets/Images/icon-list.png';

const formularyAdminPath = '/formulary-admin';

class RecordsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      view: 'table'
    };
  }

  async componentDidMount() {
    const { history, location, match } = this.props;
    await this.props.r_setQueryStateFromUrl(history, location, match);
    this.props.r_fetchOrgs();
    this.props.r_fetchSites();
    this.props.r_queryFormulary(history);
  }

  toggleView() {
    this.setState(
      currentState => ({
        view: currentState.view === 'table' ? 'visual' : 'table'
      }),
      () => {
        this.props.history.push({
          pathname: `${formularyAdminPath}/${this.state.view}/results`,
          search: this.props.location.search
        });
      }
    );
  }

  setSearchBy(option) {
    this.setState(currentState => ({
      query: {
        ...currentState.query,
        search_by: option
      }
    }));
  }

  search() {
    this.props.history.push({
      pathname: `${formularyAdminPath}/${this.state.view}/results`,
      search: `?search_by=${this.state.query.search_by}&search_query=${this.state.query.search_query}`
      // state: { some: 'state' }
    });
  }

  static queryToUrl(query) {
    const querySections = [];
    Object.keys(query).forEach(key => {
      if (
        // value is not falsey and not an empty object
        query[key] &&
        !(
          Object.entries(query[key]).length === 0 &&
          query[key].constructor === Object
        )
      ) {
        if (key === 'filters') {
          const filtersString = RecordsContainer.filterObjToUrl(query[key]);
          if (filtersString) {
            querySections.push(filtersString);
          }
        } else {
          querySections.push(`${key}=${query[key]}`);
        }
      }
    });

    return querySections.length ? `?${querySections.join('&')}` : '';
  }

  static filterObjToUrl(filters) {
    const filterSections = [];
    Object.keys(filters).forEach(key => {
      if (filters[key].length) {
        filterSections.push(`${key}=${filters[key].join(',')}`);
      }
    });

    if (filterSections.length) {
      return `filters=${filterSections.join(';')}`;
    }
    return '';
  }

  applyFilters(filterObj) {
    const newQuery = {
      ...this.state.query,
      filters: {
        ...this.state.query.filters,
        ...filterObj
      }
    };
    const queryUrl = RecordsContainer.queryToUrl(newQuery);
    this.props.history.push({
      pathname: `${formularyAdminPath}/${this.state.view}/results`,
      search: queryUrl
    });
  }

  groupBy(group) {
    const newQuery = {
      ...this.state.query,
      group_by: group
    };
    const queryUrl = RecordsContainer.queryToUrl(newQuery);
    this.props.history.push({
      pathname: `${formularyAdminPath}/${this.state.view}/results`,
      search: queryUrl
    });
  }

  removeAllFilters() {
    const newQuery = {
      ...this.state.query,
      filters: {}
    };
    const queryUrl = RecordsContainer.queryToUrl(newQuery);
    this.props.history.push({
      pathname: `${formularyAdminPath}/${this.state.view}/results`,
      search: queryUrl
    });
  }

  printFilters() {
    if (!this.state.query) {
      return '';
    }
    let str = '';
    const { filters } = this.state.query;
    Object.keys(filters).forEach(key => {
      str += `\n${key}: ${filters[key].join(', ')}`;
    });
    return str;
  }

  handleSearchInputChange(e) {
    this.setState(prevState => ({
      ...prevState,
      query: {
        ...prevState.query,
        search_query: e.target.value
      }
    }));
  }

  // getFormularySites() {
  //   const formularyAppId = 1;
  //   const siteOptions = this.props.entities
  //     .filter(appInstance => appInstance.app.app_id === formularyAppId)
  //     .map(appOption => {
  //       return {
  //         key: appOption.site_id,
  //         value: appOption.site_id,
  //         text: appOption.site.name,
  //         icon: appOption.app.icon_url
  //       };
  //     });
  //   return siteOptions;
  // }

  render() {
    const allOrgsOption = {
      key: 'all',
      value: 'all',
      text: 'MASTER FORMULARY'
    };
    const orgOptions = this.props.orgs.map(org => {
      return {
        key: org.id,
        value: org.id,
        text: org.name
      };
    });
    orgOptions.unshift(allOrgsOption);

    const allSitesOption = {
      key: 'all',
      value: 'all',
      text: 'ALL SITES'
    };
    const siteOptions = this.props.sites.map(site => {
      return {
        key: site.id,
        value: site.id,
        text: site.name
      };
    });
    siteOptions.unshift(allSitesOption);

    return (
      <div>
        {!this.props.defaultFilters ? (
          <div style={styles.loadingView}>
            <Loader active inline="centered">
              Initializing...
            </Loader>
          </div>
        ) : (
          <>
            <PageHeader
              headerContent={
                <div style={styles.dropdownContainer}>
                  <BEDropdownIcon
                    value={this.props.orgId}
                    icon={iconList}
                    onSelect={async value => {
                      await this.props.r_setOrg(value);
                      this.props.r_fetchSites();
                      this.props.r_fetchDefaultFilters();
                      this.props.r_queryFormulary(this.props.history);
                    }}
                    options={orgOptions}
                  />
                  <div style={styles.slash}>/</div>
                  <BEDropdownIcon
                    value={this.props.siteId}
                    icon={iconList}
                    onSelect={async value => {
                      await this.props.r_setSite(value);
                      this.props.r_queryFormulary(this.props.history);
                    }}
                    options={siteOptions}
                  />
                </div>
              }
            />
            <PageContent>
              <ControlsResultsWrapper
                filterComponent={<FilterContainer />}
                resultsComponent={<ResultsContainer />}
              />
            </PageContent>
          </>
        )}
      </div>
    );
  }
}

RecordsContainer.propTypes = {
  defaultFilters: PropTypes.object,
  r_queryFormulary: PropTypes.func.isRequired,
  r_setQueryStateFromUrl: PropTypes.func.isRequired,
  r_fetchOrgs: PropTypes.func.isRequired,
  records: PropTypes.shape({
    total: PropTypes.number,
    perPage: PropTypes.number,
    page: PropTypes.number,
    lastPage: PropTypes.number,
    data: PropTypes.array
  }),
  orgs: PropTypes.array.isRequired,
  r_setOrg: PropTypes.func.isRequired,
  r_fetchSites: PropTypes.func.isRequired,
  r_fetchDefaultFilters: PropTypes.func.isRequired,
  orgId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  sites: PropTypes.array.isRequired,
  siteId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  r_setSite: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

RecordsContainer.defaultProps = {
  records: {
    data: []
  },
  defaultFilters: null
};

const styles = {
  loadingView: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  appTitle: {
    color: colors.white
  },
  dropdownContainer: {
    display: 'flex',
    margin: '24px auto',
  },
  slash: {
    width: '26px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '34px',
    color: colors.white,
    paddingBottom: '8px'
  }
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state, props) => {
  const { defaultFilters } = state.defaults;
  const { orgId, orgs, siteId, sites } = state.query;
  const { fetchingRecords, records } = state.queryResults;

  return {
    ...props,
    defaultFilters,
    fetchingRecords,
    records,
    orgs,
    orgId,
    sites,
    siteId
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RecordsContainer)
);
//
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(RecordsContainer);
