import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actionCreators';

import ModalWrapper from '../../presentational/ModalWrapper/ModalWrapper';
import ModalHeader from '../../presentational/ModalHeader/ModalHeader';
import ModalBody from '../../presentational/ModalBody/ModalBody';
import BEInput from '../../presentational/BEInput/BEInput';

/** Styles */
import colors from '../../../styles/colors';
import iconRemove from '../../../Assets/Images/icon-remove.png';
import ButtonText from '../../presentational/ButtonText/ButtonText';
import {
  addOrgAllowedEmailDomain,
  getOrgAllowedEmailDomains,
  removeOrgAllowedEmailDomain
} from '../../../API/organizations';

function AllowedEmailDomainsModal({ organization, onClose }) {
  const [currentDomain, setCurrentDomain] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [domains, setDomains] = useState([]);
  const [error, setError] = useState(null);

  const isValidDomain = domain => {
    if (!domain) {
      setError(`The domain can't be empty`);
      return false;
    }

    /** Regex Validator: https://regexr.com/659bm */
    const domainRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;
    if (!domain.match(domainRegex)) {
      setError('The domain is invalid (use "example.com" format)');
      return false;
    }

    const isDuplicated = domains.some(d => d.name.toLowerCase() === domain.toLowerCase());
    if (isDuplicated) {
      setError('The current domain already exists for this organization');
      return false;
    }

    setError(null);
    return true;
  };

  const fetchAllowedDomains = async () => {
    try {
      if (!organization) return;
      const { data } = await getOrgAllowedEmailDomains(organization.id);
      setDomains(data);
    } catch (e) {
      console.log({ e });
      setError(e.message || JSON.stringify(error));
    }
  };

  const createDomain = async newDomain => {
    try {
      if (!isValidDomain(newDomain.toLowerCase())) return;
      // TODO: Validate domain doesn't exist
      setIsLoading(true);

      await addOrgAllowedEmailDomain(organization.id, {
        allowedEmailDomain: newDomain
      });

      await fetchAllowedDomains();
    } catch (e) {
      setError(e.message || JSON.stringify(error));
    } finally {
      setIsLoading(false);
      setCurrentDomain('');
    }
  };

  const removeDomain = async domainId => {
    try {
      await removeOrgAllowedEmailDomain(organization.id, domainId);
      await fetchAllowedDomains();
    } catch (e) {
      setError(`Error removing domain: ${e.message || JSON.stringify(error)}`);
    } finally {
      setCurrentDomain('');
    }
  };

  const renderDomainTables = () => {
    return (
      <div className="list-container fade flex-2">
        <Table basic="very" className="table main-table" columns={3} compact singleLine size="small">
          <Table.Body className="body">
            {domains
              ? domains.map(d => {
                  return (
                    <Table.Row className="row" key={d.id}>
                      <Table.HeaderCell className="cell header-cell" style={styles.domainColumn} rowSpan="1">
                        {d.name}
                      </Table.HeaderCell>
                      <Table.Cell className="cell" style={styles.removeColumn} onClick={() => removeDomain(d.id)}>
                        <img style={{ width: 27, height: 30 }} src={iconRemove} alt="checkmark" />
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              : null}
          </Table.Body>
        </Table>
      </div>
    );
  };

  useEffect(() => {
    fetchAllowedDomains();
  }, []);

  return (
    <ModalWrapper
      className="dark-modal"
      customStyles={{
        modal: styles.modal
      }}
    >
      <ModalHeader title="These are the allowed domains" onClose={() => onClose()} />
      <ModalBody confirmation={false}>
        <div>
          <p style={styles.infoText}>
            Add domains to restrict invitations and logins (if no domains are added there are not restrictions){' '}
          </p>
          <div style={styles.modalBody}>
            <BEInput
              // label="Domain"
              value={currentDomain}
              onChange={e => setCurrentDomain(e.target.value.toLowerCase())}
            />
            <ButtonText
              className="formulary-link"
              isLoading={isLoading}
              customStyles={{
                backgroundColor: colors.blueThree,
                color: colors.white,
                marginLeft: '1em'
              }}
              onClick={() => createDomain(currentDomain)}
            >
              <span className="button__text">Add</span>
            </ButtonText>
          </div>
          {error ? (
            <div>
              <p style={styles.errorMessage}>{error}</p>
            </div>
          ) : null}
          {renderDomainTables()}
        </div>
      </ModalBody>
    </ModalWrapper>
  );
}

const styles = {
  modal: {
    backgroundColor: colors.blueFive,
    color: 'white'
  },
  modalBody: {
    display: 'flex',
    alignItems: 'center'
  },
  infoText: {
    fontSize: '18px',
    margin: '1em 0'
  },
  removeColumn: {
    width: '100px',
    maxWidth: '100px',
    textAlign: 'center',
    backgroundColor: colors.blueTwo,
    color: colors.white,
    cursor: 'pointer'
  },
  domainColumn: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.blueDarkTwo,
    color: colors.white
  },
  errorMessage: {
    fontSize: '15px',
    fontFamily: 'Nunito-SemiBold',
    color: 'red',
    height: '20px'
  }
};

AllowedEmailDomainsModal.propTypes = {
  organization: PropTypes.shape(PropTypes.any).isRequired,
  onClose: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default withRouter(connect(mapDispatchToProps)(AllowedEmailDomainsModal));
