import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

import ModalWrapper from '../../../../components/presentational/ModalWrapper/ModalWrapper';
import ModalHeader from '../../../../components/presentational/ModalHeader/ModalHeader';
import ModalBody from '../../../../components/presentational/ModalBody/ModalBody';
import ModalFooter from '../../../../components/presentational/ModalFooter/ModalFooter';
import ButtonText from '../../../../components/presentational/ButtonText/ButtonText';
import colors from '../../../../styles/colors';
import { getDate, mapPlanToSelect, dateToString } from '../../helpers';
import { getPlans, assignMemberPlan, grantMemberFullAccess } from '../../../../API/plans';

const UserPlanModal = ({ currentPlan, onClose, onUpdate }) => {
  const [error, setError] = useState();
  const [plan, setPlan] = useState({ ...(currentPlan || {}) });
  const [plans, setPlans] = useState([]);

  const loadPlans = async () => {
    try {
      const response = await getPlans('member');
      setPlans(mapPlanToSelect(response.data));
    } catch (_e) {
      console.log('error');
    }
  };

  const updatePlan = async () => {
    try {
      if (!plan.plan_id || !plan.start_at) {
        setError('Please fill all required field');
        return;
      }
      await assignMemberPlan(plan.organization_id, plan.user_id, plan);
      onUpdate();
    } catch (e) {
      setError('Something went wrong, please try again!');
    }
  };

  const removePlan = async () => {
    try {
      await grantMemberFullAccess(plan.organization_id, plan.user_id);
      onUpdate();
    } catch (e) {
      setError('Something went wrong, please try again!');
    }
  };

  useEffect(() => {
    loadPlans();
  }, []);

  return (
    <ModalWrapper className="dark-modal" customStyles={{ modal: { backgroundColor: colors.blueFive } }}>
      <ModalHeader title="User plan" onClose={onClose} />
      <ModalBody confirmation={false}>
        {!!error && <div className="error">{error}</div>}
        <Form className="dark-form" inverted>
          <Form.Select
            fluid
            label="Plan"
            options={plans}
            placeholder="Plan"
            value={plan.plan_id}
            onChange={(_e, { value }) => setPlan({ ...plan, plan_id: value })}
          />
          <Form.Group widths="equal">
            <Form.Field className="datepicker">
              <label>Start Date</label>
              <SemanticDatepicker
                onChange={(_e, { value }) => setPlan({ ...plan, start_at: dateToString(value) })}
                value={plan.start_at && getDate(plan.start_at)}
              />
            </Form.Field>
            <Form.Field className="datepicker">
              <label>End Date (Empty to Unlimited)</label>
              <SemanticDatepicker
                onChange={(_e, { value }) => setPlan({ ...plan, end_at: dateToString(value) })}
                value={plan.end_at && getDate(plan.end_at)}
                minDate={(plan.start_at && getDate(plan.start_at)) || Date.now()}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </ModalBody>
      <ModalFooter>
        <div className="flex justify-content">
          <ButtonText className="submit-btn" onClick={onClose}>
            Cancel
          </ButtonText>
          <ButtonText
            className="submit-btn"
            customStyles={{
              backgroundColor: colors.blueThree,
              color: colors.white
            }}
            onClick={updatePlan}
          >
            Save
          </ButtonText>
          <ButtonText
            className="submit-btn big"
            customStyles={{
              backgroundColor: colors.green,
              color: colors.white
            }}
            onClick={removePlan}
          >
            Grant Full Access
          </ButtonText>
        </div>
      </ModalFooter>
    </ModalWrapper>
  );
};

UserPlanModal.propTypes = {
  currentPlan: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
};

export default UserPlanModal;
