import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import colors from '../../../../styles/colors';

const Breadcrumb = ({ setSelectedItem, selectedItem }) => (
  <div className="breadcrumb-container" style={{ color: colors.white }}>
    <button
      className="breadcrumb-item"
      style={{
        color: !selectedItem ? colors.white : colors.blueThree
      }}
      onClick={() => setSelectedItem(null)}
    >
      Customers
    </button>
    {!!selectedItem && (
      <>
        {selectedItem.organization && (
          <>
            <Icon name="chevron right" />
            <button
              className="breadcrumb-item"
              onClick={() => setSelectedItem(selectedItem.organization)}
              style={{
                color: colors.blueThree
              }}
            >
              {selectedItem.organization.name}
            </button>
          </>
        )}
        <Icon name="chevron right" />
        <span className="breadcrumb-item">{selectedItem.name}</span>
      </>
    )}
  </div>
);

Breadcrumb.propTypes = {
  setSelectedItem: PropTypes.func.isRequired,
  selectedItem: PropTypes.object.isRequired
};

export default Breadcrumb;
