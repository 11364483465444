import React, { useState, useRef, useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import colors from '../../../styles/colors';
import sassStyles from './BETableRowDropdown.module.scss';
import validator from 'validator';

/**
 * Hook that detects clicks outside of the passed ref
 */
function useOutsideClickDetector(ref, didClickOutside) {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      didClickOutside(event.target);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

const toDisplayDate = text => {
  if (!text) {
    return '';
  }
  // sanitize: Remove +1, remove any non-numerical values
  let value = text
    .trim()
    .replace(/\D/g, '')
    .substring(0, 8);

  if (value.length > 2 && value.length <= 4)
    value = `${value.slice(0, 2)}/${value.slice(2)}`;
  else if (value.length > 4)
    value = `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4)}`;

  return value;
};

/**
 * BETableRowDropdown Component
 */

function BETableRowDropdown({
  inputType,
  allowNoSelection,
  options,
  onSelect,
  displayValue,
  value,
  width,
  error,
  search, // a boolean - whether or not the dropdown is a search selection or normal
  searchInputDidChange,
  showAddOption, // a boolean - whether or not the dropdown allows you to add a new value
  onAddOption
}) {
  const [isOpen, toggleOpen] = useState(true);
  const [searchText, setSearchText] = useState('');

  const selectionRef = useRef(null);
  const dropdownMenuRef = useRef(null);

  const componentDidMount = async () => {
    // If it's a Search Selection component, focus the input element
    if (search) {
      setTimeout(() => {
        if (selectionRef.current) {
          selectionRef.current.focus();
        }
      }, 50);
    }
  };

  useEffect(() => {
    componentDidMount();
    // eslint-disable-next-line
  }, []);

  // Called whenever user clicks outside the dropdown menu
  const onClickOutside = clickTarget => {
    const didClickDropdownButton = selectionRef.current.contains(clickTarget);
    if (!didClickDropdownButton) {
      // prevents a double toggle (the dropdown button will call the
      // toggleOpen() function as well)
      toggleOpen(false);
    }
  };

  useOutsideClickDetector(dropdownMenuRef, onClickOutside);

  let dropdownItems = [];

  // If the attribute exists as a dropdown option, don't show
  // the "add <attribute name>" option
  const attributeAlreadyExists = options.find(
    option => option.text === searchText
  );

  const searchTextIsValidDate = () => {
    let refactoredDate = searchText.split('/').reverse();
    const tmp = refactoredDate[2];
    refactoredDate[2] = refactoredDate[1];
    refactoredDate[1] = tmp;
    refactoredDate = refactoredDate.join('-');
    return validator.isISO8601(refactoredDate);
  };

  if (
    showAddOption &&
    !attributeAlreadyExists &&
    (inputType === 'date' ? searchText.length > 9 : searchText.length > 1)
  ) {
    dropdownItems.unshift(
      <>
        {inputType !== 'date' || searchTextIsValidDate() ? (
          <div
            style={styles.dropdownItem}
            className={sassStyles.DropdownItem}
            key="addOption"
            onClick={async () => {
              await onAddOption(searchText);
              // Wait until the new attribute has been saved
              // before closing the dropdown
              toggleOpen(false);
            }}
            onKeyPress={() => {
              onAddOption(searchText);
              toggleOpen(false);
            }}
            role="button"
            tabIndex="0"
          >
            <p
              style={{
                color: colors.black
              }}
            >
              {`Create  `}
            </p>
            <p
              style={{
                color: colors.black,
                fontWeight: 600,
                marginLeft: '5px'
              }}
            >
              {`${searchText}`}
            </p>
          </div>
        ) : (
          <div
            style={styles.dropdownItem}
            className={sassStyles.DropdownItem}
            key="addOption"
          >
            <p
              style={{
                color: colors.grayDark
              }}
            >
              {`invalid date`}
            </p>
          </div>
        )}
      </>
    );
  }

  if (allowNoSelection) {
    dropdownItems.push(
      <div
        style={styles.dropdownItem}
        className={sassStyles.DropdownItem}
        key="makeNull"
        onClick={() => {
          onSelect(null);
          toggleOpen(false);
        }}
        onKeyPress={() => {
          onSelect(null);
          toggleOpen(false);
        }}
        role="button"
        tabIndex="0"
      >
        <p
          style={{
            color: colors.black
          }}
        >
          {`Set to NO VALUE`}
        </p>
      </div>
    );
  }

  dropdownItems = [
    ...dropdownItems,
    options.map((option, index) => {
      const isSelected = option.value === value;
      return (
        <div
          style={styles.dropdownItem}
          className={sassStyles.DropdownItem}
          key={option.key}
          onClick={() => {
            onSelect(option.value);
            toggleOpen(false);
          }}
          onKeyPress={() => {
            onSelect(option.value);
            toggleOpen(false);
          }}
          role="button"
          tabIndex="0"
        >
          <p
            style={{
              color: isSelected ? colors.bluePrimary : colors.black
            }}
          >
            {option.text}
          </p>
        </div>
      );
    })
  ];

  const displayText =
    (value === null && ' ') ||
    (options.length &&
      options.find(option => option.value === value) &&
      options.find(option => option.value === value).text) ||
    displayValue;

  // If the dropdown is a Search Selection
  if (search) {
    return (
      <div style={{ ...styles.container, width: `${width}px` }}>
        <div
          style={{
            ...styles.selectionContainer,
            ...(error && { borderBottom: `1.5px solid ${colors.red}` })
          }}
          className={sassStyles.SelectionContainer}
        >
          {' '}
          {inputType === 'date' ? (
            <input
              ref={selectionRef}
              type="text"
              placeholder={displayText || 'MM/DD/YYYY'}
              value={isOpen ? searchText : displayText}
              onChange={e => {
                setSearchText(toDisplayDate(e.target.value));
                searchInputDidChange(toDisplayDate(e.target.value));
              }}
              onFocus={() => {
                setSearchText('');
                toggleOpen(true);
              }}
              style={styles.input}
            />
          ) : (
            <input
              ref={selectionRef}
              type="text"
              value={isOpen ? searchText : displayText}
              placeholder={displayText}
              onChange={e => {
                setSearchText(e.target.value);
                searchInputDidChange(e.target.value);
              }}
              onFocus={() => {
                setSearchText('');
                toggleOpen(true);
              }}
              style={styles.input}
            />
          )}
        </div>

        {isOpen && (
          <div
            ref={dropdownMenuRef}
            style={styles.dropdown}
            className={sassStyles.DropDown}
          >
            {dropdownItems}
          </div>
        )}
      </div>
    );
  }

  // Otherwise if it's a normal dropdown:

  return (
    <div style={styles.container}>
      <div
        ref={selectionRef}
        onClick={() => toggleOpen(!isOpen)}
        onKeyPress={() => toggleOpen(!isOpen)}
        role="button"
        tabIndex="0"
        style={{
          ...styles.selectionContainer,
          ...(error && { borderBottom: `1.5px solid ${colors.red}` })
        }}
        className={sassStyles.SelectionContainer}
      >
        <p style={styles.title}>{displayText}</p>
        <Icon style={styles.icon} name="chevron down" size="small" />
      </div>

      {isOpen && (
        <div
          ref={dropdownMenuRef}
          style={styles.dropdown}
          className={sassStyles.DropDown}
        >
          {dropdownItems}
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    position: 'relative',
    flex: 1
  },
  selectionContainer: {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: colors.grayDark,
    cursor: 'pointer',
    borderBottom: `1.5px solid ${colors.bluePrimary}`
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '10px',
    color: colors.black
  },
  icon: {
    color: colors.bluePrimary
  },
  dropdown: {
    position: 'absolute',
    zIndex: 3,
    marginTop: '5px',
    backgroundColor: colors.white,
    boxShadow: '0 7px 73px rgba(0, 0, 0, 0.12)',
    width: '100%',
    maxHeight: '200px',
    overflow: 'auto'
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 10px',
    cursor: 'pointer'
  },
  checkbox: {
    height: '15px',
    width: '15px',
    borderRadius: '3px',
    border: `1px solid ${colors.bluePrimary}`
  },
  input: {
    height: '100%',
    fontFamily: 'Nunito-Regular',
    fontSize: '15px',
    color: colors.black,
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    width: '100%'
  }
};

BETableRowDropdown.propTypes = {
  inputType: PropTypes.string,
  allowNoSelection: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  displayValue: PropTypes.string.isRequired,
  width: PropTypes.number,
  error: PropTypes.bool,
  search: PropTypes.bool,
  searchInputDidChange: PropTypes.string.isRequired,
  showAddOption: PropTypes.bool,
  onAddOption: PropTypes.func.isRequired
};

BETableRowDropdown.defaultProps = {
  inputType: '',
  width: null,
  error: false,
  search: false,
  showAddOption: false
};

export default BETableRowDropdown;
