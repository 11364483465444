const colors = {
  nonmetal: 'rgba(255, 255, 0, 0.3)',
  halogen: 'rgba(255, 255, 0, 0.3)',
  alkailMetal: 'rgba(255, 0, 0, 0.25)',
  alkalineEarthMetal: 'rgba(64, 64, 255, 0.25)',
  nobleGas: 'rgba(255, 152, 0, 0.3)',
  metalloid: 'rgba(140, 190, 0, 0.3)',
  postTransitionMetal: 'rgba(0, 255, 0, 0.3)',
  transitionMetal: 'rgba(0, 128, 255, 0.3)',
  lanthanide: 'rgba(0, 255, 255, 0.35)',
  actinide: 'rgba(64, 255, 192, 0.35)'
};

export default [
  [
    { name: 'Hydrogen', symbol: 'H', number: 1, color: colors.nonmetal },
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    { name: 'Helium', symbol: 'He', number: 2, color: colors.nobleGas }
  ],
  [
    { name: 'Lithium', symbol: 'Li', number: 3, color: colors.alkailMetal },
    { name: 'Beryllium', symbol: 'Be', number: 4, color: colors.alkalineEarthMetal },
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    { name: 'Boron', symbol: 'B', number: 5, color: colors.metalloid },
    { name: 'Carbon', symbol: 'C', number: 6, color: colors.nonmetal },
    { name: 'Nitrogen', symbol: 'N', number: 7, color: colors.nonmetal },
    { name: 'Oxygen', symbol: 'O', number: 8, color: colors.nonmetal },
    { name: 'Fluorine', symbol: 'F', number: 9, color: colors.halogen },
    { name: 'Neon', symbol: 'Ne', number: 10, color: colors.nobleGas }
  ],
  [
    { name: 'Sodium', symbol: 'Na', number: 11, color: colors.alkailMetal },
    { name: 'Magnesium', symbol: 'Mg', number: 12, color: colors.alkalineEarthMetal },
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    { name: 'Aluminum', symbol: 'Al', number: 13, color: colors.postTransitionMetal },
    { name: 'Silicon', symbol: 'Si', number: 14, color: colors.metalloid },
    { name: 'Phosphorus', symbol: 'P', number: 15, color: colors.nonmetal },
    { name: 'Sulfur', symbol: 'S', number: 16, color: colors.nonmetal },
    { name: 'Chlorine', symbol: 'Cl', number: 17, color: colors.halogen },
    { name: 'Argon', symbol: 'Ar', number: 18, color: colors.nobleGas }
  ],
  [
    { name: 'Potassium', symbol: 'K', number: 19, color: colors.alkailMetal },
    { name: 'Calcium', symbol: 'Ca', number: 20, color: colors.alkalineEarthMetal },
    { name: 'Scandium', symbol: 'Sc', number: 21, color: colors.transitionMetal },
    { name: 'Titanium', symbol: 'Ti', number: 22, color: colors.transitionMetal },
    { name: 'Vanadium', symbol: 'V', number: 23, color: colors.transitionMetal },
    { name: 'Chromium', symbol: 'Cr', number: 24, color: colors.transitionMetal },
    { name: 'Manganese', symbol: 'Mn', number: 25, color: colors.transitionMetal },
    { name: 'Iron', symbol: 'Fe', number: 26, color: colors.transitionMetal },
    { name: 'Cobalt', symbol: 'Co', number: 27, color: colors.transitionMetal },
    { name: 'Nickel', symbol: 'Ni', number: 28, color: colors.transitionMetal },
    { name: 'Copper', symbol: 'Cu', number: 29, color: colors.transitionMetal },
    { name: 'Zinc', symbol: 'Zn', number: 30, color: colors.transitionMetal },
    { name: 'Gallium', symbol: 'Ga', number: 31, color: colors.postTransitionMetal },
    { name: 'Germanium', symbol: 'Ge', number: 32, color: colors.metalloid },
    { name: 'Arsenic', symbol: 'As', number: 33, color: colors.metalloid },
    { name: 'Selenium', symbol: 'Se', number: 34, color: colors.nonmetal },
    { name: 'Bromine', symbol: 'Br', number: 35, color: colors.halogen },
    { name: 'Krypton', symbol: 'Kr', number: 36, color: colors.nobleGas }
  ],
  [
    { name: 'Rubidium', symbol: 'Rb', number: 37, color: colors.alkailMetal },
    { name: 'Strontium', symbol: 'Sr', number: 38, color: colors.alkalineEarthMetal },
    { name: 'Yttrium', symbol: 'Y', number: 39, color: colors.transitionMetal },
    { name: 'Zirconium', symbol: 'Zr', number: 40, color: colors.transitionMetal },
    { name: 'Niobium', symbol: 'Nb', number: 41, color: colors.transitionMetal },
    { name: 'Molybdenum', symbol: 'Mo', number: 42, color: colors.transitionMetal },
    { name: 'Technetium', symbol: 'Tc', number: 43, color: colors.transitionMetal },
    { name: 'Ruthenium', symbol: 'Ru', number: 44, color: colors.transitionMetal },
    { name: 'Rhodium', symbol: 'Rh', number: 45, color: colors.transitionMetal },
    { name: 'Palladium', symbol: 'Pd', number: 46, color: colors.transitionMetal },
    { name: 'Silver', symbol: 'Ag', number: 47, color: colors.transitionMetal },
    { name: 'Cadmium', symbol: 'Cd', number: 48, color: colors.transitionMetal },
    { name: 'Indium', symbol: 'In', number: 49, color: colors.postTransitionMetal },
    { name: 'Tin', symbol: 'Sn', number: 50, color: colors.postTransitionMetal },
    { name: 'Antimony', symbol: 'Sb', number: 51, color: colors.metalloid },
    { name: 'Tellurium', symbol: 'Te', number: 52, color: colors.metalloid },
    { name: 'Iodine', symbol: 'I', number: 53, color: colors.halogen },
    { name: 'Xenon', symbol: 'Xe', number: 54, color: colors.nobleGas }
  ],
  [
    { name: 'Cesium', symbol: 'Cs', number: 55, color: colors.alkailMetal },
    { name: 'Barium', symbol: 'Ba', number: 56, color: colors.alkalineEarthMetal },
    null,
    { name: 'Hafnium', symbol: 'Hf', number: 72, color: colors.transitionMetal },
    { name: 'Tantalum', symbol: 'Ta', number: 73, color: colors.transitionMetal },
    { name: 'Tungsten', symbol: 'W', number: 74, color: colors.transitionMetal },
    { name: 'Rhenium', symbol: 'Re', number: 75, color: colors.transitionMetal },
    { name: 'Osmium', symbol: 'Os', number: 76, color: colors.transitionMetal },
    { name: 'Iridium', symbol: 'Ir', number: 77, color: colors.transitionMetal },
    { name: 'Platinum', symbol: 'Pt', number: 78, color: colors.transitionMetal },
    { name: 'Gold', symbol: 'Au', number: 79, color: colors.transitionMetal },
    { name: 'Mercury', symbol: 'Hg', number: 80, color: colors.transitionMetal },
    { name: 'Thallium', symbol: 'Tl', number: 81, color: colors.postTransitionMetal },
    { name: 'Lead', symbol: 'Pb', number: 82, color: colors.postTransitionMetal },
    { name: 'Bismuth', symbol: 'Bi', number: 83, color: colors.postTransitionMetal },
    { name: 'Polonium', symbol: 'Po', number: 84, color: colors.metalloid },
    { name: 'Astatine', symbol: 'At', number: 85, color: colors.halogen },
    { name: 'Radon', symbol: 'Rn', number: 86, color: colors.nobleGas }
  ],
  [
    { name: 'Francium', symbol: 'Fr', number: 87, color: colors.alkailMetal },
    { name: 'Radium', symbol: 'Ra', number: 88, color: colors.alkalineEarthMetal },
    null,
    { name: 'Rutherfordium', symbol: 'Rf', number: 104, color: colors.transitionMetal },
    { name: 'Dubnium', symbol: 'Db', number: 105, color: colors.transitionMetal },
    { name: 'Seaborgium', symbol: 'Sg', number: 106, color: colors.transitionMetal },
    { name: 'Bohrium', symbol: 'Bh', number: 107, color: colors.transitionMetal },
    { name: 'Hassium', symbol: 'Hs', number: 108, color: colors.transitionMetal },
    { name: 'Meitnerium', symbol: 'Mt', number: 109, color: colors.transitionMetal },
    { name: 'Darmstadtium', symbol: 'Ds', number: 110, color: colors.transitionMetal },
    { name: 'Roentgenium', symbol: 'Rg', number: 111, color: colors.transitionMetal },
    { name: 'Copernicium', symbol: 'Cn', number: 112, color: colors.transitionMetal },
    { name: 'Nihonium', symbol: 'Nh', number: 113, color: colors.postTransitionMetal },
    { name: 'Flerovium', symbol: 'Fl', number: 114, color: colors.postTransitionMetal },
    { name: 'Moscovium', symbol: 'Mc', number: 115, color: colors.postTransitionMetal },
    { name: 'Livermorium', symbol: 'Lv', number: 116, color: colors.postTransitionMetal },
    { name: 'Tennessine', symbol: 'Ts', number: 117, color: colors.halogen },
    { name: 'Oganesson', symbol: 'Og', number: 118, color: colors.nobleGas }
  ],
  [],
  [
    null,
    null,
    null,
    { name: 'Lanthanum', symbol: 'La', number: 57, color: colors.lanthanide },
    { name: 'Cerium', symbol: 'Ce', number: 58, color: colors.lanthanide },
    { name: 'Praseodymium', symbol: 'Pr', number: 59, color: colors.lanthanide },
    { name: 'Neodymium', symbol: 'Nd', number: 60, color: colors.lanthanide },
    { name: 'Promethium', symbol: 'Pm', number: 61, color: colors.lanthanide },
    { name: 'Samarium', symbol: 'Sm', number: 62, color: colors.lanthanide },
    { name: 'Europium', symbol: 'Eu', number: 63, color: colors.lanthanide },
    { name: 'Gadolinium', symbol: 'Gd', number: 64, color: colors.lanthanide },
    { name: 'Terbium', symbol: 'Tb', number: 65, color: colors.lanthanide },
    { name: 'Dysprosium', symbol: 'Dy', number: 66, color: colors.lanthanide },
    { name: 'Holmium', symbol: 'Ho', number: 67, color: colors.lanthanide },
    { name: 'Erbium', symbol: 'Er', number: 68, color: colors.lanthanide },
    { name: 'Thulium', symbol: 'Tm', number: 69, color: colors.lanthanide },
    { name: 'Ytterbium', symbol: 'Yb', number: 70, color: colors.lanthanide },
    { name: 'Ytterbium', symbol: 'Lu', number: 71, color: colors.lanthanide }
  ],
  [
    null,
    null,
    null,
    { name: 'Actinium', symbol: 'Ac', number: 89, color: colors.actinide },
    { name: 'Thorium', symbol: 'Th', number: 90, color: colors.actinide },
    { name: 'Protactinium', symbol: 'Pa', number: 91, color: colors.actinide },
    { name: 'Uranium', symbol: 'U', number: 92, color: colors.actinide },
    { name: 'Neptunium', symbol: 'Np', number: 93, color: colors.actinide },
    { name: 'Plutonium', symbol: 'Pu', number: 94, color: colors.actinide },
    { name: 'Americium', symbol: 'Am', number: 95, color: colors.actinide },
    { name: 'Curium', symbol: 'Cm', number: 96, color: colors.actinide },
    { name: 'Berkelium', symbol: 'Bk', number: 97, color: colors.actinide },
    { name: 'Californium', symbol: 'Cf', number: 98, color: colors.actinide },
    { name: 'Einsteinium', symbol: 'Es', number: 99, color: colors.actinide },
    { name: 'Fermium', symbol: 'Fm', number: 100, color: colors.actinide },
    { name: 'Mendelevium', symbol: 'Md', number: 101, color: colors.actinide },
    { name: 'Nobelium', symbol: 'No', number: 102, color: colors.actinide },
    { name: 'Lawrencium', symbol: 'Lr', number: 103, color: colors.actinide }
  ]
];
