import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { Auth } from 'aws-amplify';
import axios from 'axios';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actionCreators';

import ModalWrapper from '../../presentational/ModalWrapper/ModalWrapper';
import ModalHeader from '../../presentational/ModalHeader/ModalHeader';
import ModalBody from '../../presentational/ModalBody/ModalBody';
import ModalFooter from '../../presentational/ModalFooter/ModalFooter';
import BEInput from '../../presentational/BEInput/BEInput';
import colors from '../../../styles/colors';

const requestNDCEndpoint = process.env.REACT_APP_REQUEST_NDC_URL;

function RequestNDCModal({ onClose, org, myApps, siteId }) {
  const [name, setName] = useState('');
  const [ndc, setNdc] = useState('');
  const [err, setErr] = useState('');
  const [success, setSuccess] = useState(false);

  const sendRequest = async () => {
    try {
      // VALIDATION
      if (!ndc || !name) {
        if (name) {
          setErr('Enter a valid NDC number.');
        } else if (ndc) {
          setErr('Enter a valid name.');
        } else {
          setErr('Enter a valid name and NDC number.');
        }
        return;
      }

      // Get site name:
      const formularyAppId = 1;
      const thisApp = myApps.find(
        appInstance =>
          appInstance.site_id === siteId &&
          appInstance.app.app_id === formularyAppId
      );
      const siteName = thisApp.site.name;

      // Get email
      const session = await Auth.currentSession();
      const requestingUserEmail = session.idToken.payload.email;

      await axios.post(
        requestNDCEndpoint,
        {
          orgName: 'whatever',
          siteName,
          requestingUserEmail,
          requestedNDC: ndc,
          requestedDrugName: name
        },
        {} // No auth needed on this endpoint
      );

      setSuccess(true);
    } catch (e) {
      setErr(e.message);
    }
  };

  return (
    <ModalWrapper>
      <ModalHeader
        title="Request NDC #"
        onClose={() => {
          onClose();
        }}
      />
      <ModalBody>
        {!success ? (
          <>
            <p style={styles.description}>
              {`To request a new NDC # be added to this Formulary, enter it below and click "Submit Request". Bird's Eye admins will review your request shortly.`}
            </p>
            <div style={styles.input}>
              <BEInput
                placeholder="NDC #"
                value={ndc}
                onChange={e => setNdc(e.target.value)}
              />
            </div>
            <div style={styles.input}>
              <BEInput
                placeholder="Drug Name"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </div>

            <div style={styles.errorBox}>
              {err && <p style={styles.errorMessage}>{err}</p>}
            </div>
          </>
        ) : (
          <p style={styles.label}>
            {"Bird's Eye admins have received your request!"}
          </p>
        )}
      </ModalBody>
      <ModalFooter>
        {!success ? (
          <>
            <Button
              secondary
              style={{
                borderColor: colors.bluePrimary,
                color: colors.bluePrimary
              }}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              primary
              style={{ backgroundColor: colors.bluePrimary }}
              onClick={async () => {
                sendRequest();
              }}
            >
              Submit Request
            </Button>
          </>
        ) : (
          <Button
            secondary
            style={{
              borderColor: colors.bluePrimary,
              color: colors.bluePrimary
            }}
            onClick={() => {
              onClose();
            }}
          >
            Done
          </Button>
        )}
      </ModalFooter>
    </ModalWrapper>
  );
}

const styles = {
  input: {
    flex: 1,
    marginBottom: '20px'
  },
  label: {
    fontFamily: 'Nunito-SemiBold',
    marginBottom: '7px'
  },
  description: {
    fontFamily: 'Nunito-SemiBold',
    marginBottom: '20px'
  },
  errorBox: {
    height: '60px'
  },
  errorMessage: {
    fontSize: '11px',
    fontFamily: 'Nunito-SemiBold',
    color: 'red',
    height: '20px'
  }
};

RequestNDCModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  org: PropTypes.object.isRequired,
  myApps: PropTypes.array.isRequired,
  siteId: PropTypes.number.isRequired
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state, props) => {
  const { myApps, siteId } = state.defaults;

  return {
    ...props,
    siteId,
    myApps
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RequestNDCModal)
);
