import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import PeriodicTable from '../PeriodicTable';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import ModalBody from '../ModalBody/ModalBody';
import ModalFooter from '../ModalFooter/ModalFooter';
import colors from '../../../styles/colors';

const PeriodicTableFilterModal = ({ onClose, onSearch, toggleOption, selectedElements }) => (
  <ModalWrapper className="periodic-table-modal">
    <ModalBody>
      <PeriodicTable setSeleted={toggleOption} selected={selectedElements} />
    </ModalBody>
    <ModalFooter>
      <Button
        secondary
        style={{
          borderColor: colors.bluePrimary,
          color: colors.bluePrimary
        }}
        onClick={onClose}
      >
        Cancel
      </Button>
      <Button
        primary
        style={{ backgroundColor: colors.bluePrimary }}
        onClick={() => {
          onClose();
          onSearch();
        }}
      >
        Filter
      </Button>
    </ModalFooter>
  </ModalWrapper>
);

PeriodicTableFilterModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  toggleOption: PropTypes.func.isRequired,
  selectedElements: PropTypes.array.isRequired
};

export default PeriodicTableFilterModal;
