import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { Auth } from 'aws-amplify';

import colors from '../../../styles/colors';
import BEInput from '../../presentational/BEInput/BEInput';
import ButtonText from '../../presentational/ButtonText/ButtonText';

class BELoginContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      errorMessage: ''
    };

    this.handleLogin = this.handleLogin.bind(this);
  }

  async handleLogin() {
    this.setState(
      prevState => ({ username: prevState.username.trim() }),
      () => {
        this.SignIn();
      }
    );
  }

  async SignIn() {
    try {
      const user = await Auth.signIn(this.state.username, this.state.password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.props.history.push({
          pathname: `/auth/configureAccount`,
          state: {
            username: this.state.username,
            password: this.state.password,
            userAttributes: user.challengeParam.userAttributes
          }
        });
      } else {
        // Then navigate to the main app
        this.props.history.push({ pathname: '/' });
      }
    } catch (err) {
      this.setState({
        errorMessage: err.message || err
      });
      if (err.code === 'UserNotConfirmedException') {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (err.code === 'PasswordResetRequiredException') {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else if (err.code === 'NotAuthorizedException') {
        // The error happens when the incorrect password is provided
      } else if (err.code === 'UserNotFoundException') {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
      } else {
        console.log(err);
      }
    }
  }

  render() {
    return (
      <>
        <div style={styles.header}>
          <h1>Login</h1>
        </div>

        <div style={styles.label}>
          <p style={styles.labelText}>Username</p>
        </div>
        <BEInput
          value={this.state.username}
          onChange={e => this.setState({ username: e.target.value })}
        />

        <div style={styles.break} />

        <div style={styles.label}>
          <p style={styles.labelText}>Password</p>
          <ButtonText
            onClick={() => {
              this.props.history.push({
                pathname: `/auth/resetPassword`
              });
            }}
            customStyles={{
              color: colors.birdseyeBlue,
              fontFamily: 'Nunito-Regular',
              fontSize: '13px'
            }}
          >
            Forgot password?
          </ButtonText>
        </div>
        <BEInput
          value={this.state.password}
          onChange={e => this.setState({ password: e.target.value })}
          type="password"
        />
        <div style={styles.break} />

        <p style={styles.errorMessage}>{this.state.errorMessage}</p>

        <div style={styles.break} />
        <div style={styles.break} />

        {/* <Radio label="Make my profile visible" / > */}

        <Button primary onClick={this.handleLogin}>
          Login
        </Button>

        <div style={styles.break} />
        <div style={styles.break} />

        <p style={styles.finePrint}>
          By Signing in, you agree to our User Terms of Service and Privacy
          Policy
        </p>
      </>
    );
  }
}

const styles = {
  header: {
    height: '70px'
  },
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '7px'
  },
  labelText: {
    fontFamily: 'Nunito-SemiBold'
  },
  break: {
    height: '14px'
  },
  errorMessage: {
    fontSize: '11px',
    fontFamily: 'Nunito-SemiBold',
    color: 'red',
    height: '20px'
  },
  finePrint: {
    fontSize: '11px',
    fontFamily: 'Nunito-SemiBold',
    color: colors.grayDark
  }
};

BELoginContainer.propTypes = {
  history: PropTypes.object.isRequired
};
export default withRouter(BELoginContainer);
