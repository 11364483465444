import React from 'react';
import PropTypes from 'prop-types';

function ModalBody({ children, confirmation }) {
  return (
    <div
      style={{
        ...styles.modalBody,
        ...(confirmation && {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        })
      }}
    >
      {children}
    </div>
  );
}

const styles = {
  modalBody: {
    flex: 1,
    padding: '30px 50px'
  }
};

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
  confirmation: PropTypes.bool.isRequired
};

export default ModalBody;
