import { Storage } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

import ButtonText from '../../../../../components/presentational/ButtonText/ButtonText';
import colors from '../../../../../styles/colors';
import { SetS3Config } from '../../../../../services';
import * as Actions from '../../../../../redux/actionCreators';
import birdseyeNavIcon from '../../../../../Assets/Images/birds-eye-nav-logo.png';

const Container = styled.div`
  background-color: rgba(22, 22, 52, 0.4);
  height: 100%;
  padding: 20px;
  outline: none;
  border: 2px dashed ${colors.blueFour};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const BackgroundImage = styled.img`
  position: absolute;
  opacity: 0.3;
  height: 100%;
`;

const DragNDropText = styled.h6`
  color: white;
  z-index: 10;
`;

const FeedTheBirdText = styled.h3`
  color: ${colors.blueThree};
  z-index: 10;
`;

const CloudUploadIcon = styled(Icon)`
  color: ${colors.white};
  z-index: 10;
`;

const accept = '.csv';

/*
Use the getRootProps() fn to get the props required for drag 'n' drop and use
them on any element. For click and keydown behavior, use the getInputProps() fn
and use the returned props on an <input>.
Source: https://react-dropzone.js.org/#section-basic-example
*/

const handleUploadFile = async (file, r_fetchNdcBounceResults, orgId, orgName) => {
  const bucketName = process.env.REACT_APP_CLIENT_IMPORT_BUCKET;
  SetS3Config(bucketName, 'private');

  try {
    let filename, prospectName;

    if (!!orgId && !!orgName) {
      prospectName = `${orgName.replace(/\W/g, '_')}-${Date.now()}`
      filename = `${prospectName}_${orgId}.csv`;
    } else {
      prospectName = `Prospect-${Date.now()}`;
      filename = `prospect_${prospectName}.csv`;
    }

    await Storage.put(filename, file, {
      contentType: file.type,
      progressCallback(progress) {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
      }
    });

    r_fetchNdcBounceResults(prospectName);
  } catch (e) {
    console.error('Error uploading file:', e);
  }
};

const FileUploadContainer = ({ r_fetchNdcBounceResults, orgId, orgName }) => {
  const onDropAccepted = useCallback(acceptedFiles => {
    handleUploadFile(acceptedFiles[0], r_fetchNdcBounceResults, orgId, orgName);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    accept
  });

  return (
    <Container {...getRootProps()}>
      <BackgroundImage src={birdseyeNavIcon} alt="birdseye logo" />
      <input {...getInputProps()} />
      {isDragActive && <DragNDropText>Drop the file here...</DragNDropText>}
      {!isDragActive && (
        <>
          <CloudUploadIcon name="cloud upload" size="huge" />

          <ButtonText
            customStyles={{
              width: '134px',
              height: '37px',
              backgroundColor: colors.blueThree,
              color: colors.white,
              borderRadius: '21.5px',
              marginTop: '10px',
              marginBottom: '10px',
              zIndex: 1
            }}
            onClick={() => {}}
          >
            Choose File
          </ButtonText>
          <DragNDropText>or Drag and Drop the file here.</DragNDropText>
          <FeedTheBirdText>(Feed The Bird)</FeedTheBirdText>
        </>
      )}
    </Container>
  );
};

FileUploadContainer.propTypes = {
  r_fetchNdcBounceResults: PropTypes.func.isRequired,
  orgId: PropTypes.number,
  orgName: PropTypes.string
};

export default connect(
  null,
  { r_fetchNdcBounceResults: Actions.r_fetchNdcBounceResults }
)(FileUploadContainer);
