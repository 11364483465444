import { types } from '../actionCreators'; // Import the actions types constant we defined in our actions

// Fetches data for a specific Formulary record: Displayed within the record detqil view
export const recordDetail = {
  fetchingRecordData: false,
  currentGroup: 'All', // 'Birds Eye View',
  data: null,

  editingAttribute: '', // the name of the field currently being edited
  fetchingDropdownOptions: false,
  dropdownOptions: [], // array of objects with {key, text, value}
  savingAttribute: '',
  error: {
    attribute: '',
    message: ''
  }
};

export default (state = {}, action) => {
  switch (action.type) {
    case types.FETCHING_RECORD_DATA:
      return {
        ...state,
        fetchingRecordData: true
      };
    case types.FETCHED_RECORD_DATA:
      return {
        ...state,
        fetchingRecordData: false,
        data: action.data.recordDetailData
      };
    case types.SET_CURRENT_GROUP:
      return {
        ...state,
        currentGroup: action.data.currentGroup
      };

    case types.GETTING_DROPDOWN_OPTIONS:
      return {
        ...state,
        fetchingDropdownOptions: true
      };
    case types.SET_DROPDOWN_OPTIONS:
      return {
        ...state,
        fetchingDropdownOptions: false,
        dropdownOptions: action.data.dropdownOptions
      };

    case types.SET_EDITING_ATTRIBUTE:
      return {
        ...state,
        editingAttribute: action.data.editingAttribute
      };

    case types.CREATED_ATTRIBUTE:
      return {
        ...state,
        creatingAttribute: true
      };

    case types.CREATING_ATTRIBUTE:
      return {
        ...state,
        creatingAttribute: false
      };

    case types.SAVING_ATTRIBUTE:
      return {
        ...state,
        savingAttribute: action.data.savingAttribute
      };

    case types.SAVED_ATTRIBUTE:
      return {
        ...state,
        savingAttribute: '',
        error: {
          attribute: '',
          message: ''
        }
      };

    case types.ERROR_SAVING_ATTRIBUTE:
      return {
        ...state,
        savingAttribute: '',
        error: {
          attribute: action.data.attribute,
          message: action.data.message
        }
      };

    case types.CLEAR_ATTRIBUTE_ERROR:
      return {
        ...state,
        error: {
          attribute: '',
          message: ''
        }
      };

    case types.SIGN_OUT:
      return { ...recordDetail };
    default:
      return state;
  }
};
