import React, { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
/** Components */
import Breadcrumb from './Breadcrumb/Breadcrumb';
import ButtonText from '../../../../components/presentational/ButtonText/ButtonText';
/** Services */
import { getAdminConfiguration, updateAdminConfiguration } from '../../../../API/admin';
/** Styles */
import './Dashboard.scss';
import colors from '../../../../styles/colors';

function DashboardView() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [configuration, setConfiguration] = useState([]);
  const [error, setError] = useState();

  const formatLabel = fieldName => fieldName.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());

  const handleInputChange = (_e, { name, value }) => {
    const newConfiguration = [...configuration];
    const fieldIndex = newConfiguration.findIndex(conf => conf.field === name);
    newConfiguration[fieldIndex] = { ...newConfiguration[fieldIndex], value };
    setConfiguration(newConfiguration);
  };

  const getConfiguration = async () => {
    try {
      const { data } = await getAdminConfiguration();
      setConfiguration(data);
    } catch (err) {
      setError(err.message || 'Something went wrong, please try again!');
    }
  };

  const saveConfig = async data => {
    try {
      setIsLoading(true);
      await updateAdminConfiguration({ configuration });
    } catch (err) {
      setError(err.message || 'Something went wrong, please try again!');
    } finally {
      setIsLoading(false);
      getConfiguration();
    }
  };

  useEffect(() => {
    getConfiguration();
  }, []);

  return (
    <div className="control-center-view-container">
      <Breadcrumb root="Dashboard" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />

      <div className="admin-configuration-container">
        <h1>Admin Configuration:</h1>

        {!!error && <h2 className="error">{error}</h2>}

        <div className="config-form-container">
          <Form className="dark-form configuration-form" inverted>
            {configuration.length > 0
              ? configuration.map(({ field, value, type }) => (
                  <Form.Input
                    key={field}
                    label={formatLabel(field)}
                    name={field}
                    type={type}
                    value={value}
                    onChange={handleInputChange}
                  />
                ))
              : null}
            <ButtonText
              isLoading={isLoading}
              customStyles={{
                backgroundColor: colors.blueThree,
                color: colors.white,
                borderColor: colors.blueThree,
                padding: '.78571429em 1.5em .78571429em',
                borderRadius: 5,
                float: 'right'
              }}
              onClick={saveConfig}
            >
              <span className="button__text">Save</span>
            </ButtonText>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default DashboardView;
