import { combineReducers } from 'redux';

// Import reducers
import query from './queryReducer';
import queryResults from './queryResultsReducer';
import defaults from './defaultsReducer';
import recordDetail from './recordDetailReducer';
import view from './viewReducer';
import errors from './errorsReducer';
import ndcBounce from './controlCenter/ndcBounceReducer';

// Combine all the reducers
const rootReducer = () =>
  combineReducers({
    query,
    queryResults,
    defaults,
    recordDetail,
    view,
    errors,
    ndcBounce
  });

export default rootReducer;
