import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { Storage } from 'aws-amplify';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actionCreators';

import ModalWrapper from '../../presentational/ModalWrapper/ModalWrapper';
import ModalHeader from '../../presentational/ModalHeader/ModalHeader';
import ModalBody from '../../presentational/ModalBody/ModalBody';
import colors from '../../../styles/colors';

import { downloadBlob, getCurrentDate, SetS3Config } from '../../../services';

import './ExportModal.scss';
import LoadingScreen from '../../presentational/LoadingScreen/LoadingScreen';

function ExportModal({ onClose, query }) {
  const { orgId, orgs } = query;

  const [exportStatus, setExportStatus] = useState('');
  const [exportError, setExportError] = useState(null);

  const getFileName = () => {
    const currentDate = getCurrentDate();
    const orgName = orgId === 'all' ? 'Master' : orgs.find(org => org.id === orgId).name;

    return `The ${orgName} Formulary-${currentDate}.csv`.replace(/ /g, '_');
  };

  const downloadFile = async (fileName, attempts) => {
    const bucketName = process.env.REACT_APP_S3_EXPORT_FORMULARY_BUCKET;
    SetS3Config(bucketName, 'public');

    try {
      const result = await Storage.get(fileName, { download: true });
      const blobFile = new Blob([result.Body], { type: 'text/csv' });
      downloadBlob(blobFile, fileName);
      setExportStatus('success');
    } catch (e) {
      if (attempts < 15) {
        setTimeout(() => {
          downloadFile(fileName, attempts + 1);
        }, 1000 + attempts * 500);
      } else {
        const errorMessage = e.message || JSON.stringify(e);
        /** Set the initial status with the error Message */
        setExportError(errorMessage);
        setExportStatus('');
      }
    }
  };

  const sendRequest = async () => {
    try {
      setExportStatus('in_progress');
      const fileName = getFileName();
      await Actions.exportCSVFormulary(query, fileName);
      await downloadFile(fileName, 0);
    } catch (e) {
      const errorMessage = e.message || JSON.stringify(e);
      /** Set the initial Status in order to request the process again */
      setExportStatus('');
      setExportError(errorMessage);
    }
  };

  const InProgressRender = () => (
    <LoadingScreen random inline="centered" type="relative" interval={500}>
      <h6>Exporting Information...</h6>
    </LoadingScreen>
  );

  const SuccessRender = () => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <h6>Information Exported Successfully</h6>
    </div>
  );

  const InitialExporRender = () => (
    <div className="modal-body-container">
      <div className="description-container">
        <h3>Do want to export the current information ?</h3>
        {exportError ? (
          <div className="error-container">
            <p>{exportError}</p>
          </div>
        ) : null}
      </div>
      <div className="button-container">
        <Button
          primary
          style={{
            backgroundColor: colors.bluePrimary,
            margin: '1em',
            height: 40
          }}
          onClick={sendRequest}
        >
          Export Information
        </Button>
      </div>
    </div>
  );

  const renderExportStatus = () => {
    const renders = {
      in_progress: () => InProgressRender(),
      success: () => SuccessRender(),
      default: () => InitialExporRender()
    };

    return (renders[exportStatus] || renders.default)();
  };

  return (
    <ModalWrapper customStyles={{ modal: styles.modal }}>
      <ModalHeader title="Export Dataset" onClose={() => onClose()} />
      <ModalBody confirmation={false}> {renderExportStatus()}</ModalBody>
    </ModalWrapper>
  );
}

const styles = {
  modal: {
    minHeight: 'auto'
  }
};

ExportModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  query: PropTypes.objectOf(PropTypes.any).isRequired
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state, props) => {
  const { query } = state;
  return {
    ...props,
    query
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ExportModal)
);
