const filterDisplayNameMap = {
  waste_class: 'Waste Class',
  waste_stream: 'Waste Stream',
  disposal_grouping: 'Disposal Grouping',
  main_reason: 'Main Reason',
  epa_waste_code: 'EPA Waste Code',
  route: 'Route',
  dermal_ld_50: 'LD/50 Dermal',
  inhalation_ld_50: 'LD/50 Inhalation',
  intravenous_ld_50: 'LD/50 Intravenous',
  ld_50: 'LD/50 Oral',
  manufacturer: 'Manufacturer',
  dea_schedule: 'DEA Schedule',
  preserverative: 'Preservative',
  glass_or_plastic_value: 'Glass or Plastic',
  uses_syringe_value: 'Syringe',
  dot_class: 'DOT Class',
  is_wte_non_compatible: 'WTE Non-Compatible',
  is_wa_state_dangerous: 'WA State Dangerous',
  molecular_formula_api: 'Molecular Formula API',
  wte_main_reason: 'WTE Main Reason'
};

export default filterDisplayNameMap;
