import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Menu } from 'semantic-ui-react';

const Paginator = ({ onPrev, onNext, page, totalPages }) => {
  return (
    <Menu floated="right" pagination>
      {page > 1 && (
        <Menu.Item as="a" icon onClick={onPrev}>
          <Icon name="chevron left" />
        </Menu.Item>
      )}
      <Menu.Item>
        <p>{`Page ${page} of ${totalPages}`}</p>
      </Menu.Item>
      {page < totalPages && (
        <Menu.Item as="a" icon onClick={onNext}>
          <Icon name="chevron right" />
        </Menu.Item>
      )}
    </Menu>
  );
};

Paginator.propTypes = {
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired
};

export default Paginator;
