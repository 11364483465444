import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actionCreators';

class BEPrivateRoute extends Component {
  constructor() {
    super();

    this.state = {
      loaded: false,
      authenticated: false
    };
  }

  componentDidMount() {
    this.authenticate();

    // Listen for URL changes, and check if user is still authenticated on each change
    this.unlisten = this.props.history.listen(() => {
      Auth.currentAuthenticatedUser()
        .then(() => {
          this.setState({ authenticated: true });
        })
        .catch(() => {
          this.setState({ authenticated: false });
        });
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  async authenticate() {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });

      // 1) ENSURE USER HAS PERMISSION TO VIEW THE ADMIN TOOL
      if (
        user.signInUserSession.idToken.payload['cognito:groups'] &&
        user.signInUserSession.idToken.payload['cognito:groups'].includes(
          'power_user'
        )
      ) {
        // 2) PERFORM ALL DATA FETCHING BEFORE DISPLAYING THE APP
        await this.props.r_fetchDefaultFilters();

        this.setState({
          loaded: true,
          authenticated: true
        });
      } else {
        const error =
          'You do not have permission to view the Formulary Admin Tool. You must be a Power User to do so.';
        alert(error);
        throw new Error(error);
      }
    } catch (err) {
      console.log({ err });
      await Auth.signOut();
      this.props.r_signOut(this.props.history);
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;
    const { loaded, authenticated } = this.state;
    if (!loaded) {
      return null;
    }
    return (
      <Route
        {...rest}
        render={props => {
          return authenticated ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/auth' }} />
          );
        }}
      />
    );
  }
}

BEPrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  r_signOut: PropTypes.func.isRequired,
  r_fetchDefaultFilters: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state, props) => {
  return {
    ...props
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BEPrivateRoute)
);
