import React, { useState } from 'react';
import PropTypes from 'prop-types';

import colors from '../../../../styles/colors';
import ButtonText from '../../../../components/presentational/ButtonText/ButtonText';
import PlanForm from './PlanForm';

const INITIAL_VALUE = {
  name: '',
  plan_type: 'all',
  restrictions_config: {}
};

const Create = ({ onCreatePlan, restrictions }) => {
  const [newData, setNewData] = useState(INITIAL_VALUE);
  return (
    <div className="dark-form plan-form">
      <PlanForm data={newData} setData={setNewData} restrictions={restrictions} />
      <ButtonText
        className="submit-btn"
        customStyles={{
          backgroundColor: colors.blueThree,
          color: colors.white
        }}
        onClick={() => onCreatePlan(newData)}
      >
        Create
      </ButtonText>
    </div>
  );
};

Create.propTypes = {
  onCreatePlan: PropTypes.func.isRequired,
  restrictions: PropTypes.array.isRequired
};

export default Create;
