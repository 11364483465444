import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { RadialProgress } from 'react-radial-progress-indicator';

import { matchingNdcPercentSelector } from '../../../../../redux/reducers/controlCenter/ndcBounceReducer';
import colors from '../../../../../styles/colors';

const Container = styled.div`
  background-color: ${colors.blueDarkTwo};
  color: ${colors.white};
  padding-top: 25px;
  padding-bottom: 25px;
`;

const Text = styled.p`
  color: ${props => props.color || colors.white};
  display: block;
  font-family: Nunito-Bold;
  font-size: ${props => props.fontSize || '12px'};
  line-height: ${props => props.lineHeight || '28px'};
  text-transform: uppercase;
  text-align: center;
`;

const RadialProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 25px;
`;

const AnalysisGauge = ({ matchingNdcPercent }) => {
  const getText = (_steps, proportion) => {
    return (
      <Text fontSize="42px" lineHeight="60px">
        {matchingNdcPercent == null
          ? '--'
          : `${(Math.round(proportion * 1000) / 10).toFixed(1)}%`}
      </Text>
    );
  };

  return (
    <Container>
      <Text fontSize="18px" lineHeight="28px">
        Total percent match
      </Text>
      <RadialProgressContainer>
        <RadialProgress
          backgroundTransparent
          duration={1000}
          fontRatio={4}
          height={225}
          ringBgColour={colors.grayLightTwo}
          ringFgColour={colors.blueThree}
          ringIntermediateColour={colors.blueThree}
          ringThickness={0.2}
          segmented={false}
          showIntermediateProgress
          startStep={0}
          step={matchingNdcPercent}
          steps={1}
          text={getText}
          width={225}
        />
      </RadialProgressContainer>
    </Container>
  );
};

AnalysisGauge.propTypes = {
  matchingNdcPercent: PropTypes.number
};

AnalysisGauge.defaultProps = {
  matchingNdcPercent: null
};

const mapState = state => {
  const matchingNdcPercent = matchingNdcPercentSelector(state.ndcBounce);

  return { matchingNdcPercent };
};

export default connect(mapState)(AnalysisGauge);
