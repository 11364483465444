import React, { useState, useRef, useEffect } from 'react';

import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import colors from '../../../styles/colors';
import sassStyles from './BENavBarDropdown.module.scss';

/**
 * Hook that detects clicks outside of the passed ref
 */
function useOutsideClickDetector(ref, didClickOutside) {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      didClickOutside(event.target);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

/**
 * BENavBarDropdown Component
 */

function BENavBarDropdown({ options, value, width, backgroundColor, isDarkStyle }) {
  // options - an object of form id: {}
  // Declare a new state variable, which we'll call "count"
  const [isOpen, toggleOpen] = useState(false);

  const dropdownMenuRef = useRef(null);
  const buttonRef = useRef(null);

  // Called whenever user clicks outside the dropdown menu
  const onClickOutside = clickTarget => {
    const didClickDropdownButton = buttonRef.current.contains(clickTarget);
    if (!didClickDropdownButton) {
      // prevents a double toggle (the dropdown button will call the
      // toggleOpen() function as well)
      toggleOpen(false);
    }
  };

  useOutsideClickDetector(dropdownMenuRef, onClickOutside);

  const appOptions = options
    .filter(x => x.category === 'apps')
    .map(option => {
      return (
        <div
          style={styles.dropdownItem}
          className={sassStyles.DropdownItem}
          key={option.key}
          onClick={() => {
            window.location.href = option.route;
            toggleOpen(false);
          }}
          onKeyPress={() => {
            window.location.href = option.route;
            toggleOpen(false);
          }}
          role="button"
          tabIndex="0"
        >
          <img alt="icon" style={{ width: '20px' }} src={option.icon} />
          <p style={styles.dropdownItemText}>{option.text}</p>
        </div>
      );
    });

  const birdseyeOptions = options
    .filter(x => x.category === 'birdseye')
    .map(option => {
      return (
        <div
          style={styles.dropdownItem}
          className={sassStyles.DropdownItem}
          key={option.key}
          onClick={() => {
            window.location.href = option.route;
            toggleOpen(false);
          }}
          onKeyPress={() => {
            window.location.href = option.route;
            toggleOpen(false);
          }}
          role="button"
          tabIndex="0"
        >
          <img alt="icon" style={{ width: '20px' }} src={option.icon} />
          <p style={styles.dropdownItemText}>{option.text}</p>
        </div>
      );
    });

  const selectedText =
    (options.length &&
      options.find(option => option.value === value) &&
      options.find(option => option.value === value).text) ||
    '';
  const selectedIcon =
    (options.length &&
      options.find(option => option.value === value) &&
      options.find(option => option.value === value).icon) ||
    '';

  return (
    <div style={{ ...styles.container, maxWidth: `${width}px` }}>
      <div
        ref={buttonRef}
        style={{
          ...styles.button,
          ...(isDarkStyle ? styles.darkButton : {})
        }}
        className={sassStyles.Button}
        onClick={() => {
          toggleOpen(!isOpen);
        }}
        onKeyPress={() => toggleOpen(!isOpen)}
        role="button"
        tabIndex="0"
      >
        {selectedIcon && (
          <img alt="icon" style={{ width: '30px' }} src={selectedIcon} />
        )}

        <h4 
          style={{
            ...styles.title,
            ...(isDarkStyle ? styles.darkTitle : {})
          }}
        >
          {selectedText}
        </h4>
        <Icon
          style={{
            ...(isDarkStyle ? styles.darkIcon : styles.icon),
            ...(isOpen && { transform: 'rotate(180deg)' }),
          }}
          name="chevron down"
          size="small"
        />
      </div>
      {isOpen && (
        <div
          ref={dropdownMenuRef}
          style={{
            ...styles.dropdown,
            ...(backgroundColor && {
              backgroundColor
            })
          }}
          className={sassStyles.DropDown}
        >
          {!!appOptions.length && (<h5 style={{ margin: '20px 20px 0px 20px' }}>My Apps</h5>)}
          {appOptions}
          {!!birdseyeOptions.length && (<h5 style={{ margin: '20px 20px 0px 20px' }}>Birds Eye</h5>)}
          {birdseyeOptions}
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    position: 'relative',
    minWidth: '250px'
  },
  darkButton: {
    backgroundColor: colors.blueDarkTwo,
    color: colors.white,
  },
  button: {
    backgroundColor: colors.white,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '20px',
    paddingRight: '20px',
    color: colors.grayDark,
    cursor: 'pointer'
  },
  darkTitle: {
    color: colors.white,
  },
  title: {
    fontFamily: 'Nunito-ExtraLight',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: '10px',
    marginRight: '10px',
    color: colors.black,
    flex: 1
  },
  darkIcon: {
    color: colors.white
  },
  icon: {
    color: colors.black
  },
  dropdown: {
    position: 'absolute',
    zIndex: 3,
    backgroundColor: colors.white,
    boxShadow: '0 7px 73px rgba(0, 0, 0, 0.12)',
    width: '100%',
    maxHeight: '400px',
    overflow: 'auto',
    paddingBottom: '22px'
  },
  iconContainer: {
    width: '42px',
    padding: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 20px 8px 20px',
    cursor: 'pointer'
  },

  dropdownItemText: {
    color: colors.black,
    marginLeft: '8px'
  }
};

BENavBarDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  value: PropTypes.string.isRequired,
  width: PropTypes.number,
  backgroundColor: PropTypes.string,
  isDarkStyle: PropTypes.bool,
};

BENavBarDropdown.defaultProps = {
  width: 400,
  backgroundColor: ''
};

export default BENavBarDropdown;
