import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../styles/colors';

function ModalFooter({ children, confirmation }) {
  return (
    <div
      style={{
        ...styles.modalFooter,
        ...(confirmation && {
          borderTop: 'none',
          justifyContent: 'center',
          paddingTop: '0px'
        })
      }}
    >
      {children}
    </div>
  );
}

const styles = {
  modalFooter: {
    padding: '26px 50px',
    borderTop: `1px solid ${colors.grayLight}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }
};

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired,
  confirmation: PropTypes.bool
};

ModalFooter.defaultProps = {
  confirmation: false
};

export default ModalFooter;
