const colors = {
  background: '#f5f6fa',
  black: '#3e4042',
  white: '#ffffff',
  bluePrimary: '#00b8d9',
  blueLight: '#7FD5E1',
  blueDark: '#009bb7',
  grayDark: '#8792a5',

  grayLight: '#f4f5f9',
  grayLightTwo: 'rgba(135,146,165,0.1)',
  red: '#FF4A4A',

  birdseyeBlue: '#204695',
  birdseyeBlueDark: '#03031F',

  blueDarkTwo: '#161634',
  blueTwo: '#535385',
  blueThree: '#1D4EFF',
  blueFour: '#1C1C3D',
  blueFive: '#0C0C27',

  green: '#79D034',
  yellow: '#FFAB00',
  redTwo: '#FF3030'
};

export default colors;
