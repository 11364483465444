import React from 'react';
import PropTypes from 'prop-types';

import closeIcon from '../../../Assets/Images/icon-close.png';
import sassStyles from './ModalHeader.module.scss';
import colors from '../../../styles/colors';

function ModalHeader({ title, onClose, confirmation, subtitle }) {
  return (
    <div
      style={{
        ...styles.modalHeader,
        ...(confirmation && { justifyContent: 'center' })
      }}
    >
      <div style={styles.headerContainer}>
        <h3 style={styles.title}>{title}</h3>
        <p style={styles.subTitle}>{subtitle}</p>
      </div>

      {!confirmation && (
        <div
          className={sassStyles.Button}
          onClick={onClose}
          onKeyPress={onClose}
          role="button"
          tabIndex="0"
          style={styles.closeButton}
        >
          <img src={closeIcon} width="16px" alt="close" />
        </div>
      )}
    </div>
  );
}

const styles = {
  modalHeader: {
    padding: '26px 50px',
    borderBottom: `1px solid ${colors.grayLight}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerContainer: {
    display: 'flex'
  },
  subTitle: {
    marginLeft: '18px',
    paddingTop: '3px',
    fontFamily: 'Nunito-Regular',
    fontSize: '12px',
    color: colors.gray
  },
  closeButton: {
    width: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  }
};

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  confirmation: PropTypes.bool
};

ModalHeader.defaultProps = {
  confirmation: false,
  subtitle: ''
};

export default ModalHeader;
