import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Menu, Table, Loader } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actionCreators';

import colors from '../../../styles/colors';

function TableContainer({
  orgId,
  siteId,
  records,
  fetchingRecords,
  r_queryFormulary,
  history
}) {
  const isTablet = useMediaQuery({ query: '(max-width: 1224px)' });
  const tableWidth = `calc(100vw - 3vw - 3vw${
    isTablet ? '' : ' - 288px - 32px'
  })`;

  const maxCellLength = 40;
  const { data } = records;
  if (fetchingRecords) {
    return (
      <div style={{ ...styles.emptyResultsContainer, width: tableWidth }}>
        <Loader active inline="centered">
          Fetching records...
        </Loader>
      </div>
    );
  }
  if (!data.length) {
    return (
      <div style={styles.emptyResultsContainer}>
        <h4>No Results</h4>
        <p>Try a different search or removing filters.</p>
      </div>
    );
  }

  if (data.length === 1 && history && history.action !== 'POP') {
    history.push(`/formulary-admin/records/${orgId}/${siteId}/${data[0].upc}`);
  }

  const headers = Object.keys(data[0]).slice(1);
  const headerCells = headers.map(header => {
    let headerText;
    if (header === 'ndc_10_spaces') {
      headerText = 'ndc';
    } else {
      headerText = header;
    }
    return (
      <Table.HeaderCell
        key={headerText}
        style={{ ...styles.tableCell, ...styles.headerCell }}
        rowSpan="1"
      >
        {headerText.replace(/_/g, ' ')}
      </Table.HeaderCell>
    );
  });

  const rows = data.map((record, rowIndex) => {
    const ndcCell = (
      <Table.Cell
        key={record.ndc_10_spaces}
        style={{
          ...styles.tableCell,
          fontFamily: 'Nunito-Bold',
          color: colors.black
        }}
      >
        {record.ndc_10_spaces}
      </Table.Cell>
    );
    const otherCells = Object.entries(record)
      .slice(2)
      .map(([key, value], i) => {
        let cellText;
        if (value[key].length > maxCellLength) {
          cellText = `${value[key].substring(0, maxCellLength)}...`;
        } else {
          cellText = value[key];
        }
        return (
          <Table.Cell key={`${cellText}_${key}`} style={styles.tableCell}>
            {cellText}
          </Table.Cell>
        );
      });
    const cells = [ndcCell, ...otherCells];
    return (
      <Table.Row
        key={record.upc}
        style={{
          ...styles.tableRow,
          cursor: 'pointer',
          borderBottomLeftRadius: rowIndex === data.length - 1 ? '8px' : '0px',
          borderBottomRightRadius: rowIndex === data.length - 1 ? '8px' : '0px'
        }}
        onClick={() =>
          history.push(
            `/formulary-admin/records/${orgId}/${siteId}/${record.upc}`
          )
        }
      >
        {cells}
      </Table.Row>
    );
  });

  const disablePrevious = records.page <= 1;
  const disableNext = records.page >= records.lastPage;
  return (
    <div style={{ ...styles.tableContainer, width: tableWidth }}>
      <Table
        style={styles.table}
        columns={6}
        selectable
        striped
        size="small"
        compact
        singleLine
        basic="very"
        // padded="very"
        className="table"
      >
        <Table.Header style={styles.tableHeader}>
          <Table.Row style={styles.tableRow}>{headerCells}</Table.Row>
        </Table.Header>

        <Table.Body style={styles.tableBody}>{rows}</Table.Body>
      </Table>

      <Menu floated="right" pagination>
        <Menu.Item
          as="a"
          icon
          onClick={() => {
            if (!disablePrevious) {
              r_queryFormulary(history, { page: records.page - 1 });
            }
          }}
        >
          <Icon name="chevron left" disabled={disablePrevious} />
        </Menu.Item>
        <Menu.Item>
          <p>{`Page ${records.page} of ${records.lastPage}`}</p>
        </Menu.Item>
        <Menu.Item
          as="a"
          icon
          onClick={() => {
            if (!disableNext) {
              r_queryFormulary(history, { page: records.page + 1 });
            }
          }}
        >
          <Icon name="chevron right" disabled={disableNext} />
        </Menu.Item>
      </Menu>
    </div>
  );
}

TableContainer.propTypes = {
  orgId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  siteId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  fetchingRecords: PropTypes.bool.isRequired,
  records: PropTypes.object.isRequired,
  r_queryFormulary: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

const styles = {
  tableContainer: {
    backgroundColor: colors.white,
    boxShadow: '0 2px 56px rgba(0, 0, 0, 0.07)',
    borderRadius: '8px'
  },
  table: {
    display: 'flex',
    flexDirection: 'column'
  },
  tableHeader: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  tableBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  headerCell: {
    color: colors.bluePrimary,
    textTransform: 'uppercase',
    fontWeight: 'normal',
    fontSize: '11px'
  },
  tableRow: {
    flex: 1,
    display: 'flex',
    border: 'none',
    paddingLeft: '1vw',
    paddingRight: '1vw'
  },
  tableCell: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    backgroundColor: 'transparent',
    border: 'none',
    paddingTop: '15px',
    paddingBottom: '15px',
    fontFamily: 'Nunito-Light',
    fontSize: '14px'
  },
  emptyResultsContainer: {
    height: '60vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
};
function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state, props) => {
  const { orgId, siteId } = state.query;
  const { fetchingRecords, records } = state.queryResults;

  return {
    ...props,
    orgId,
    siteId,
    fetchingRecords,
    records
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TableContainer)
);
