import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actionCreators';

import BEAppWrapper from '../BEAppWrapper/BEAppWrapper';
import ErrorRenderer from '../ErrorRenderer/ErrorRenderer';
import BENavBar from '../../presentational/BENavBar/BENavBar';
import RecordsContainer from '../RecordsContainer/RecordsContainer';
import AuthTimeoutWrapper from '../AuthTimeoutWrapper/AuthTimeoutWrapper';
import RecordDetailContainer from '../RecordDetailContainer/RecordDetailContainer';
import LoadingScreen from '../../presentational/LoadingScreen/LoadingScreen';

import useInterval from '../../../Hooks/useInterval';

export const formularyAdminPath = '/formulary-admin';

function FormularyApp({ history, r_signOut }) {
  const [showLoading, setShowLoading] = useState(true);
  const [userName, setUserName] = useState('');

  const isContentLoaded = () => {
    const element = document.getElementById('results-container-header');
    return !!element;
  };

  const capitalize = name =>
    name ? `${name.charAt(0).toUpperCase()}${name.slice(1)}` : '';

  const setCurrentUsername = async () => {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
    const userInfo =
      user && user.attributes && user.attributes.given_name
        ? capitalize(user.attributes.given_name)
        : '';

    setUserName(userInfo);
  };

  useInterval(
    () => {
      if (isContentLoaded()) {
        setShowLoading(false);
      }
    },
    showLoading ? 500 : null
  );

  const handleSignout = async () => {
    try {
      await Auth.signOut();
      r_signOut(history);
    } catch (error) {
      console.error({ error });
    }
  };

  useEffect(() => {
    setCurrentUsername();
  }, []);

  return (
    <>
      <ErrorRenderer>
        <AuthTimeoutWrapper>
          <BENavBar signOut={() => handleSignout()} />
          <BEAppWrapper>
            <Switch>
              <Route
                path={`${formularyAdminPath}/records/:orgId/:siteId/:upc`}
                component={RecordDetailContainer}
              />
              <Route
                path={[
                  `${formularyAdminPath}/:view/*`,
                  `${formularyAdminPath}/`
                ]}
                component={RecordsContainer}
              />
              <Route render={() => <div>404</div>} />
            </Switch>
          </BEAppWrapper>
        </AuthTimeoutWrapper>
      </ErrorRenderer>
      {showLoading ? (
        <LoadingScreen random inline="centered">
          <h1>Welcome {userName}</h1>
          <span>Loading your experience…</span>
        </LoadingScreen>
      ) : null}
    </>
  );
}

FormularyApp.propTypes = {
  history: PropTypes.object.isRequired,
  r_signOut: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (_state, props) => {
  return {
    ...props
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FormularyApp)
);
