import React, { useState, useRef, useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import colors from '../../../styles/colors';
import sassStyles from './BEDropdownMulti.module.scss';

/**
 * Hook that detects clicks outside of the passed ref
 */
function useOutsideClickDetector(ref, didClickOutside) {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      didClickOutside(event.target);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

/**
 * BEDropdownMulti Component
 */

function BEDropdownMulti({
  options,
  optionIds,
  placeholder,
  style,
  toggleOption,
  applySelection
}) {
  // options - an object of form id: {}
  // Declare a new state variable, which we'll call "count"
  const [isOpen, toggleOpen] = useState(false);

  const dropdownMenuRef = useRef(null);
  const buttonRef = useRef(null);

  // Called whenever user clicks outside the dropdown menu
  const onClickOutside = clickTarget => {
    const didClickDropdownButton = buttonRef.current.contains(clickTarget);
    if (!didClickDropdownButton) {
      // prevents a double toggle (the dropdown button will call the
      // toggleOpen() function as well)
      toggleOpen(false);
      applySelection();
    }
    // applySelection();
  };

  useOutsideClickDetector(dropdownMenuRef, onClickOutside);

  const selectedNames = [];

  // Instead of simply iterating through the object, we now use the optionIds
  // array to specify the order these dropdown options are rendered
  // const dropdownItems = Object.entries(options).map(([id, values]) => {
  const dropdownItems = optionIds.map(id => {
    const values = Object.values(options).find(x => x.id === id);
    if (values.selected) {
      selectedNames.push(values.name);
    }
    return (
      <div
        style={styles.dropdownItem}
        className={sassStyles.DropdownItem}
        key={id}
        onClick={() => toggleOption(id)}
        onKeyPress={() => toggleOption(id)}
        role="button"
        tabIndex="0"
      >
        <div
          style={{
            ...styles.checkbox,
            backgroundColor: values.selected
              ? colors.bluePrimary
              : 'transparent'
          }}
        />
        <div style={styles.dropdownItemText}>
          <p>{values.name}</p>
        </div>
      </div>
    );
  });

  let optionsSelected = false;
  let title = placeholder;
  if (selectedNames.length) {
    optionsSelected = true;
    title = selectedNames.join(', ');
  }

  return (
    <div style={{ ...styles.container, ...style }}>
      <div
        ref={buttonRef}
        style={{
          ...styles.button,
          border: optionsSelected ? `1px solid ${colors.bluePrimary}` : 'none'
        }}
        className={sassStyles.Button}
        onClick={() => {
          if (isOpen) {
            applySelection();
          }
          toggleOpen(!isOpen);
        }}
        onKeyPress={() => toggleOpen(!isOpen)}
        role="button"
        tabIndex="0"
      >
        <p
          style={{
            ...styles.title,
            ...(optionsSelected && {
              color: colors.bluePrimary,
              fontFamily: 'Nunito-Bold'
            })
            // color: optionsSelected ? colors.bluePrimary : colors.grayDark
          }}
        >
          {title}
        </p>
        <Icon style={styles.icon} name="chevron down" size="small" />
      </div>
      {isOpen && (
        <div ref={dropdownMenuRef} style={styles.dropdownContainer}>
          <div style={styles.dropdown} className={sassStyles.DropDown}>
            {dropdownItems}
          </div>
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    position: 'relative'
  },
  button: {
    backgroundColor: colors.white,
    boxShadow: '0 7px 16px rgba(0, 0, 0, 0.05)',
    height: '44px',
    borderRadius: '22px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '26px',
    paddingRight: '20px',
    color: colors.grayDark,
    cursor: 'pointer'
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '10px'
  },
  icon: {
    color: colors.bluePrimary
  },
  dropdownContainer: {
    position: 'absolute',
    zIndex: 3,
    backgroundColor: colors.white,
    boxShadow: '0 7px 73px rgba(0, 0, 0, 0.12)',
    width: '100%',
    marginTop: '5px',
    maxHeight: '190px',
    paddingTop: '22px',
    paddingBottom: '20px',
    borderRadius: '20px'
  },
  dropdown: {
    maxHeight: '150px',
    overflow: 'auto'
    // paddingBottom: '16px'
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px 8px 16px',
    cursor: 'pointer'
    // justifyContent: 'space-between'
  },
  checkbox: {
    height: '15px',
    width: '15px',
    borderRadius: '3px',
    border: `1px solid ${colors.bluePrimary}`
  },
  dropdownItemText: {
    flex: 1,
    marginLeft: '15px',
    maxWidth: 'calc(100% - 32px)' // - 13px - 15px
  }
};

BEDropdownMulti.propTypes = {
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      selected: PropTypes.bool
    })
  ).isRequired,
  optionIds: PropTypes.array.isRequired,
  style: PropTypes.object,
  toggleOption: PropTypes.func.isRequired,
  applySelection: PropTypes.func.isRequired

  /* EXAMPLE of the options prop:
      options: {
         0: {
           name: 'RCRA',
           selected: false
         },
         1: {
           name: 'RAD, RCRA',
           selected: false
         },
         ... more objects
      }
  */
};

BEDropdownMulti.defaultProps = {
  style: {}
};

export default BEDropdownMulti;
