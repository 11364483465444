import React, { useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import DomainModal from './DomainModal';
import colors from '../../../../styles/colors';
import ButtonText from '../../../../components/presentational/ButtonText/ButtonText';

const OrgDetail = ({ organization }) => (
  <div className="list-container fade flex-2">
    <Table basic="very" className="table main-table" columns={3} compact singleLine size="small">
      <Table.Body className="body">
        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            Name
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.name}
          </Table.Cell>
        </Table.Row>

        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            Facility Name
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.facility_name}
          </Table.Cell>
        </Table.Row>

        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            Parent 1
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.parent_org_1}
          </Table.Cell>
        </Table.Row>

        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            Parent 2
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.parent_org_2}
          </Table.Cell>
        </Table.Row>

        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            Main Phone
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.phone_main}
          </Table.Cell>
        </Table.Row>

        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            Account Type
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.account_type &&
              `${organization.account_type.name} (${organization.account_type.abbreviation})`}
          </Table.Cell>
        </Table.Row>

        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            Subscription Active
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.subscription_active ? 'Yes' : 'No'}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
    <Table basic="very" className="table main-table" columns={3} compact singleLine size="small">
      <Table.Body className="body">
        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            Address
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.address.address_line_1} {organization.address.address_line_2}
          </Table.Cell>
        </Table.Row>

        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            City
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.address.city}
          </Table.Cell>
        </Table.Row>

        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            State
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.address.state}
          </Table.Cell>
        </Table.Row>

        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            Zip
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.address.zip_code}
          </Table.Cell>
        </Table.Row>

        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            Tax ID #
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.tax_id_num}
          </Table.Cell>
        </Table.Row>

        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            BIRDEYES CUSTOMER #
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.behc_org_num}
          </Table.Cell>
        </Table.Row>

        <Table.Row className="row">
          <Table.HeaderCell
            className="cell header-cell"
            style={{ backgroundColor: colors.blueTwo, color: colors.white }}
            rowSpan="1"
          >
            TRILOGY CUSTOMER #
          </Table.HeaderCell>
          <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
            {organization.trilogy_org_num}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  </div>
);

OrgDetail.propTypes = {
  organization: PropTypes.object.isRequired
};

const OrgDetailView = ({ data, organization, getOrgDetail, setEdit, onAddDomain }) => {
  const [openDomainModal, setOpenDomainModal] = useState(false);

  useEffect(() => {
    getOrgDetail(data.id);
  }, []);

  return (
    <div>
      <div className="detail-header">
        <div>
          <ButtonText
            className="formulary-link"
            customStyles={{
              backgroundColor: colors.blueThree,
              color: colors.white,
              marginLeft: '0.5em'
            }}
            onClick={() => setEdit(true)}
          >
            Edit
          </ButtonText>
          <ButtonText
            className="formulary-link"
            customStyles={{
              backgroundColor: colors.blueThree,
              color: colors.white,
              marginLeft: '0.5em',
              width: '170px'
            }}
            onClick={() => setOpenDomainModal(true)}
          >
            Assign Subdomain
          </ButtonText>
        </div>
      </div>

      {!!organization && <OrgDetail organization={organization} />}
      {!!openDomainModal && (
        <DomainModal
          onAddDomain={org_domain => onAddDomain({ org_id: data.id, org_domain })}
          onClose={() => setOpenDomainModal(false)}
        />
      )}
    </div>
  );
};

OrgDetailView.propTypes = {
  data: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  getOrgDetail: PropTypes.func.isRequired,
  onAddDomain: PropTypes.func.isRequired,
  setEdit: PropTypes.func.isRequired
};

export default OrgDetailView;
