const CSV_EXPORT_HEADERS = [
  'upc',
  'alt_upc_code',
  'ndc_11',
  'ndc_11_spaces',
  'ndc_10_spaces',
  'ndc_10',
  'dosage_form',
  'route',
  'strength',
  'product_name',
  'substance_name',
  'manufacturer',
  'has_patent',
  'patent_number',
  'patent_expiration_date',
  'linked_ndc',
  'linked_product_name',
  'waste_class',
  'epa_waste_code',
  'waste_stream',
  'main_reason',
  'packaging_description',
  'start_marketing_date',
  'end_marketing_date',
  'disposal_acronym',
  'disposal_grouping',
  'single_or_multi_dose_value',
  'preserverative',
  'glass_or_plastic_value',
  'plastic_type',
  'uses_syringe_value',
  'storage_temperature_range',
  'permitted_temperature_excursions',
  'is_biohazardous',
  'is_carcinogen',
  'is_chemo',
  'is_endocrine_disruptor',
  'is_osha',
  'is_niosh_1',
  'is_niosh_2',
  'is_msds_indicated',
  'is_dea_control',
  'dea_schedule',
  'dea_number',
  'is_dea_list_1',
  'alcohol_percentage',
  'ph_range',
  'flash_point',
  'is_wte_non_compatible',
  'wte_main_reason',
  'is_wa_state_dangerous',
  'molecular_formula_api',
  'pill_imprint_1',
  'pill_imprint_2',
  'shape',
  'color',
  'size',
  'scored_value',
  'pregnancy_category',
  'dermal_ld_50',
  'inhalation_ld_50',
  'intravenous_ld_50',
  'oral_ld_50',
  'is_bmp_selected',
  'dot_class',
  'notes',
  'regulatory_return_policy',
  'regulatory_return_policy_reason',
  'return_policy_details',
  'proper_disposal_1',
  'proper_disposal_2',
  'if_empty',
  'proper_disposal_special_instructions',
  'shippable_value',
  'picture_1',
  'picture_2',
  'date_added',
  'needs_review',
  'last_reviewed_date'
];

export default CSV_EXPORT_HEADERS;
