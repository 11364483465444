import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { CSVDownloader } from 'react-papaparse';
import CSV_EXPORT_HEADERS from '../../../../../CSV_EXPORT_HEADERS';

import colors from '../../../../../styles/colors';
import Text from '../../../Text';

const Container = styled.div`
  background-color: ${colors.blueDarkTwo};
  color: ${colors.white};
  padding-top: 25px;
  padding-bottom: 25px;
  height: 100%;
`;

const MatchedIcon = styled(Icon)`
  color: ${colors.green};
  margin-right: 0px !important; // !important to override SemanticUI
`;

const DuplicatesIcon = styled(Icon)`
  color: ${colors.yellow};
  margin-right: 0px !important; // !important to override SemanticUI
`;

const NeedsReviewIcon = styled(Icon)`
  color: ${colors.redTwo};
  margin-right: 0px !important; // !important to override SemanticUI
`;

// const ExcelFileIcon = styled(Icon)`
//   position: absolute;
//   right: 1.5px;
//   top: 10px;
// `;

const NeedsReviewIconWrap = styled.div`
  display: inline;
  border: 2px solid ${colors.redTwo};
`;

const SectionRow = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
`;

const AnalysisBox = styled.div`
  background-color: ${colors.blueFour};
  border-radius: 10px;
  padding-bottom: 15px;
  padding-top: 15px;
  position: relative;
  text-align: center;
  width: 30%;
`;

const AnalysisBoxContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`;

const AnalysisBoxText = styled(Text)`
  padding-top: 5px;
`;

const TreeBranch = styled.div`
  width: 35.3%;
  height: 22px;
  border-top: 2px solid rgba(255, 255, 255, 0.1);
`;

const TreeBranchLeft = styled(TreeBranch)`
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  border-left: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px 0px 0px 0px;
`;

const TreeBranchRight = styled(TreeBranch)`
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 0px 10px 0px 0px;
`;

const TreeBranchRow = styled(SectionRow)`
  padding-bottom: 12px;
  padding-top: 15px;
`;

const StyledCSVDownloader = styled(CSVDownloader)`
  position: absolute;
  cursor: pointer;
  right: 8px;
  top: 8px;
  color: ${colors.white};
`;


const formatNumber = value => {
  if (!value && value !== 0) return '--';

  // Format number with comma as thousands separator.
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const leadingZerosFields = ['upc', 'ndc_11', 'ndc_10', 'ndc'];

const formatNdcList = (ncdList, headers) => (
  ncdList
    .sort((a, b) => {
      const value1 = (a.upc || a.ndc).replace(/-/g, '');
      const value2 = (b.upc || b.ndc).replace(/-/g, '');
      return parseInt(value1) - parseInt(value2);
    }).map(ndcRow =>
      headers.map(key => {
        if (leadingZerosFields.includes(key)) {
          return `'${ndcRow[key]}`;
        }
        return ndcRow[key];
      })
    )
);

const AnalysisHighlights = ({
  duplicateNdcCount,
  matchingNdcCount,
  missingNdcCount,
  duplicateNdc,
  matchingNdc,
  missingNdc,
  ndcCount,
  addedNdc,
  addedNdcCount
}) => {
  const orderedMatchingNdc = [
    CSV_EXPORT_HEADERS,
    ...formatNdcList(matchingNdc, CSV_EXPORT_HEADERS)
  ];

  const orderedMissingNdc = [
    Object.keys(missingNdc[0] || {}),
    ...formatNdcList(missingNdc, Object.keys(missingNdc[0] || {}))
  ];

  const orderedDuplicateNdc = [
    Object.keys(duplicateNdc[0] || {}),
    ...formatNdcList(duplicateNdc, Object.keys(duplicateNdc[0] || {}))
  ];
  const orderedAddedNdc = [Object.keys(addedNdc[0] || {}), ...formatNdcList(addedNdc, Object.keys(addedNdc[0] || {}))];

  return (
    <Container>
      <SectionRow>
        <Text>Total NDC&#39;s Analyzed</Text>
      </SectionRow>
      <SectionRow>
        <Text fontSize="48px" lineHeight="56px">
          {formatNumber(ndcCount)}
        </Text>
      </SectionRow>
      <TreeBranchRow>
        <TreeBranchLeft />
        <TreeBranchRight />
      </TreeBranchRow>
      <SectionRow>
        <AnalysisBoxContainer>
          <AnalysisBox>
            {/* <ExcelFileIcon name="file excel outline" /> */}
            <MatchedIcon name="check square outline" size="large" />
            <AnalysisBoxText>Matched</AnalysisBoxText>
            <Text fontSize="28px" lineHeight="40px" color={colors.green}>
              {formatNumber(matchingNdcCount)}
            </Text>
            {matchingNdcCount > 0 && (
              <StyledCSVDownloader
                filename="CSV-matching-ndc"
                data={orderedMatchingNdc}
              >
                <Icon name='external' />
              </StyledCSVDownloader>
            )}
          </AnalysisBox>
          <AnalysisBox>
            {/* <ExcelFileIcon name="file excel outline" /> */}
            <DuplicatesIcon name="clone outline" size="large" />
            <AnalysisBoxText>Duplicates</AnalysisBoxText>
            <Text fontSize="28px" lineHeight="40px" color={colors.yellow}>
              {formatNumber(duplicateNdcCount)}
            </Text>
            {duplicateNdcCount > 0 && (
              <StyledCSVDownloader
                filename="CSV-duplicate-ndc"
                data={orderedDuplicateNdc}
              >
                <Icon name='external' />
              </StyledCSVDownloader>
            )}
          </AnalysisBox>
          <AnalysisBox>
            {/* <ExcelFileIcon name="file excel outline" /> */}
            <NeedsReviewIconWrap>
              <NeedsReviewIcon name="cancel" />
            </NeedsReviewIconWrap>
            <AnalysisBoxText>Needs Review</AnalysisBoxText>
            <Text fontSize="28px" lineHeight="40px" color={colors.redTwo}>
              {formatNumber(missingNdcCount)}
            </Text>
            {missingNdcCount > 0 && (
              <StyledCSVDownloader
                filename="CSV-missing-ndc"
                data={orderedMissingNdc}
              >
                <Icon name='external' />
              </StyledCSVDownloader>
            )}
          </AnalysisBox>

          <AnalysisBox>
            {/* <ExcelFileIcon name="file excel outline" /> */}
            <MatchedIcon name="check square outline" size="large" />
            <AnalysisBoxText>Added NDCs</AnalysisBoxText>
            <Text fontSize="28px" lineHeight="40px" color={colors.green}>
              {formatNumber(addedNdcCount)}
            </Text>
            {addedNdcCount > 0 && (
              <StyledCSVDownloader
                filename="CSV-missing-ndc"
                data={orderedAddedNdc}
              >
                <Icon name='external' />
              </StyledCSVDownloader>
            )}
          </AnalysisBox>
        </AnalysisBoxContainer>
      </SectionRow>
    </Container>
  );
};

AnalysisHighlights.propTypes = {
  duplicateNdcCount: PropTypes.number,
  matchingNdcCount: PropTypes.number,
  missingNdcCount: PropTypes.number,
  ndcCount: PropTypes.number,
  duplicateNdc: PropTypes.array,
  matchingNdc: PropTypes.array,
  missingNdc: PropTypes.array,
  addedNdc: PropTypes.array,
  addedNdcCount: PropTypes.number
};

AnalysisHighlights.defaultProps = {
  duplicateNdcCount: null,
  matchingNdcCount: null,
  missingNdcCount: null,
  ndcCount: null,
  duplicateNdc: [],
  matchingNdc: [],
  missingNdc: [],
  addedNdcCount: null,
  addedNdc: []
};

const mapState = state => {
  const {
    duplicateNdcCount,
    matchingNdcCount,
    missingNdcCount,
    duplicateNdc,
    matchingNdc,
    missingNdc,
    ndcCount,
    addedNdc,
    addedNdcCount
  } = state.ndcBounce;

  return {
    duplicateNdcCount,
    matchingNdcCount,
    missingNdcCount,
    duplicateNdc,
    matchingNdc,
    missingNdc,
    ndcCount,
    addedNdc,
    addedNdcCount
  };
};

export default connect(mapState)(AnalysisHighlights);
