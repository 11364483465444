import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import colors from '../../../styles/colors';
import 'react-circular-progressbar/dist/styles.css';

const ProgressRing = ({progress, size, pathColor, textColor, trailColor, strokeLinecap}) => {
  const styles = {
    container: {
      width: `${size || 200}px`,
      height: `${size || 200}px`,
      margin: '18px auto',
    },

    progressBar: {
      strokeLinecap: strokeLinecap || 'butt',
      pathColor: pathColor || colors.birdseyeBlue,
      textColor: textColor || colors.grayDark,
      trailColor: trailColor || 'transparent'
    }
  };

  return (
    <div className="progess-ring" style={styles.container}>
      <CircularProgressbar
        value={progress}
        text={`${progress}%`}
        styles={buildStyles(styles.progressBar)}
      />
    </div>
  );
};

export default ProgressRing;
