import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import colors from '../../../styles/colors';
import sassStyles from './BENavBarProfileDropdown.module.scss';
import iconProfile from '../../../Assets/Images/icon-profile.png';
/**
 * Hook that detects clicks outside of the passed ref
 */
function useOutsideClickDetector(ref, didClickOutside) {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      didClickOutside(event.target);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

/**
 * BENavBarProfileDropdown Component
 */

function BENavBarProfileDropdown({ onGoToProfile, onSignOut }) {
  // options - an object of form id: {}
  // Declare a new state variable, which we'll call "count"
  const [isOpen, toggleOpen] = useState(false);

  const dropdownMenuRef = useRef(null);
  const buttonRef = useRef(null);

  // Called whenever user clicks outside the dropdown menu
  const onClickOutside = clickTarget => {
    const didClickDropdownButton = buttonRef.current.contains(clickTarget);
    if (!didClickDropdownButton) {
      // prevents a double toggle (the dropdown button will call the
      // toggleOpen() function as well)
      toggleOpen(false);
    }
  };

  useOutsideClickDetector(dropdownMenuRef, onClickOutside);

  return (
    <div style={styles.container}>
      <div
        ref={buttonRef}
        style={styles.button}
        className={sassStyles.Button}
        onClick={() => {
          toggleOpen(!isOpen);
        }}
        onKeyPress={() => toggleOpen(!isOpen)}
        role="button"
        tabIndex="0"
      >
        <img alt="icon" style={{ width: '30px' }} src={iconProfile} />
      </div>
      {isOpen && (
        <div
          ref={dropdownMenuRef}
          style={styles.dropdown}
          className={sassStyles.DropDown}
        >
          <div
            style={styles.dropdownItem}
            className={sassStyles.DropdownItem}
            key="signout"
            onClick={() => {
              onSignOut();
              toggleOpen(false);
            }}
            onKeyPress={() => {
              onSignOut();
              toggleOpen(false);
            }}
            role="button"
            tabIndex="0"
          >
            <p style={styles.dropdownItemText}>Sign Out</p>
          </div>
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    position: 'relative',
    height: '100%'
  },
  button: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: colors.grayDark,
    cursor: 'pointer'
  },

  dropdown: {
    position: 'absolute',
    zIndex: 3,
    right: '-16px',
    backgroundColor: colors.white,
    boxShadow: '0 7px 73px rgba(0, 0, 0, 0.12)',
    minWidth: '150px',
    maxHeight: '250px',
    overflow: 'auto',
    borderBottomLeftRadius: '22px',
    borderBottomRightRadius: '22px'
  },
  iconContainer: {
    width: '42px',
    padding: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 20px 8px 20px',
    cursor: 'pointer'
  },

  dropdownItemText: {
    color: colors.black,
    marginLeft: '8px'
  }
};

BENavBarProfileDropdown.propTypes = {
  onSignOut: PropTypes.func.isRequired
};

export default BENavBarProfileDropdown;
