import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actionCreators';

import TableContainer from '../TableContainer/TableContainer';
import VisualContainer from '../VisualContainer/VisualContainer';
import RequestNDCModal from '../RequestNDCModal/RequestNDCModal';
import UploadCSVModal from '../UploadCSVModal/UploadCSVModal';
import ExportModal from '../ExportModal/ExportModal';
import BEToggle from '../../presentational/BEToggle/BEToggle';
import colors from '../../../styles/colors';
import iconAdd from '../../../Assets/Images/icon-add.png';

function ResultsContainer({
  records,
  view,
  r_toggleView,
  r_showRequestNDCModal,
  showRequestNDCModal,
  r_showUploadCSVModal,
  showUploadCSVModal,
  r_showExportModal,
  showExportModal,
  history,
  match
}) {
  return (
    <>
      <div id="results-container-header" style={styles.header}>
        <div style={styles.headerLeft}>
          <h3>Unique Records</h3>
          {records.total ? (
            <h3 style={styles.recordCount}>
              {records.total.toLocaleString('en-US')}
            </h3>
          ) : null}
        </div>
        <div style={styles.headerRight}>
          <Button
            primary
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: colors.bluePrimary
            }}
            onClick={() => r_showUploadCSVModal(true)}
          >
            Add Records
            <img
              alt="add NDC"
              src={iconAdd}
              style={{ width: '12px', marginLeft: '20px' }}
            />
          </Button>
          <Button
            primary
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: colors.bluePrimary
            }}
            onClick={() => r_showExportModal(true)}
          >
            Export Dataset
          </Button>

          {showRequestNDCModal && (
            <RequestNDCModal
              onClose={() => {
                r_showRequestNDCModal(false);
              }}
            />
          )}

          {showUploadCSVModal && (
            <UploadCSVModal
              onClose={() => {
                r_showUploadCSVModal(false);
              }}
            />
          )}

          {showExportModal && (
            <ExportModal onClose={() => r_showExportModal(false)} />
          )}

          <BEToggle
            options={[
              { value: 'table', text: 'Table' },
              { value: 'visual', text: 'Visual' }
            ]}
            value={view}
            toggle={() => r_toggleView(history, match)}
          />
        </div>
      </div>

      {view === 'table' ? <TableContainer /> : <VisualContainer />}
    </>
  );
}

ResultsContainer.propTypes = {
  fetchingRecords: PropTypes.bool.isRequired,
  records: PropTypes.object.isRequired,
  r_showRequestNDCModal: PropTypes.func.isRequired,
  r_showUploadCSVModal: PropTypes.func.isRequired,
  r_showExportModal: PropTypes.func.isRequired,
  r_toggleView: PropTypes.func.isRequired,
  showRequestNDCModal: PropTypes.bool.isRequired,
  showUploadCSVModal: PropTypes.bool.isRequired,
  showExportModal: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '38px',
    marginBottom: '14px'
  },
  headerLeft: {
    display: 'flex'
  },
  headerRight: {
    display: 'flex'
  },
  table: {
    display: 'flex',
    flexDirection: 'column'
  },
  tableHeader: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  tableBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  headerCell: {
    color: colors.bluePrimary,
    textTransform: 'uppercase',
    fontWeight: 'normal',
    fontSize: '11px'
  },
  tableRow: {
    flex: 1,
    display: 'flex',
    border: 'none',
    paddingLeft: '1vw',
    paddingRight: '1vw'
  },
  tableCell: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    backgroundColor: 'transparent',
    border: 'none',
    paddingTop: '15px',
    paddingBottom: '15px',
    fontFamily: 'Nunito-Light',
    fontSize: '14px'
  },
  recordCount: {
    color: colors.grayDark,
    marginLeft: '8px',
    fontWeight: 'normal'
  }
};
function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state, props) => {
  const { fetchingRecords, records } = state.queryResults;
  const { view } = state.query;
  const {
    showRequestNDCModal,
    showUploadCSVModal,
    showExportModal
  } = state.view;

  return {
    ...props,
    fetchingRecords,
    records,
    view,
    showRequestNDCModal,
    showUploadCSVModal,
    showExportModal
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ResultsContainer)
);
