import API from './base';

export const getPlans = type => API.managmentGet(type ? `plans/${type}` : 'plans');

export const getPlanRestrictions = () => API.managmentGet('plans/restrictions');

export const createPlan = data => API.managmentPost('plans', data);

export const updatePlan = (planId, data) => API.managmentPatch(`plans/${planId}`, data);

// ORG plans
export const getOrgPlans = orgId => API.managmentGet(`organizations/${orgId}/plans`);

export const assignOrgPlan = (orgId, data) => API.managmentPatch(`organizations/${orgId}/plan`, data);

export const grantOrgFullAccess = orgId => API.managmentPost(`organizations/${orgId}/plan/fullAccess`);

// site plans
export const getSitePlans = siteId => API.managmentGet(`sites/${siteId}/plans`);

export const assignSitePlan = (orgId, siteId, data) =>
  API.managmentPatch(`organizations/${orgId}/sites/${siteId}/plan`, data);

export const grantSiteFullAccess = (orgId, siteId) =>
  API.managmentPost(`organizations/${orgId}/sites/${siteId}/plan/fullAccess`);

// Member plans
export const getMemberPlans = userId => API.managmentGet(`users/${userId}/plans`);

export const assignMemberPlan = (orgId, userId, data) =>
  API.managmentPatch(`organizations/${orgId}/users/${userId}/plan`, data);

export const grantMemberFullAccess = (orgId, userId) =>
  API.managmentPost(`organizations/${orgId}/users/${userId}/plan/fullAccess`);
