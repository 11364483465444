import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../styles/colors';
import SearchContainer from '../../container/SearchContainer/SearchContainer';

function PageHeader({ headerContent }) {
  return (
    <div style={styles.container}>
      {headerContent}
      <SearchContainer />
    </div>
  );
}

const styles = {
  container: {
    backgroundColor: colors.bluePrimary,
    padding: '0px 3vw',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  }
};

PageHeader.propTypes = {
  headerContent: PropTypes.node
};

export default PageHeader;
