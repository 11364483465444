const isTopLeftCornerOrdered = (rectangle) => {
  const isXLeftPoint = rectangle.filter(
    (point, idx) => idx > 0 && point[0] < rectangle[0][0]
  ).length < 2;
  const isYtopPoint = rectangle.filter(
    (point, idx) => idx > 0 && point[1] < rectangle[0][1]
  ).length < 2;

  return isXLeftPoint && isYtopPoint;
};

export const transformRectangleToCorners = (rectangle) => {
  if (!rectangle) { return []; }

  let tries = 4;
  while (!isTopLeftCornerOrdered(rectangle) && tries > 0) {
    rectangle.push(rectangle.shift()); // reorder rectangle
    tries--;
  }

  const [x1, y1] = rectangle[1];
  const [x2, y2] = rectangle[0];
  const size = 20;
  const slope = ((y2-y1)/(x2-x1));
  const variations = slope * size;

  return [
    // top-left corner
    [rectangle[0], [rectangle[0][0] + size, rectangle[0][1] + variations]],
    [rectangle[0], [rectangle[0][0] - variations, rectangle[0][1] + size]],
  
    // top-right corner
    [rectangle[1], [rectangle[1][0] - size, rectangle[1][1] - variations]],
    [rectangle[1], [rectangle[1][0] - variations, rectangle[1][1] + size]],
  
    // // bottom-right corner
    [rectangle[2], [rectangle[2][0] - size, rectangle[2][1] - variations]],
    [rectangle[2], [rectangle[2][0] + variations, rectangle[2][1] - size]],
  
    // // bottom-left corner
    [rectangle[3], [rectangle[3][0] + size, rectangle[3][1] + variations]],
    [rectangle[3], [rectangle[3][0] + variations, rectangle[3][1] - size]]  
  ];
};
