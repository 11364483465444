export const LOCATION_HEADERS = [
  'NAME',
  'ADDRESS',
  'CITY',
  'STATE',
  'ZIP',
  'BIRDEYES CUSTOMER #',
  'TRILOGY CUSTOMER #',
  'Users'
];

export const LOCATION_ORDER_FIELDS = {
  'NAME': 'sites.name',
  'ADDRESS': 'addresses.address_line_1',
  'CITY': 'addresses.city',
  'STATE': 'addresses.state',
  'ZIP': 'addresses.zip_code',
  // 'BIRDEYES CUSTOMER #',
  'TRILOGY CUSTOMER #': 'sites.trilogy_site_num',
  // 'Users'
};

export const locationToTable = (row) => [
  row.name,
  `${row.address.address_line_1} ${row.address.address_line_2}`,
  row.address.city,
  row.address.state,
  row.address.zip_code,
  row.behc_site_num,
  row.trilogy_site_num,
  row.member_count
];

export const ORG_HEADERS = [
  'PARENT NAME',
  'ADDRESS',
  'CITY',
  'STATE',
  'ZIP',
  'BIRDEYES CUSTOMER #',
  'TRILOGY CUSTOMER #'
];

export const ORG_ORDER_FIELDS = {
  'PARENT NAME': 'organizations.name',
  'ADDRESS': 'addresses.address_line_1',
  'CITY': 'addresses.city',
  'STATE': 'addresses.state',
  'ZIP': 'addresses.zip_code',
  // 'BIRDEYES CUSTOMER #',
  'TRILOGY CUSTOMER #': 'organizations.trilogy_org_num'
};

export const orgToTable = (row) => [
  row.name,
  `${row.address.address_line_1} ${row.address.address_line_2}`,
  row.address.city,
  row.address.state,
  row.address.zip_code,
  row.behc_org_num,
  row.trilogy_org_num
];
