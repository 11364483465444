import React from 'react';
import ReactDOM from 'react-dom';
// import '@babel/polyfill';
import 'react-app-polyfill/stable';
import './index.css';

import { configureAmplify } from './services';

import App from './App';
import * as serviceWorker from './serviceWorker';

configureAmplify();

if (
  navigator.userAgent.indexOf('MSIE') !== -1 ||
  navigator.appVersion.indexOf('Trident/') > -1
) {
  alert(
    `We're detecting you're using a browser that this application is not optimized for. We highly recommend you use this application in the most updated version of Microsoft Edge, Apple Safari, Firefox, or Google Chrome.`
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
