import React from 'react';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import colors from '../../../../../styles/colors';

const Breadcrumb = ({ root, setSelectedItem, selectedItem }) => {
  const selectedItemExist = () => !!selectedItem && Object.keys(selectedItem).length > 0;

  return (
    <div className="breadcrumb-container" style={{ color: colors.white }}>
      <button
        className="breadcrumb-item"
        style={{
          color: !selectedItemExist() ? colors.white : colors.blueThree
        }}
        onClick={() => {
          setSelectedItem(null);
        }}
      >
        {root}
      </button>
      {!!selectedItemExist() && (
        <>
          <Icon name="chevron right" />
          <span className="breadcrumb-item">{selectedItem.email}</span>
        </>
      )}
    </div>
  );
};

Breadcrumb.propTypes = {
  setSelectedItem: PropTypes.func.isRequired,
  selectedItem: PropTypes.object.isRequired,
  root: PropTypes.string.isRequired
};

export default Breadcrumb;
