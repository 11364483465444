import React, { useState, useRef, useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import colors from '../../../styles/colors';
import sassStyles from './BEDropdownSingle.module.scss';

/**
 * Hook that detects clicks outside of the passed ref
 */
function useOutsideClickDetector(ref, didClickOutside) {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      didClickOutside(event.target);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

/**
 * BEDropdownSingle Component
 */

function BEDropdownSingle({ options, onSelect, value, width, btnStyle, titleStyle, iconStyle }) {
  // options - an object of form id: {}
  // Declare a new state variable, which we'll call "count"
  const [isOpen, toggleOpen] = useState(false);

  const dropdownMenuRef = useRef(null);
  const buttonRef = useRef(null);

  // Called whenever user clicks outside the dropdown menu
  const onClickOutside = clickTarget => {
    const didClickDropdownButton = buttonRef.current.contains(clickTarget);
    if (!didClickDropdownButton) {
      // prevents a double toggle (the dropdown button will call the
      // toggleOpen() function as well)
      toggleOpen(false);
    }
  };

  useOutsideClickDetector(dropdownMenuRef, onClickOutside);

  const dropdownItems = options.map((option, index) => {
    const isSelected = option.value === value;
    return (
      <div
        style={styles.dropdownItem}
        className={sassStyles.DropdownItem}
        key={option.key}
        onClick={() => {
          onSelect(option.value);
          toggleOpen(false);
        }}
        onKeyPress={() => {
          onSelect(option.value);
          toggleOpen(false);
        }}
        role="button"
        tabIndex="0"
      >
        <p
          style={{
            ...styles.dropdownItemText,
            color: isSelected ? colors.bluePrimary : colors.black
          }}
        >
          {option.text}
        </p>
      </div>
    );
  });

  return (
    <div style={{ ...styles.container, width: `${width}px` }}>
      <div
        ref={buttonRef}
        style={{ ...styles.button, ...btnStyle }}
        className={sassStyles.Button}
        onClick={() => {
          toggleOpen(!isOpen);
        }}
        onKeyPress={() => toggleOpen(!isOpen)}
        role="button"
        tabIndex="0"
      >
        <p style={{ ...styles.title, ...titleStyle }}>
          {(options.length &&
            options.find(option => option.value === value) &&
            options.find(option => option.value === value).text) ||
            ''}
        </p>
        <Icon style={{ ...styles.icon, ...iconStyle }} name="chevron down" size="small" />
      </div>
      {isOpen && (
        <div ref={dropdownMenuRef} style={styles.dropdown} className={sassStyles.DropDown}>
          {dropdownItems}
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    position: 'relative',
    width: '200px',
    left: '2px'
  },
  button: {
    backgroundColor: colors.white,
    boxShadow: '0 7px 16px rgba(0, 0, 0, 0.05)',
    height: '44px',
    borderRadius: '22px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '26px',
    paddingRight: '20px',
    color: colors.grayDark,
    cursor: 'pointer'
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '10px',
    color: colors.bluePrimary
  },
  icon: {
    color: colors.bluePrimary
  },
  dropdown: {
    position: 'absolute',
    zIndex: 3,
    marginTop: '5px',
    backgroundColor: colors.white,
    boxShadow: '0 7px 73px rgba(0, 0, 0, 0.12)',
    width: '100%',
    maxHeight: '300px',
    overflow: 'auto',
    borderRadius: '22px'
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    cursor: 'pointer'
  },
  checkbox: {
    height: '15px',
    width: '15px',
    borderRadius: '3px',
    border: `1px solid ${colors.bluePrimary}`
  },
  dropdownItemText: {
    marginLeft: '15px'
  }
};

BEDropdownSingle.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  width: PropTypes.number,
  btnStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  iconStyle: PropTypes.object
};

BEDropdownSingle.defaultProps = {
  width: 200,
  btnStyle: {},
  titleStyle: {},
  iconStyle: {}
};

export default BEDropdownSingle;
