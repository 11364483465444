import React from 'react';
import PropTypes from 'prop-types';

import CustomTable from '../../../../components/presentational/ControlCenterTable';
import Paginator from '../../../../components/presentational/Pagination';

const ListView = ({ data, selectItem }) => (
  <div className="list-container fade">
    <CustomTable
      headers={['Name', 'Type', 'Restrictions Count']}
      className="sortable"
      availablesOrders={{}}
      data={data.map(row => ({
        cells: [row.name, row.plan_type, Object.keys(row.restrictions_config).length],
        onSelect: () => {
          selectItem(row);
        }
      }))}
    />
    <div className="paginator-container">
      <Paginator onNext={() => {}} onPrev={() => {}} page={1} totalPages={1} />
    </div>
  </div>
);

ListView.propTypes = {
  selectItem: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired
};

export default ListView;
