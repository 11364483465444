import API from './base';

export const getUsers = (data, page, perPage) =>
  API.managmentPost(`users/search?page=${page}&perPage=${perPage}`, data);

export const getUserOrgs = userId => API.managmentGet(`users/${userId}/organizations`);
export const removeUser = (userId, orgId) => API.managmentDelete(`users/${userId}/${orgId}`);

export const exportUsers = async (data, batchSize) => {
  const perPage = batchSize || 100;
  let page = 1;
  let results = [];

  const { data: response } = await API.managmentPost(`users/export?page=${page}&perPage=${perPage}`, data);
  results = response.data;

  while (page < response.lastPage) {
    // This is a heavy endpoint that should not be called many times at a time,
    // Await for each request could be processed slower but ensures to API is available for other requests
    /* eslint-disable no-await-in-loop */
    page += 1;
    const {
      data: { data: newItems }
    } = await API.managmentPost(`users/export?page=${page}&perPage=${perPage}`, data);
    /* eslint-enable no-await-in-loop */

    results = [...results, ...newItems];
  }

  return results;
};
