import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actionCreators';

import BEPrivateRoute from '../BEPrivateRoute/BEPrivateRoute';
import BEAuthentication from '../BEAuthentication/BEAuthentication';
import FormularyApp, { formularyAdminPath } from '../FormularyApp/FormularyApp';
import ControlCenter, {
  controlCenterPath
} from '../../../modules/ControlCenter/ControlCenter';

class Routes extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/auth" component={BEAuthentication} />
          <BEPrivateRoute path={controlCenterPath} component={ControlCenter} />
          <BEPrivateRoute path={formularyAdminPath} component={FormularyApp} />
          <Route path="/">
            <Redirect to={controlCenterPath} />
          </Route>
        </Switch>
      </Router>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state, props) => {
  return {
    ...props
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Routes);
