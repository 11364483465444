import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers/rootReducer';

import { defaults } from './reducers/defaultsReducer';
import { query } from './reducers/queryReducer';
import { queryResults } from './reducers/queryResultsReducer';
import { recordDetail } from './reducers/recordDetailReducer';
import { view } from './reducers/viewReducer';
import { errors } from './reducers/errorsReducer';
import { ndcBounceDefaultState as ndcBounce } from './reducers/controlCenter/ndcBounceReducer';

const defaultStore = {
  defaults,
  query,
  queryResults,
  recordDetail,
  view,
  errors,
  ndcBounce
};

const store = createStore(
  rootReducer(),
  defaultStore,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
