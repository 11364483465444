import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import colors from '../../../../styles/colors';
import ModalWrapper from '../../../../components/presentational/ModalWrapper/ModalWrapper';
import ModalHeader from '../../../../components/presentational/ModalHeader/ModalHeader';
import ModalBody from '../../../../components/presentational/ModalBody/ModalBody';
import ModalFooter from '../../../../components/presentational/ModalFooter/ModalFooter';
import ButtonText from '../../../../components/presentational/ButtonText/ButtonText';

const DomainModal = ({ onAddDomain, onClose }) => {
  const [domain, setDomain] = useState('');

  return (
    <ModalWrapper className="dark-modal" customStyles={{ modal: { backgroundColor: colors.blueFive } }}>
      <ModalHeader title="Org Domain" onClose={onClose} />
      <ModalBody confirmation={false}>
        <Form inverted className="dark-form">
          <Form.Input label="Name" type="text" value={domain} onChange={(_e, { value }) => setDomain(value)} />
        </Form>
      </ModalBody>
      <ModalFooter>
        <div className="flex justify-content">
          <ButtonText className="submit-btn" onClick={onClose} customStyles={{ color: colors.white }}>
            Cancel
          </ButtonText>
          <ButtonText
            className="submit-btn"
            customStyles={{
              backgroundColor: colors.blueThree,
              color: colors.white
            }}
            onClick={() => onAddDomain(domain)}
          >
            Add Domain
          </ButtonText>
        </div>
      </ModalFooter>
    </ModalWrapper>
  );
};

DomainModal.propTypes = {
  onAddDomain: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default DomainModal;
