import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actionCreators';

import colors from '../../../styles/colors';
import ControlsWrapper from '../ControlsWrapper/ControlsWrapper';
import sassStyles from './GroupSelectionContainer.module.scss';

function GroupSelectionContainer({
  groupings,
  currentGroup,
  isAdmin,
  r_setCurrentGroup
}) {
  const options = groupings.map((groupName, i) => {
    if (groupings[i] === 'Admin' && !isAdmin) {
      return null;
    }
    const selected = groupings[i] === currentGroup;
    return (
      <div
        key={groupName}
        style={{
          ...styles.button,
          ...(selected && {
            backgroundColor: colors.bluePrimary
          })
        }}
        className={sassStyles.Button}
        onClick={() => r_setCurrentGroup(groupings[i])}
        onKeyPress={() => r_setCurrentGroup(groupings[i])}
        role="button"
        tabIndex="0"
      >
        <p
          style={{
            ...styles.title,
            ...(selected && {
              color: colors.white,
              fontFamily: 'Nunito-Medium'
            })
          }}
        >
          {groupings[i]}
        </p>
      </div>
    );
  });

  return <ControlsWrapper title="Filter">{options}</ControlsWrapper>;
}

GroupSelectionContainer.propTypes = {
  groupings: PropTypes.array.isRequired,
  currentGroup: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  r_setCurrentGroup: PropTypes.func.isRequired
};

const styles = {
  button: {
    backgroundColor: colors.white,
    boxShadow: '0 7px 16px rgba(0, 0, 0, 0.05)',
    height: '44px',
    borderRadius: '22px',
    marginTop: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '26px',
    paddingRight: '20px',
    color: colors.grayDark,
    cursor: 'pointer'
  },
  title: {
    fontFamily: 'Nunito-Medium',
    color: colors.grayDark,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '10px'
  }
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state, props) => {
  const { filters } = state.query;

  return {
    ...props,
    filters
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GroupSelectionContainer)
);
