import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

function BEInput({ label, value, onChange, type, placeholder }) {
  return (
    <Input
      label={label}
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
}

BEInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string
};

BEInput.defaultProps = {
  type: 'text',
  placeholder: '',
  label: undefined
};

export default BEInput;
