import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Icon, Loader } from 'semantic-ui-react';
import styled from 'styled-components';

import colors from '../../../../../styles/colors';
import AnalysisHighlights from './AnalysisHighlights';
import AnalysisCategoryTable from './AnalysisCategoryTable';
import AnalysisBooleanFields from './AnalysisBooleanFields';
import AnalysisGauge from './AnalysisGauge';
import FileUploadContainer from './FileUploadContainer';

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
`;

const HeaderRow = styled(Row)`
  align-items: center;
  height: 100px;
  justify-content: flex-start;
  padding-bottom: 0px;
  padding-left: 4%;
`;

const AnalysisHighlightsContainerWrapper = styled.div`
  width: 44%;
`;

const FileUploadContainerWrapper = styled.div`
  width: 25%;
`;

const AnalysisCategoryTableContainer = styled.div`
  width: 100%;
  padding-right: 1rem;
`;

const AnalysisBooelanFieldsTableContainer = styled.div`
  width: 100%;
  padding-left: 1rem;
`;

const AnalysisGaugeContainerWrapper = styled.div`
  width: 25%;
`;

const StyledIcon = styled(Icon)`
  color: ${colors.white};
  padding-right: 7px;
`;

const BreadcrumbParent = styled.h6`
  color: ${colors.white};
  padding-right: 10px;
`;
const BreadcrumbChild = styled.h1`
  color: ${colors.white};
`;

const StyledLoader = styled.div`
  background-color: #070b1b;
  opacity: 0.9;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 20;
  color: white;
`;

const LoaderText = styled.h1`
  color: ${colors.grayDark};
`;

const FormularyNdcBounce = ({ fetchingNdcBounceResults, hideHeaders, orgId, orgName }) => {
  return (
    <Container style={{ padding: hideHeaders ? '0' : '0 4%' }}>
      {fetchingNdcBounceResults && (
        <StyledLoader>
          <Loader active size="massive" color="white">
            <LoaderText>Analyzing</LoaderText>
          </Loader>
        </StyledLoader>
      )}

      {!hideHeaders && (
        <HeaderRow>
          <BreadcrumbParent>Formulary App</BreadcrumbParent>
          <StyledIcon name="chevron right" />
          <BreadcrumbChild>NDC Bounce</BreadcrumbChild>
        </HeaderRow>
      )}
      <Row>
        <AnalysisHighlightsContainerWrapper>
          <AnalysisHighlights />
        </AnalysisHighlightsContainerWrapper>
        <AnalysisGaugeContainerWrapper>
          <AnalysisGauge />
        </AnalysisGaugeContainerWrapper>
        <FileUploadContainerWrapper>
          <FileUploadContainer orgId={orgId} orgName={orgName} />
        </FileUploadContainerWrapper>
      </Row>
      <Row>
        <AnalysisCategoryTableContainer>
          <AnalysisCategoryTable orgId={orgId} orgName={orgName} />
        </AnalysisCategoryTableContainer>
        <AnalysisBooelanFieldsTableContainer>
          <AnalysisBooleanFields />
        </AnalysisBooelanFieldsTableContainer>
      </Row>
    </Container>
  );
};

FormularyNdcBounce.propTypes = {
  fetchingNdcBounceResults: PropTypes.bool.isRequired,
  hideHeaders: PropTypes.bool.isRequired,
  orgId: PropTypes.number.isRequired,
  orgName: PropTypes.string.isRequired
};

const mapState = state => {
  const { fetchingNdcBounceResults } = state.ndcBounce;

  return {
    fetchingNdcBounceResults
  };
};

export default connect(mapState)(FormularyNdcBounce);
