import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import validator from 'validator';

import { Auth } from 'aws-amplify';

import colors from '../../../styles/colors';
import BEInput from '../../presentational/BEInput/BEInput';

const toDisplayPhone = text => {
  // sanitize: Remove +1, remove any non-numerical values
  let value = text
    .trim()
    .replace(/\D/g, '')
    .substring(0, 10);

  if (value.length > 3 && value.length <= 6)
    value = `${value.slice(0, 3)}-${value.slice(3)}`;
  else if (value.length > 6)
    value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6)}`;

  return value;
};

const fromDisplayPhone = text => {
  const value = text
    .trim()
    .replace(/\D/g, '')
    .substring(0, 10);
  return value;
};

const sanitizePhone = num => {
  return `+1${num}`;
};

const fromDisplayDate = date => {
  let displayText = date
    .trim()
    .replace(/\D/g, '')
    .substring(0, 8);

  if (displayText.length > 2 && displayText.length <= 4)
    displayText = `${displayText.slice(0, 2)}/${displayText.slice(2)}`;
  else if (displayText.length > 4)
    displayText = `${displayText.slice(0, 2)}/${displayText.slice(
      2,
      4
    )}/${displayText.slice(4)}`;

  return displayText;
};

class BEConfigureAccountContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      birthdate: this.props.location.state.userAttributes.birthdate || '',
      family_name: this.props.location.state.userAttributes.family_name || '',
      given_name: this.props.location.state.userAttributes.given_name || '',
      phone_number:
        this.props.location.state.userAttributes.phone_number.replace(
          '+1',
          ''
        ) || '',

      // Optional
      middle_initial:
        this.props.location.state.userAttributes.middle_initial || '',
      maiden_name: this.props.location.state.userAttributes.maiden_name || '',

      password: '',
      confirmPassword: '',

      errorMessage: '',

      validationErrors: {}
    };

    this.save = this.save.bind(this);
  }

  validateFields() {
    const {
      birthdate,
      family_name,
      given_name,
      phone_number,
      middle_initial,
      password,
      confirmPassword
    } = this.state;

    const errors = {};

    // Date format to ISO8601
    let refactoredDate = birthdate.split('/').reverse();
    const tmp = refactoredDate[2];
    refactoredDate[2] = refactoredDate[1];
    refactoredDate[1] = tmp;
    refactoredDate = refactoredDate.join('-');

    errors.birthdate =
      (!birthdate.length && 'Birthdate is a required field.') ||
      (!validator.isISO8601(refactoredDate) &&
        'Date is incorrectly formatted') ||
      null;
    errors.family_name =
      (!family_name.length && 'Last Name is a required field.') ||
      // (!validator.isISO8601(birthdate) && 'Date is incorrectly formatted') ||
      null;
    errors.given_name =
      (!given_name.length && 'First Name is a required field.') ||
      // (!validator.isISO8601(birthdate) && 'Date is incorrectly formatted') ||
      null;

    const sanitizedPhone = sanitizePhone(phone_number);
    errors.phone_number =
      (!phone_number.length && 'Phone Number is a required field.') ||
      (!validator.isMobilePhone(sanitizedPhone, 'en-US', {
        strictMode: true
      }) &&
        'The phone number is invalid') ||
      null;

    errors.middle_initial =
      (middle_initial.length &&
        middle_initial.length !== 1 &&
        'Middle Initial must be a single letter.') ||
      null;
    errors.maiden_name = null;
    errors.password =
      (!password.length && 'You must create a new password.') ||
      (password !== confirmPassword && 'Passwords must match') ||
      null;
    errors.confirmPassword =
      (password !== confirmPassword && 'Passwords must match') || null;

    const keys = Object.keys(errors);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      if (errors[key] === null || errors[key] === undefined) {
        delete errors[key];
      }
    }

    if (Object.keys(errors).length) {
      return errors;
    }
    return null;
  }

  async save() {
    try {
      // Run validations on the passed data
      const errors = this.validateFields();

      if (errors) {
        this.setState({ validationErrors: errors });
        return;
      }

      this.setState({ validationErrors: {} });

      const user = await Auth.signIn(
        this.props.location.state.username,
        this.props.location.state.password
      );

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        Auth.completeNewPassword(
          user, // the Cognito User Object
          this.state.password, // the new password
          // OPTIONAL, the required attributes
          {
            birthdate: this.state.birthdate,
            family_name: this.state.family_name,
            given_name: this.state.given_name,
            phone_number: sanitizePhone(this.state.phone_number),
            'custom:middle_initial': this.state.middle_initial,
            'custom:maiden_name': this.state.maiden_name
          }
        )
          .then(finalUser => {
            // Then navigate to the main app
            this.props.history.push({ pathname: `/` });
          })
          .catch(e => {
            this.setState({
              errorMessage: e.message || e
            });
            console.log(e);
          });
      } else {
        // Then navigate to the main app
        this.props.history.push({ pathname: `/` });
      }
    } catch (err) {
      this.setState({
        errorMessage: err.message || err
      });
      if (err.code === 'UserNotConfirmedException') {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (err.code === 'PasswordResetRequiredException') {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else if (err.code === 'NotAuthorizedException') {
        // The error happens when the incorrect password is provided
      } else if (err.code === 'UserNotFoundException') {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
      } else {
        console.log(err);
      }
    }
  }

  render() {
    return (
      <>
        <div style={styles.header}>
          <h1 style={{ marginBottom: '20px' }}>Welcome to Birds Eye!</h1>
          <p>We need just a little more information to get you set up.</p>
        </div>

        <div style={styles.label}>
          <p style={styles.labelText}>Birthdate</p>
        </div>
        <BEInput
          value={this.state.birthdate}
          placeholder="MM/DD/YYYY"
          onChange={e =>
            this.setState({ birthdate: fromDisplayDate(e.target.value) })
          }
        />
        <p style={styles.errorMessage}>
          {this.state.validationErrors.birthdate}
        </p>
        <div style={styles.break} />

        <div style={styles.label}>
          <p style={styles.labelText}>Phone Number</p>
        </div>
        <BEInput
          label="+1"
          value={toDisplayPhone(this.state.phone_number)}
          onChange={e =>
            this.setState({ phone_number: fromDisplayPhone(e.target.value) })
          }
        />
        <p style={styles.errorMessage}>
          {this.state.validationErrors.phone_number}
        </p>
        <div style={styles.break} />

        <div style={styles.break} />
        <div style={styles.break} />

        <div style={styles.label}>
          <p style={styles.labelText}>First Name</p>
        </div>
        <BEInput
          value={this.state.given_name}
          onChange={e => this.setState({ given_name: e.target.value })}
        />
        <p style={styles.errorMessage}>
          {this.state.validationErrors.given_name}
        </p>
        <div style={styles.break} />

        <div style={styles.label}>
          <p style={styles.labelText}>Middle Initial (optional)</p>
        </div>
        <BEInput
          value={this.state.middle_initial}
          onChange={e => this.setState({ middle_initial: e.target.value })}
        />
        <p style={styles.errorMessage}>
          {this.state.validationErrors.middle_initial}
        </p>
        <div style={styles.break} />

        <div style={styles.label}>
          <p style={styles.labelText}>Last Name</p>
        </div>
        <BEInput
          value={this.state.family_name}
          onChange={e => this.setState({ family_name: e.target.value })}
        />
        <p style={styles.errorMessage}>
          {this.state.validationErrors.family_name}
        </p>
        <div style={styles.break} />

        <div style={styles.label}>
          <p style={styles.labelText}>Maiden Name (optional)</p>
        </div>
        <BEInput
          value={this.state.maiden_name}
          onChange={e => this.setState({ maiden_name: e.target.value })}
        />
        <p style={styles.errorMessage}>
          {this.state.validationErrors.maiden_name}
        </p>
        <div style={styles.break} />

        <div style={styles.break} />
        <div style={styles.break} />

        <div style={styles.label}>
          <p style={styles.labelText}>New Password</p>
        </div>
        <BEInput
          value={this.state.password}
          type="password"
          onChange={e => this.setState({ password: e.target.value })}
        />
        <p style={styles.errorMessage}>
          {this.state.validationErrors.password}
        </p>
        <div style={styles.break} />

        <div style={styles.label}>
          <p style={styles.labelText}>Confirm New Password</p>
        </div>
        <BEInput
          value={this.state.confirmPassword}
          type="password"
          onChange={e => this.setState({ confirmPassword: e.target.value })}
        />
        <p style={styles.errorMessage}>
          {this.state.validationErrors.confirmPassword}
        </p>
        <div style={styles.break} />

        {this.state.errorMessage.length > 0 && (
          <div style={styles.errorBox}>
            <p style={styles.errorMessage}>{this.state.errorMessage}</p>
          </div>
        )}

        <Button primary onClick={this.save}>
          Complete Configuration
        </Button>
      </>
    );
  }
}

const styles = {
  header: {
    marginBottom: '20px'
  },
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '7px'
  },
  labelText: {
    fontFamily: 'Nunito-SemiBold'
  },
  break: {
    height: '14px'
  },
  errorBox: {
    height: '60px'
  },
  errorMessage: {
    fontSize: '11px',
    fontFamily: 'Nunito-SemiBold',
    color: 'red',
    height: '20px'
  },
  finePrint: {
    fontSize: '11px',
    fontFamily: 'Nunito-SemiBold',
    color: colors.grayDark
  }
};

BEConfigureAccountContainer.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};
export default withRouter(BEConfigureAccountContainer);
