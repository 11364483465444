import React from 'react';
import PropTypes from 'prop-types';

import colors from '../../../styles/colors';
import sassStyles from './ScannerButton.module.scss';
import scanIcon from '../../../Assets/Images/icon-scan.png';
import clearIcon from '../../../Assets/Images/icon-clear.png';

function ScannerButton({ scanningActive, disableScanner, enableScanner }) {
  return (
    <div
      className={`${sassStyles.Button} ${sassStyles.ButtonContainer}`}
      style={styles.button(scanningActive)}
      onClick={!scanningActive ? enableScanner : undefined}
      onKeyPress={!scanningActive ? enableScanner : undefined}
      role="button"
      tabIndex="0"
    >
      {scanningActive ? (
        <div className={sassStyles.activeContaier}>
          <h5 style={styles.text} className={sassStyles.Fade}>
            Scanner Active...
          </h5>
          <div
            className={`${sassStyles.Button} ${sassStyles.closeButton}`}
            onClick={() => disableScanner()}
            onKeyPress={() => disableScanner()}
            role="button"
            tabIndex="0"
          >
            <img src={clearIcon} width="12px" alt="close" />
          </div>
        </div>
      ) : (
        <img src={scanIcon} width="20px" alt="controls" />
      )}
    </div>
  );
}

const styles = {
  button: (scanningActive) => ({
    backgroundColor: colors.white,
    width: scanningActive ? `220px` : `44px`,
    cursor: scanningActive ? 'auto' : 'pointer'
  }),
  text: {
    color: colors.bluePrimary
  }
};

ScannerButton.propTypes = {
  scanningActive: PropTypes.bool.isRequired,
  disableScanner: PropTypes.func.isRequired,
  enableScanner: PropTypes.func.isRequired
};

export default ScannerButton;
