import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../styles/colors';
import { styles as BENavBarStyles } from '../../presentational/BENavBar/BENavBar';

function BEAppWrapper({ children, style }) {
  return <div style={{ ...styles.appContainer, ...style }}>{children}</div>;
}

BEAppWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object
};

BEAppWrapper.defaultProps = {
  style: {}
};

const styles = {
  appContainer: {
    minHeight: '100vh',
    overflow: 'auto',
    width: '100%',
    backgroundColor: colors.background,
    paddingTop: BENavBarStyles.menu.height
  }
};
export default BEAppWrapper;
