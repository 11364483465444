import React, { useState } from 'react';
import { Checkbox, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { jsonToCSV } from 'react-papaparse';

import CustomTable from '../../../../components/presentational/ControlCenterTable';
import Paginator from '../../../../components/presentational/Pagination';
import ButtonText from '../../../../components/presentational/ButtonText/ButtonText';
import colors from '../../../../styles/colors';
import Filters from './Filters';
import { exportUsers } from '../../../../API/users';
import { downloadBlob } from '../../../../services';

const ListView = ({
  users,
  selectItem,
  setShowPlanModal,
  selectedUsers,
  setSelectedUsers,
  onMainSearch,
  filters,
  onFilter,
  orgs,
  onDelete
}) => {
  const [exportInProgress, setExportInProgress] = useState(false);

  return (
    <>
      <Filters filters={filters} onFilter={onFilter} onMainSearch={onMainSearch} orgs={orgs} />
      {!!users && !!users.data && (
        <div className="list-container fade">
          <div className="actions">
            <ButtonText
              className="submit-btn large"
              customStyles={{
                backgroundColor: selectedUsers.length ? colors.blueThree : colors.grayLightTwo,
                color: colors.white,
                padding: '0 14px'
              }}
              disabled={exportInProgress}
              onClick={async () => {
                setExportInProgress(true);
                const response = await exportUsers({ ...filters });
                const blobFile = new Blob([jsonToCSV(response)], { type: 'text/csv' });
                downloadBlob(blobFile, 'incompleted-users.csv');
                setExportInProgress(false);
              }}
            >
              {!exportInProgress
                ? `Export '${filters.organization ? orgs[filters.organization] : 'Global'}' users`
                : 'Exporting...'}
            </ButtonText>

            <ButtonText
              className="submit-btn"
              customStyles={{
                backgroundColor: selectedUsers.length ? colors.blueThree : colors.grayLightTwo,
                color: colors.white
              }}
              disabled={!selectedUsers.length}
              onClick={() => setShowPlanModal(true)}
            >
              Assign Plans
            </ButtonText>
            <ButtonText
              className="submit-btn"
              customStyles={{
                backgroundColor: selectedUsers.length ? colors.red : colors.grayLightTwo,
                color: colors.white,
                width: '180px'
              }}
              disabled={!selectedUsers.length}
              onClick={onDelete}
            >
              Delete Selected ({selectedUsers.length})
            </ButtonText>
          </div>
          <span style={{ color: colors.white }}>Total: {users.total}</span>
          <CustomTable
            headers={[
              <Checkbox
                checked={selectedUsers.length >= 1}
                onClick={() => {
                  setSelectedUsers(selectedUsers.length >= users.data.length ? [] : users.data);
                }}
                label={
                  selectedUsers.length >= 1 && selectedUsers.length < users.data.length ? <Icon name="minus" /> : null
                }
              />,
              'ID',
              'Email'
            ]}
            className="sortable selectable"
            availablesOrders={{}}
            data={users.data.map(row => {
              const isSelected = selectedUsers.some(user => user.email === row.email);
              return {
                cells: [
                  <Checkbox
                    checked={isSelected}
                    onClick={e => {
                      e.stopPropagation();
                      if (isSelected) {
                        setSelectedUsers(selectedUsers.filter(user => user.email !== row.email));
                      } else {
                        setSelectedUsers([...selectedUsers, row]);
                      }
                    }}
                  />,
                  row.cognito_id,
                  row.email
                ],
                onSelect: (_item, event) => {
                  selectItem(row);
                }
              };
            })}
          />
          <div className="paginator-container">
            <Paginator
              onNext={() => onMainSearch(filters, users.page + 1)}
              onPrev={() => onMainSearch(filters, users.page - 1)}
              page={users.page}
              totalPages={users.lastPage}
            />
          </div>
        </div>
      )}
    </>
  );
};

ListView.propTypes = {
  users: PropTypes.object.isRequired,
  selectItem: PropTypes.func.isRequired,
  setShowPlanModal: PropTypes.func.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  setSelectedUsers: PropTypes.func.isRequired,
  onMainSearch: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
  orgs: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default ListView;
