import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Input } from 'semantic-ui-react';
import debounce from 'lodash.debounce';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actionCreators';

import sassStyles from './SearchContainer.module.scss';
import colors from '../../../styles/colors';
import BEDropdownSingle from '../../presentational/BEDropdownSingle/BEDropdownSingle';
import ButtonCircular from '../../presentational/ButtonCircular/ButtonCircular';
import controlsIcon from '../../../Assets/Images/icon-controls.png';
import clearIcon from '../../../Assets/Images/icon-clear.png';
import cameraIcon from '../../../Assets/Images/icon-camera.png';

import ScannerButton from '../../presentational/ScannerButton/ScannerButton';
import ScanBarcodeModal from '../ScanBarcodeModal/ScanBarcodeModal';
import PeriodicTableFilterModal from '../../presentational/PeriodicTableFilter/Modal';

const keyCodes = { enter: 13 };

/**
 * Hook that detects clicks outside of the passed ref
 */
function useOutsideClickDetector(ref, didClickOutside) {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      didClickOutside(event.target);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

const searchByOptions = [
  { key: 'all', text: 'All', value: 'all' },
  { key: 'ndc', text: 'NDC #', value: 'ndc' },
  { key: 'upc', text: 'UPC #', value: 'upc' },
  { key: 'product_name', text: 'Product Name', value: 'product_name' },
  { key: 'substance_name', text: 'Substance Name', value: 'substance_name' },
  { key: 'pill_identifier', text: 'Pill Identifier', value: 'pill_identifier' },
  { key: 'molecular_formula_api', text: 'Molecular Formula API', value: 'molecular_formula_api' }
];

const customInputs = {
  molecular_formula_api: props => <PeriodicTableFilterModal {...props} />
};

function SearchContainer({
  search_by,
  search_query,
  searchSuggestions,
  showControls,
  r_setSearchQuery,
  r_setSearchQueryAll,
  r_setSearchBy,
  r_queryFormulary,
  r_resetFilters,
  r_showControls,
  r_getSearchSuggestions,
  scanningActive,
  r_scanningActive,
  history
}) {
  const isTablet = useMediaQuery({ query: '(max-width: 1224px)' });
  const inputRef = useRef(null);
  const searchSuggestionsRef = useRef(null);

  const [showBarcodeModal, setShowBarcodeModal] = useState(false);
  const [openCustomInput, setOpenCustomInput] = useState(false);

  const clearSearchSuggestions = () => r_getSearchSuggestions(search_by, '');

  // Called whenever user clicks outside the dropdown menu
  const onClickOutside = clickTarget => {
    clearSearchSuggestions();

    // const didClickInsideInput = inputRef.current.contains(clickTarget);
    // if (!didClickInsideInput) {
    //   // Perform action
    // }
  };
  useOutsideClickDetector(searchSuggestionsRef, onClickOutside);

  let query = '';

  const lookupNdc = async () => {
    await r_setSearchQuery(query);
    await r_resetFilters();
    r_queryFormulary(history);
    query = '';
  };

  const debouncedLookupNdc = debounce(lookupNdc, 500);

  const getMultyOptions = () => {
    try {
      return JSON.parse(search_query);
    } catch (_e) {
      return [];
    }
  };

  useEffect(() => {
    const onMessage = async event => {
      if (scanningActive) {
        event.preventDefault();

        if (event.key && event.key.length && event.key.length === 1) {
          query += event.key;
          debouncedLookupNdc();
        }
      }
    };

    document.addEventListener('keydown', onMessage);

    return () => {
      document.removeEventListener('keydown', onMessage);
    };
  }, [scanningActive]);

  async function searchBySuggestion(suggestion) {
    clearSearchSuggestions();

    if (search_by === 'all') {
      await r_setSearchQueryAll(suggestion);
    } else {
      await r_setSearchQuery(suggestion[search_by]);
    }

    r_resetFilters();
    r_queryFormulary(history, {
      isSuggestion: true
    });
  }

  const dropdownItems = searchSuggestions.map(suggestion => {
    return (
      <div
        style={styles.dropdownItem}
        className={sassStyles.DropdownItem}
        key={suggestion.id}
        onClick={() => searchBySuggestion(suggestion)}
        onKeyPress={() => searchBySuggestion(suggestion)}
        role="button"
        tabIndex="0"
      >
        <p style={styles.searchSuggestionText}>{suggestion[search_by]}</p>
      </div>
    );
  });

  const dropdownItemsForAll = searchSuggestions.map(suggestion => {
    const { product_name, substance_name } = suggestion;
    return (
      <div
        style={styles.dropdownItem}
        className={sassStyles.DropdownItem}
        key={`${product_name}-${substance_name}`}
        onClick={() => searchBySuggestion(suggestion)}
        onKeyPress={() => searchBySuggestion(suggestion)}
        role="button"
        tabIndex="0"
      >
        <div style={styles.searchSuggestionText}>
          <div>
            <span style={styles.searchSuggestionLabel}>Product Name:</span> {product_name}
          </div>
          <div>
            <span style={styles.searchSuggestionLabel}>Substance Name:</span> {substance_name}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div style={styles.container(isTablet)}>
      {!scanningActive && (
        <div ref={inputRef} style={styles.inputContainer}>
          <Input
            className={sassStyles.Fade}
            style={styles.search}
            input={
              <input
                style={styles.searchInput}
                className={sassStyles.searchInput}
                onClick={() => {
                  if (Object.keys(customInputs).includes(search_by)) {
                    setOpenCustomInput(true);
                  }
                }}
                readOnly={Object.keys(customInputs).includes(search_by)}
              />
            }
            icon={
              <div style={{ position: 'absolute', left: '7px', top: '7px' }}>
                <ButtonCircular
                  onClick={async () => {
                    await r_setSearchQuery('');
                    await r_setSearchQueryAll({});
                    await r_resetFilters();
                    r_queryFormulary(history);
                  }}
                  diameter={30}
                >
                  <img src={clearIcon} width="12px" alt="clear" />
                </ButtonCircular>
              </div>
            }
            iconPosition="left"
            label={
              <BEDropdownSingle
                value={search_by}
                onSelect={value => {
                  if (Object.keys(customInputs).includes(search_by) && !Object.keys(customInputs).includes(value)) {
                    r_setSearchQuery('');
                  }
                  r_setSearchBy(value);
                  if (Object.keys(customInputs).includes(value)) {
                    r_setSearchQuery('[]');
                    setOpenCustomInput(true);
                  }
                }}
                options={searchByOptions}
                width={180}
              />
            }
            labelPosition="right"
            placeholder="Search by UPC #, NDC #, product or substance name..."
            value={search_query}
            onChange={e => {
              const textValue = e.target.value;
              r_setSearchQuery(textValue);
              if (['product_name', 'substance_name', 'all'].includes(search_by)) {
                r_getSearchSuggestions(search_by, textValue);
              }
            }}
            onKeyDown={async e => {
              if (e.keyCode === keyCodes.enter) {
                await r_setSearchQuery(search_query.trim());
                await r_resetFilters();
                r_queryFormulary(history);
                clearSearchSuggestions();
              }
            }}
          />

          <div ref={searchSuggestionsRef} style={styles.searchSuggestions} className={sassStyles.DropDown}>
            {search_by === 'all' ? dropdownItemsForAll : dropdownItems}
          </div>
        </div>
      )}

      {openCustomInput &&
        customInputs[search_by]({
          onClose: () => setOpenCustomInput(false),
          onSearch: () => r_queryFormulary(history),
          toggleOption: value => {
            const options = getMultyOptions();
            const newOptions = options.includes(value) ? options.filter(e => e !== value) : options.concat([value]);
            r_setSearchQuery(JSON.stringify(newOptions));
          },
          selectedElements: getMultyOptions()
        })}

      {scanningActive && (
        <ScanBarcodeModal
          onScan={async code => {
            await r_setSearchQuery(code);
            await r_resetFilters();
            setShowBarcodeModal(false);
            r_scanningActive(false);
            r_queryFormulary(history);
          }}
          onClose={() => {
            setShowBarcodeModal(false);
          }}
          onOpen={() => {
            setShowBarcodeModal(true);
          }}
          open={showBarcodeModal}
        >
          <ButtonCircular style={{ marginLeft: '12px' }}>
            <img src={cameraIcon} width="20px" alt="controls" />
          </ButtonCircular>
        </ScanBarcodeModal>
      )}

      <ScannerButton
        scanningActive={scanningActive}
        enableScanner={() => {
          r_scanningActive(true);
          r_setSearchBy('ndc');
        }}
        disableScanner={() => {
          r_scanningActive(false);
        }}
      />

      {isTablet && (
        <ButtonCircular
          onClick={() => (showControls ? r_showControls(false) : r_showControls(true))}
          style={{ marginLeft: '20px' }}
        >
          <img src={controlsIcon} width="20px" alt="controls" />
        </ButtonCircular>
      )}
    </div>
  );
}

SearchContainer.propTypes = {
  search_by: PropTypes.string.isRequired,
  search_query: PropTypes.string.isRequired,
  showControls: PropTypes.bool.isRequired,
  r_setSearchQuery: PropTypes.func.isRequired,
  r_setSearchQueryAll: PropTypes.func.isRequired,
  r_setSearchBy: PropTypes.func.isRequired,
  r_queryFormulary: PropTypes.func.isRequired,
  r_resetFilters: PropTypes.func.isRequired,
  r_showControls: PropTypes.func.isRequired,
  searchSuggestions: PropTypes.array.isRequired,
  scanningActive: PropTypes.bool.isRequired,
  r_scanningActive: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  r_getSearchSuggestions: PropTypes.func.isRequired
};

const styles = {
  container: isTablet => ({
    display: 'flex',
    flexDirection: 'row',
    margin: '24px 0px 24px 12px',
    flex: 1,
    justifyContent: isTablet ? 'center' : 'flex-end'
  }),
  search: {
    backgroundColor: colors.blueDark,
    border: 'none',
    borderRadius: '22px'
  },
  searchInput: {
    backgroundColor: 'transparent',
    color: colors.white,
    border: 'none',
    borderRadius: '22px',
    height: '44px'
  },
  inputContainer: {
    width: '90%',
    maxWidth: '50vw',
    position: 'relative'
  },
  searchSuggestions: {
    position: 'absolute',
    zIndex: 3,
    marginTop: '5px',
    backgroundColor: colors.white,
    boxShadow: '0 7px 73px rgba(0, 0, 0, 0.12)',
    width: '100%',
    // maxHeight: '200px',
    overflow: 'auto'
  },
  searchSuggestionText: {
    margin: '0px',
    fontSize: '14px',
    lineHeight: '23px',
    fontFamily: 'Nunito-Light',
    color: colors.black,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  searchSuggestionLabel: {
    color: colors.bluePrimary
  },
  searchSuggestionDivider: {
    height: '3px',
    width: '100%',
    backgroundColor: colors.grayDark
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 10px',
    cursor: 'pointer'
  },
  icon: {
    color: colors.white
  }
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state, props) => {
  const { search_by, search_query, searchSuggestions } = state.query;
  const { showControls, scanningActive } = state.view;

  return {
    ...props,
    search_by,
    search_query,
    searchSuggestions,
    scanningActive,
    showControls
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SearchContainer)
);
