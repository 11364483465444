import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Quagga from '@ericblade/quagga2';
import { Popup, Placeholder } from 'semantic-ui-react';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actionCreators';

import './ScanBarcodeModal.scss';
import closeIcon from '../../../Assets/Images/icon-close.png';
import { transformRectangleToCorners } from './utils'

function ScanBarcodeModal({ onClose, onScan, open, children, onOpen }) {
  const [isStarted, setIsStartes] = useState(false)
  useEffect(() => {
    if (open) {
      startScanner();
    }
  }, [open]);

  const startScanner = () => {
    Quagga.init(
      {
        inputStream: {
          name: 'Live',
          type: 'LiveStream',
          target: document.querySelector('#scanning-container'),
          constraints: {
            width: 590,
            height: 394,
            facingMode: 'environment'
          }
        },
        decoder: {
          readers: [
            {
              format: "ean_reader",
              config: {
                supplements: [
                  'ean_5_reader', 'ean_2_reader'
                ]
              }
            },
            'code_128_reader',
            'ean_8_reader',
            'upc_reader',
            'upc_e_reader'
          ]
        }
      },
      err => {
        if (err) {
          console.log(err);
          return;
        }
        Quagga.start();
        setIsStartes(true);
      }
    );

    Quagga.onProcessed(result => {
      const drawingCtx = Quagga.canvas.ctx.overlay;
      const drawingCanvas = Quagga.canvas.dom.overlay;
      drawingCtx.clearRect(
        0,
        0,
        parseInt(drawingCanvas.getAttribute('width')),
        parseInt(drawingCanvas.getAttribute('height'))
      );

      if (result && result.boxes) {
        result.boxes
          .forEach(box => {
            const cornersLines = transformRectangleToCorners(box);
            cornersLines.map(line => {
              Quagga.ImageDebug.drawPath(line, { x: 0, y: 1 }, drawingCtx, {
                color: 'green',
                lineWidth: 5
              })
            });
          });
      }

      if (result && result.codeResult && result.codeResult.code) {
        Quagga.ImageDebug.drawPath(
          result.line,
          { x: 'x', y: 'y' },
          drawingCtx,
          { color: 'red', lineWidth: 3 }
        );
      }
    });

    Quagga.onDetected(result => {
      const { code } = result.codeResult;
      Quagga.stop();
      onScan(code);
      setIsStartes(false);
    });
  };

  const closeAndStop = () => {
    Quagga.stop();
    onClose();
    setIsStartes(false);
  }

  return (
    <Popup
      on='click'
      onClose={closeAndStop}
      onOpen={onOpen}
      open={open}
      trigger={children}
      position='bottom center'
      popperDependencies={[isStarted]}
      wide
    >
      <div
        className='scannerCloseBtn'
        onClick={closeAndStop}
      >
        <img src={closeIcon} width="12px" alt="close" />
      </div>
      
      <div className='ScannerCameraContainer'>
        {!isStarted && (
          <Placeholder style={{ minWidth: '200px' }}>
            <Placeholder.Paragraph>
              <Placeholder.Line length='medium' />
              <Placeholder.Line length='short' />
            </Placeholder.Paragraph>
          </Placeholder>
        )}

        <div
          style={styles.scanner(isStarted)}
          id="scanning-container"
        />
      </div>
    </Popup>
  );
}

const styles = {
  scanner: (isStarted) => ({
    width: `100%`,
    height: `394px`,
    position: isStarted ? 'relative' : 'absolute',
    opacity: isStarted ? '1' : '0'
  })
}

ScanBarcodeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onScan: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onOpen: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state, props) => {
  const { myApps } = state.defaults;

  return {
    ...props,
    myApps
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ScanBarcodeModal)
);
