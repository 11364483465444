import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, Loader } from 'semantic-ui-react';
import { Auth, Storage } from 'aws-amplify';
import axios from 'axios';
import { CSVDownloader } from 'react-papaparse'

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actionCreators';

import { SetS3Config } from '../../../services';

import ModalWrapper from '../../presentational/ModalWrapper/ModalWrapper';
import ModalHeader from '../../presentational/ModalHeader/ModalHeader';
import ModalBody from '../../presentational/ModalBody/ModalBody';
import ModalFooter from '../../presentational/ModalFooter/ModalFooter';
import BEInput from '../../presentational/BEInput/BEInput';
import BEToggle from '../../presentational/BEToggle/BEToggle';
import colors from '../../../styles/colors';
import CSV_EXPORT_HEADERS from '../../../CSV_EXPORT_HEADERS';

const formularyHost = process.env.REACT_APP_FORMULARY_API_URL;

// Change it to a SET, robust error handling which indicates values that are missing or invalid...

function UploadCSVModal({
  onClose,
  siteId,
  orgId,
  orgs,
  r_queryFormulary,
  history
}) {
  const uploadRef = useRef(null);

  const [view, setView] = useState('Single');

  // Single NDC
  const [ndc, setNdc] = useState('');
  const [return_policy_details, setReturnPolicy] = useState('');
  const [proper_disposal_1, setDisposal1] = useState('');
  const [proper_disposal_2, setDisposal2] = useState('');
  const [if_empty, setIfEmpty] = useState('');
  const [
    proper_disposal_special_instructions,
    setSpecialInstructions
  ] = useState('');
  const [singleErr, setSingleErr] = useState('');
  const [singleSuccess, setSingleSuccess] = useState(false);

  // Bulk
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [err, setErr] = useState('');
  const [bulkSuccess, setBulkSuccess] = useState(false);

  const getContext = () => {
    if (orgId !== 'all') {
      return 'org';
    }
    return 'master';
  };

  const ndcIsValid = () => {
    if (ndc.length >= 10 && ndc.length <= 13) {
      return true;
    }
    return false;
  };

  const clearForm = () => {
    setNdc('');
    setReturnPolicy('');
    setDisposal1('');
    setDisposal2('');
    setIfEmpty('');
    setSpecialInstructions('');

    setSingleErr('');
  };

  const validateInputs = () => {
    const maxCharLimit = 255;
    if (
      return_policy_details.length > maxCharLimit ||
      proper_disposal_1.length > maxCharLimit ||
      proper_disposal_2.length > maxCharLimit ||
      if_empty.length > maxCharLimit ||
      proper_disposal_special_instructions.length > maxCharLimit
    ) {
      setSingleErr('Field exceeded maximum character limit of 255');
      return false;
    }

    return true;
  };

  const addNdcRecord = async () => {
    const upc = ndc.replace(/-/g, ''); // remove all dashes from NDC text input
    // if length is 11 (a 11-digit NDC # was entered), slice off the beginnig number
    const upcId = upc.length === 10 ? upc : upc.slice(1);

    if (!validateInputs()) {
      return;
    }
    try {
      const session = await Auth.currentSession();
      const response = await axios.post(
        `${formularyHost}/${'v0'}/formulary/organization/${orgId}/upc/${upcId}`,
        {
          return_policy_details,
          proper_disposal_1,
          proper_disposal_2,
          if_empty,
          proper_disposal_special_instructions
        },
        {
          headers: {
            Authorization: session.idToken.jwtToken
          }
        }
      );
      setSingleSuccess(true);
      clearForm();
      r_queryFormulary(history, { page: 1 }); // Re-query to include new record
    } catch (e) {
      if (e.response && e.response.data) {
        if (e.response.data.message) {
          setSingleErr(e.response.data.message);
        } else if (e.response.data.errors) {
          setSingleErr(e.response.data.errors.join(', '));
        }
      }
    }
  };

  const getUploadedFileName = () => {
    // Determine orgName
    let orgName = '';
    if (orgId !== 'all') {
      orgName = orgs.find(org => org.id === orgId).name;
      orgName = orgName.split(' ').join('-');
      orgName = `${orgName}_${orgId}`;
    } else {
      orgName = `master-formulary-${Date.now()}`;
    }

    return orgName;
  };

  const readFileContent = csvFile => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = event => resolve(event.target.result);
      reader.onerror = error => reject(error);
      reader.readAsText(csvFile);
    });
  };

  const uploadImage = async () => {
    try {
      // 1) Verify all column headers are correct:
      const textFile = await readFileContent(file);
      // First convert the CSV string into an array where the first value is a string of header names
      const content = textFile.split('\n');

      // Get the first element of the array (the column headers),
      const csvHeaders = content[0]
        .replace(/['"\s]+/g, '') // remove all " and '
        .split(','); // convert to a string where values are separated by commas
      // .join(',');

      const csvHeadersSet = new Set(csvHeaders);
      const requiredHeadersSet = new Set(contextMap[getContext()].headers);

      const missingHeaders = new Set(
        [...requiredHeadersSet].filter(x => !csvHeadersSet.has(x))
      );

      const invalidHeaders = new Set(
        [...csvHeadersSet].filter(x => !requiredHeadersSet.has(x))
      );

      if (missingHeaders.size || invalidHeaders.size) {
        let message = '';
        if (missingHeaders.size) {
          message += `The following column headers are missing from the CSV file: ${Array.from(
            missingHeaders
          ).join(', ')}`;
        }
        if (invalidHeaders.size) {
          message += `\nThe following column headers are invalid: ${Array.from(
            invalidHeaders
          ).join(', ')}`;
        }
        setErr(message);
        setUploading(false); // Hide spinner
        return;
      }

      // 2) If column headers have been verified, upload CSV to S3

      // Specify which bucket to put the CSV file in:
      const bucketName = contextMap[getContext()].bucket;
      SetS3Config(bucketName, 'private');

      // Begin the upload
      setUploading(true); // Show the spinner
      Storage.put(`${getUploadedFileName()}`, file, {
        contentType: file.type,
        progressCallback(progress) {
          setUploadProgress(
            Math.round((progress.loaded / progress.total) * 100)
          );
        }
      })
        .then(result => {
          uploadRef.current.value = null; // reset input HTML element
          setBulkSuccess(true);
          setUploading(false); // Hide spinner
        })
        .catch(e => {
          setErr(`Error uploading file: ${e}`);
          setUploading(false); // Hide spinner
        });
    } catch (e) {
      setErr(`Error uploading file: ${e}`);
      setUploading(false); // Hide spinner
    }
  };

  const addSingleNDCView = (
    <>
      {!singleSuccess ? (
        <>
          <p style={styles.description}>{`NDC # (required)`}</p>

          <div style={styles.input}>
            <BEInput
              placeholder="NDC #"
              value={ndc}
              onChange={e => setNdc(e.target.value)}
            />
          </div>

          <p style={styles.description}>{`Disposal details (optional):`}</p>

          <div style={styles.input}>
            <BEInput
              placeholder="Return Policy Details"
              value={return_policy_details}
              onChange={e => setReturnPolicy(e.target.value)}
            />
          </div>
          <div style={styles.input}>
            <BEInput
              placeholder="Proper Disposal 1"
              value={proper_disposal_1}
              onChange={e => setDisposal1(e.target.value)}
            />
          </div>
          <div style={styles.input}>
            <BEInput
              placeholder="Proper Disposal 2"
              value={proper_disposal_2}
              onChange={e => setDisposal2(e.target.value)}
            />
          </div>
          <div style={styles.input}>
            <BEInput
              placeholder="If Empty"
              value={if_empty}
              onChange={e => setIfEmpty(e.target.value)}
            />
          </div>
          <div style={styles.input}>
            <BEInput
              placeholder="Proper Disposal Special Instructions"
              value={proper_disposal_special_instructions}
              onChange={e => setSpecialInstructions(e.target.value)}
            />
          </div>

          <div style={styles.errorBox}>
            {singleErr && <p style={styles.errorMessage}>{singleErr}</p>}
          </div>
        </>
      ) : (
        <div style={styles.singleNdcAddedConfirmation}>
          <p style={styles.label}>{`Record added!`}</p>
          <Button
            primary
            style={{ backgroundColor: colors.bluePrimary, marginTop: '20px' }}
            onClick={() => {
              setSingleSuccess(false);
            }}
          >
            Add another record
          </Button>
        </div>
      )}
    </>
  );

  const uploadCSVView = (
    <>
      {!bulkSuccess ? (
        <>
          <input
            type="file"
            accept=".csv"
            style={{
              display: 'none'
            }}
            ref={uploadRef}
            onChange={e => {
              setFileName(uploadRef.current.files[0].name);
              setFile(uploadRef.current.files[0]);
            }}
          />

          <p style={styles.label}>
            Upload a CSV file with the same headers that{' '}
            <CSVDownloader
              filename="master-formulary-csv-template"
              data={[contextMap[getContext()].headers]}
              style={{cursor: 'pointer'}}
            >
              Master Formulary CSV Template
            </CSVDownloader>
          </p>

          <div style={styles.fileSelectionContainer}>
            
            <Button
              primary
              disabled={uploading}
              style={{ backgroundColor: colors.bluePrimary }}
              onClick={async () => {
                uploadRef.current.value = null;
                uploadRef.current.click();
              }}
            >
              Choose CSV File
            </Button>

            <p
              style={{
                ...styles.fileName,
                color: fileName ? colors.black : colors.grayDark
              }}
            >
              {fileName || 'No File Selected'}
            </p>

            {uploading && (
              <Loader active inline="centered">
                <div style={styles.percentUpload}>{`${uploadProgress}%`}</div>
              </Loader>
            )}
          </div>

          <div style={styles.errorBox}>
            {err &&
              err.split('\n').map(e => <p style={styles.errorMessage}>{e}</p>)}
          </div>
        </>
      ) : (
        <p style={styles.label}>
          {`Your CSV file has successfully uploaded and is now processing. You'll receive an email shortly with a status on your import.`}
        </p>
      )}
    </>
  );

  const showToggle = orgId !== 'all' && siteId === 'all';
  return (
    <ModalWrapper>
      <ModalHeader
        title={contextMap[getContext()].title}
        onClose={() => {
          onClose();
        }}
      />
      <ModalBody>
        {showToggle ? (
          <>
            <div style={styles.toggleContainer}>
              <BEToggle
                options={[
                  { value: 'Single', text: 'Single' },
                  { value: 'Bulk', text: 'Bulk' }
                ]}
                value={view}
                toggle={() =>
                  view === 'Single' ? setView('Bulk') : setView('Single')
                }
              />
            </div>
            <div style={styles.contentContainer}>
              {view === 'Single' ? addSingleNDCView : uploadCSVView}
            </div>
          </>
        ) : (
          <div style={styles.contentContainer}>{uploadCSVView}</div>
        )}
      </ModalBody>
      <ModalFooter>
        {showToggle && view === 'Single' ? (
          <>
            {singleSuccess ? (
              <Button
                secondary
                style={{
                  borderColor: colors.bluePrimary,
                  color: colors.bluePrimary
                }}
                onClick={() => {
                  onClose();
                }}
              >
                Done
              </Button>
            ) : (
              <Button
                primary
                disabled={!ndcIsValid()}
                style={{ backgroundColor: colors.bluePrimary }}
                onClick={async () => {
                  addNdcRecord();
                }}
              >
                Add Record
              </Button>
            )}
          </>
        ) : (
          <>
            {bulkSuccess ? (
              <Button
                secondary
                style={{
                  borderColor: colors.bluePrimary,
                  color: colors.bluePrimary
                }}
                onClick={() => {
                  onClose();
                }}
              >
                Done
              </Button>
            ) : (
              <Button
                primary
                disabled={!file || uploading}
                style={{ backgroundColor: colors.bluePrimary }}
                onClick={async () => {
                  uploadImage();
                }}
              >
                Import Records
              </Button>
            )}
          </>
        )}
      </ModalFooter>
    </ModalWrapper>
  );
}

const styles = {
  toggleContainer: {
    paddingBottom: '20px'
  },
  contentContainer: {
    minHeight: '435px'
  },
  input: {
    flex: 1,
    marginBottom: '10px'
  },
  label: {
    fontFamily: 'Nunito-SemiBold',
    marginBottom: '7px'
  },
  percentUpload: {
    fontFamily: 'Nunito-SemiBold',
    marginBottom: '20px',
    marginTop: '20px',
    color: colors.bluePrimary
  },
  requiredHeaders: {
    fontFamily: 'Nunito-SemiBold',
    marginBottom: '20px',
    marginTop: '0px',
    color: colors.grayDark,
    maxHeight: '100px',
    overflowY: 'auto',
    overflowWrap: 'break-word'
  },
  fileName: {
    fontFamily: 'Nunito-SemiBold',
    marginBottom: '20px',
    marginTop: '20px',
    color: colors.grayDark
  },
  errorBox: {
    marginTop: '20px',
    minHeight: '60px',
    maxHeight: '100px',
    overflowY: 'auto'
  },
  errorMessage: {
    fontSize: '11px',
    fontFamily: 'Nunito-SemiBold',
    color: 'red'
  },
  fileSelectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '40px'
  },
  description: {
    fontFamily: 'Nunito-SemiBold',
    marginBottom: '20px'
  },
  singleNdcAddedConfirmation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '300px'
  }
};

UploadCSVModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  siteId: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  orgs: PropTypes.array.isRequired,
  r_queryFormulary: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state, props) => {
  const { orgId, siteId, orgs } = state.query;
  return {
    ...props,
    orgId,
    siteId,
    orgs
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UploadCSVModal)
);

const contextMap = {
  org: {
    title: 'Associate new NDC #s to org',
    headers: [
      'ndc',
      'return_policy_details',
      'proper_disposal_1',
      'proper_disposal_2',
      'if_empty',
      'proper_disposal_special_instructions'
    ],
    bucket: process.env.REACT_APP_CLIENT_IMPORT_BUCKET
  },
  master: {
    title: 'Upload new records to the Master Formulary',
    headers: CSV_EXPORT_HEADERS,
    bucket: process.env.REACT_APP_MASTER_IMPORT_BUCKET
  }
};
