import React, { useState, useRef } from 'react';
import { Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import colors from '../../../styles/colors';
import iconEdit from '../../../Assets/Images/icon-edit.png';
import sassStyles from './EditableTableRow.module.scss';

import ButtonText from '../ButtonText/ButtonText';
import BETableRowDropdown from '../BETableRowDropdown/BETableRowDropdown';

const toDisplayPhone = text => {
  if (!text) {
    return '';
  }
  // sanitize: Remove +1, remove any non-numerical values
  let value = text
    .trim()
    .replace(/\D/g, '')
    .substring(0, 10);

  if (value.length > 3 && value.length <= 6)
    value = `${value.slice(0, 3)}-${value.slice(3)}`;
  else if (value.length > 6)
    value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6)}`;

  return value;
};

const fromDisplayPhone = text => {
  const value = text
    .trim()
    .replace(/\D/g, '')
    .substring(0, 10);
  return value;
};

const toDisplayDate = text => {
  if (!text) {
    return '';
  }
  // sanitize: Remove +1, remove any non-numerical values
  let value = text
    .trim()
    .replace(/\D/g, '')
    .substring(0, 8);

  if (value.length > 2 && value.length <= 4)
    value = `${value.slice(0, 2)}/${value.slice(2)}`;
  else if (value.length > 4)
    value = `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4)}`;

  return value;
};

function EditableTableRow({
  key,
  attributeName,
  index,
  displayName,
  type,
  value,
  displayValue,
  onTextChange,
  dropdownOptions,
  showAddOption,
  onAddOption,
  editable,
  onSave,
  saving,
  errorMessage,
  onClearError,
  customStyles,
  validator,
  onEdit,
  editing,
  onCancel
}) {
  const inputRef = useRef(null);

  // const [editing, setEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(value);
  const [inputFocused, setInputFocused] = useState(false);
  const [validationError, setValidationError] = useState('');

  const onClickEdit = () => {
    setEditedValue(value);
    onEdit();
    // setEditing(true);
    // Wait 50 ms for the <input> component to mount before focusing it
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 50);
  };

  // const displayValues = {
  //   dropdown:
  //     dropdownOptions.length &&
  //     dropdownOptions.find(option => option.value === value) &&
  //     dropdownOptions.find(option => option.value === value).text,
  //   bool: value === 1 ? 'True' : 'False',
  //   default: value
  // };
  //
  // const displayValue = displayValues[type] || displayValues.default;

  const getComponent = () => {
    switch (type) {
      case 'text':
        return (
          <input
            ref={inputRef}
            type="text"
            value={editedValue}
            onChange={e => {
              if (e.target.value.length < 100) {
                setEditedValue(e.target.value);
                onClearError();
              }
            }}
            style={{
              ...styles.input,
              ...(inputFocused && {
                borderBottom: `1.5px solid ${colors.bluePrimary}`
              }),
              ...((errorMessage || validationError) && {
                borderBottom: `1.5px solid ${colors.red}`
              })
            }}
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
          />
        );

      case 'phone':
        return (
          <input
            ref={inputRef}
            type="text"
            placeholder="###-###-####"
            value={toDisplayPhone(editedValue)}
            onChange={e => {
              setEditedValue(fromDisplayPhone(e.target.value));
              onClearError();
            }}
            style={{
              ...styles.input,
              ...(inputFocused && {
                borderBottom: `1.5px solid ${colors.bluePrimary}`
              }),
              ...((errorMessage || validationError) && {
                borderBottom: `1.5px solid ${colors.red}`
              })
            }}
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
          />
        );

      case 'dropdown': {
        return (
          <BETableRowDropdown
            allowNoSelection={
              attributeName === 'dea_schedule' ||
              attributeName === 'epa_waste_code' // for now, only allow "No Value" to be selected for these two fields
            }
            options={dropdownOptions}
            displayValue={displayValue}
            value={editedValue}
            onSelect={newValue => {
              setEditedValue(newValue);
              onClearError();
              // If setting the foreign key to null, close out of the editable row
              if (newValue === null) {
                onSave(newValue);
              }
            }}
            search
            searchInputDidChange={newInput => {
              onTextChange(newInput);
            }}
            showAddOption
            onAddOption={async attributeName => {
              await onAddOption(attributeName);
            }}
            error={errorMessage}
          />
        );
      }

      case 'dropdown_date': {
        return (
          <BETableRowDropdown
            inputType="date"
            options={dropdownOptions}
            displayValue={displayValue}
            value={editedValue}
            onSelect={newValue => {
              setEditedValue(newValue);
              onClearError();
              // If setting the foreign key to null, close out of the editable row
              if (newValue === null) {
                onSave(newValue);
              }
            }}
            search
            searchInputDidChange={newInput => {
              onTextChange(newInput);
            }}
            showAddOption
            onAddOption={async attributeName => {
              await onAddOption(attributeName);
            }}
            error={errorMessage}
          />
        );
      }

      case 'bool': {
        const options = [
          { key: 1, value: 1, text: 'True' },
          { key: 0, value: 0, text: 'False' }
        ];
        return (
          <BETableRowDropdown
            options={options}
            value={editedValue}
            onSelect={selectedKey => {
              setEditedValue(selectedKey);
              onClearError();
            }}
            error={errorMessage}
          />
        );
      }

      case 'date':
        return (
          <input
            ref={inputRef}
            type="text"
            placeholder="MM/DD/YYYY"
            value={editedValue}
            onChange={e => {
              setEditedValue(toDisplayDate(e.target.value));
              onClearError();
            }}
            style={{
              ...styles.input,
              ...(inputFocused && {
                borderBottom: `1.5px solid ${colors.bluePrimary}`
              }),
              ...((errorMessage || validationError) && {
                borderBottom: `1.5px solid ${colors.red}`
              })
            }}
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
          />
        );

      case 'modal':
        return <div>{editedValue}</div>;

      default:
        return null;
    }
  };

  return (
    <div
      key={key}
      style={{
        ...styles.container,
        ...(index % 2 === 1 && { backgroundColor: colors.grayLight }),
        ...customStyles
      }}
    >
      <div style={styles.titleCell}>{displayName}</div>
      <div style={styles.valueCell}>
        {editing ? (
          <>{getComponent()}</>
        ) : (
          <div style={styles.valueText}>
            {type === 'phone' ? toDisplayPhone(displayValue) : displayValue}
          </div>
        )}
      </div>
      <div style={styles.controlsCell}>
        {editing ? (
          <>
            <div style={styles.error}>{validationError}</div>
            <Loader active={saving} inline="centered" size="tiny" />
            <ButtonText
              onClick={async () => {
                const validation = validator && validator(editedValue);
                if (validation) {
                  setValidationError(validation);
                } else {
                  setValidationError('');
                  await onSave(editedValue);
                }
              }}
              customStyles={{ margin: '0px 30px 0px 10px' }}
            >
              Save
            </ButtonText>
            <ButtonText
              onClick={() => {
                onCancel();
                setEditedValue(value);
                onClearError();
              }}
            >
              Cancel
            </ButtonText>
          </>
        ) : (
          <>
            {editable && (
              <div
                className={sassStyles.Button}
                onClick={onClickEdit}
                onKeyPress={onClickEdit}
                role="button"
                tabIndex="0"
              >
                <img
                  alt="edit"
                  style={{ width: '19px', height: '18px' }}
                  src={iconEdit}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

const styles = {
  container: {
    height: '50px',
    backgroundColor: 'white',
    padding: '0px 34px 0px 50px',
    display: 'flex'
  },
  titleCell: {
    // flex: 1,
    width: '260px',
    marginRight: '20px',
    display: 'flex',
    alignSelf: 'center',
    fontFamily: 'Nunito-Bold',
    color: colors.black
  },
  valueCell: {
    flex: 1,
    display: 'flex',
    alignSelf: 'stretch',
    alignItems: 'center'
  },
  controlsCell: {
    // width: '250px',
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'flex-end'
  },
  valueText: {
    fontFamily: 'Nunito-Regular',
    fontSize: '15px',
    color: colors.black,
    // textTransform: 'uppercase',
    marginBottom: '1.5px',
    marginLeft: '1px'
  },
  input: {
    height: '100%',
    fontFamily: 'Nunito-Regular',
    fontSize: '15px',
    color: colors.black,
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    width: '100%'
    // textTransform: 'uppercase'
  },
  error: {
    fontSize: '11px',
    fontFamily: 'Nunito-SemiBold',
    color: 'red',
    height: '100%',
    marginRight: '20px'
  }
};

EditableTableRow.propTypes = {
  key: PropTypes.string.isRequired,
  attributeName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  displayName: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  displayValue: PropTypes.string.isRequired,
  onTextChange: PropTypes.func.isRequired,
  dropdownOptions: PropTypes.object,
  editable: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  customStyles: PropTypes.object.isRequired,
  onClearError: PropTypes.func.isRequired,
  validator: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired
};

EditableTableRow.defaultProps = {
  type: 'text',
  dropdownOptions: {}
};

export default EditableTableRow;
