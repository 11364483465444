import React, { useState } from 'react';
import PropTypes from 'prop-types';

import colors from '../../../../styles/colors';
import ButtonText from '../../../../components/presentational/ButtonText/ButtonText';
import OrgForm from './OrgForm';

const Edit = ({ data, onUpdate, orgTypes }) => {
  const {
    name,
    account_type_id,
    tax_id_num,
    facility_name,
    phone_main,
    trilogy_org_num,
    parent_org_1,
    parent_org_2,
    logo_asset_url
  } = data;
  const { address_line_1, address_line_2, city, state, zip_code, country } = data.address;
  const [newData, setNewData] = useState({
    name,
    account_type_id,
    tax_id_num,
    facility_name,
    phone_main,
    trilogy_org_num,
    parent_org_1,
    parent_org_2,
    logo_asset_url,
    address_line_1,
    address_line_2,
    city,
    state,
    zip_code,
    country
  });
  return (
    <div className="dark-form plan-form">
      <OrgForm data={newData} setData={setNewData} orgTypes={orgTypes} />
      <ButtonText
        className="submit-btn"
        customStyles={{
          backgroundColor: colors.blueThree,
          color: colors.white
        }}
        onClick={() => onUpdate(data.id, newData)}
      >
        Save
      </ButtonText>
    </div>
  );
};

Edit.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  orgTypes: PropTypes.array.isRequired
};

export default Edit;
