import colors from '../../../../../styles/colors';

export default {
  table: {
    display: 'flex',
    flexDirection: 'column'
  },
  tableHeader: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  tableBody: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  tableRow: {
    border: 'none',
    display: 'flex',
    flex: 1
  },
  tableCell: {
    backgroundColor: colors.blueDarkTwo,
    border: 'none',
    color: colors.white,
    flex: 1,
    fontFamily: 'Nunito-ExtraBold',
    fontSize: '12px',
    lineHeight: '28px',
    overflow: 'hidden',
    padding: '7px 35px',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase'
  },
  headerCell: {
    backgroundColor: colors.blueTwo,
    letterSpacing: '2px',
    paddingBottom: '10px',
    paddingTop: '12px'
  }
};
