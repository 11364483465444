import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { Auth } from 'aws-amplify';

import colors from '../../../styles/colors';
import BEInput from '../../presentational/BEInput/BEInput';

class BEForgotPasswordContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      resetEmailSent: false,
      email: '',
      confirmationCode: '',
      newPassword: '',
      confirmNewPassword: '',

      errorMessage: ''
    };

    this.sendResetEmail = this.sendResetEmail.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  async sendResetEmail() {
    try {
      await Auth.forgotPassword(this.state.email);

      this.setState({
        resetEmailSent: true
      });
    } catch (err) {
      this.setState({
        errorMessage: err.message || err
      });
    }
  }

  async resetPassword() {
    try {
      if (this.state.newPassword !== this.state.confirmNewPassword) {
        this.setState({
          errorMessage: "The passwords don't match."
        });
        return;
      }
      await Auth.forgotPasswordSubmit(
        this.state.email,
        this.state.confirmationCode,
        this.state.newPassword
      );

      // Then navigate to the main app
      this.props.history.push({
        pathname: `/auth/signIn`
      });
    } catch (err) {
      this.setState({
        errorMessage: err.message || err
      });
    }
  }

  render() {
    const sendEmailUI = (
      <>
        <div style={styles.label}>
          <p style={styles.labelText}>Email</p>
        </div>
        <BEInput
          value={this.state.email}
          onChange={e => this.setState({ email: e.target.value })}
        />
        <div style={styles.break} />

        <p style={styles.errorMessage}>{this.state.errorMessage}</p>

        <div style={styles.break} />
        <div style={styles.break} />
        <Button primary onClick={this.sendResetEmail}>
          Send Reset Code
        </Button>
      </>
    );

    const resetPasswordUI = (
      <>
        <p>
          An email was sent to <b>{this.state.email}</b>. Enter the enclosed
          confirmation code below and choose a new password.
        </p>
        <div style={styles.break} />

        <div style={styles.label}>
          <p style={styles.labelText}>Confirmation Code</p>
        </div>
        <BEInput
          value={this.state.confirmationCode}
          onChange={e => this.setState({ confirmationCode: e.target.value })}
        />
        <div style={styles.break} />

        <div style={styles.label}>
          <p style={styles.labelText}>New Password</p>
        </div>
        <BEInput
          value={this.state.newPassword}
          type="password"
          onChange={e => this.setState({ newPassword: e.target.value })}
        />
        <div style={styles.break} />

        <div style={styles.label}>
          <p style={styles.labelText}>Confirm New Password</p>
        </div>
        <BEInput
          value={this.state.confirmNewPassword}
          type="password"
          onChange={e => this.setState({ confirmNewPassword: e.target.value })}
        />
        <div style={styles.break} />

        <p style={styles.errorMessage}>{this.state.errorMessage}</p>

        <div style={styles.break} />
        <div style={styles.break} />
        <Button primary onClick={this.resetPassword}>
          Reset password
        </Button>
      </>
    );

    return (
      <>
        <div style={styles.header}>
          <h1>Forgot Password</h1>
        </div>

        {!this.state.resetEmailSent ? sendEmailUI : resetPasswordUI}
      </>
    );
  }
}

const styles = {
  header: {
    height: '70px'
  },
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '7px'
  },
  labelText: {
    fontFamily: 'Nunito-SemiBold'
  },
  break: {
    height: '14px'
  },
  errorMessage: {
    fontSize: '11px',
    fontFamily: 'Nunito-SemiBold',
    color: 'red',
    height: '20px'
  },
  finePrint: {
    fontSize: '11px',
    fontFamily: 'Nunito-SemiBold',
    color: colors.grayDark
  }
};

BEForgotPasswordContainer.propTypes = {
  history: PropTypes.object.isRequired
};
export default withRouter(BEForgotPasswordContainer);
