import React from 'react';
import PropTypes from 'prop-types';
import { Table, Loader } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actionCreators';

import colors from '../../../styles/colors';

function RecordDetailTable({ loading, tableData }) {
  const isTablet = useMediaQuery({ query: '(max-width: 1224px)' });
  const containerWidth = `calc(100vw - 3vw - 3vw${
    isTablet ? '' : ' - 288px - 32px'
  })`;

  if (loading) {
    return (
      <div style={{ ...styles.emptyResultsContainer, width: containerWidth }}>
        <Loader active inline="centered">
          Fetching records...
        </Loader>
      </div>
    );
  }

  if (tableData.length === 0) {
    return <div>Nothing</div>;
  }

  const rows = tableData.map((attribute, i) => {
    if (!attribute) {
      return null;
    }
    const cells = [
      <Table.Cell
        key={attribute.displayName}
        style={{
          ...styles.tableCell,
          fontFamily: 'Nunito-Bold',
          color: colors.black
        }}
      >
        {attribute.displayName}
      </Table.Cell>,
      <Table.Cell key={attribute.value} style={styles.tableCell}>
        {attribute.value}
      </Table.Cell>
    ];
    return (
      <Table.Row
        key={attribute.displayName}
        style={{
          ...styles.tableRow,
          borderTopLeftRadius: i === 0 ? '8px' : '0px',
          borderTopRightRadius: i === 0 ? '8px' : '0px',
          borderBottomLeftRadius: i === tableData.length - 1 ? '8px' : '0px',
          borderBottomRightRadius: i === tableData.length - 1 ? '8px' : '0px'
        }}
      >
        {cells}
      </Table.Row>
    );
  });

  return (
    <div style={{ ...styles.dataContainer, width: containerWidth }}>
      <Table
        style={styles.table}
        columns={6}
        selectable
        striped
        size="small"
        compact
        // singleLine
        basic="very"
        // padded="very"
        className="table"
      >
        <Table.Body style={styles.tableBody}>{rows}</Table.Body>
      </Table>
    </div>
  );
}

RecordDetailTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  tableData: PropTypes.array.isRequired
};

const styles = {
  dataContainer: {
    backgroundColor: colors.white,
    boxShadow: '0 2px 56px rgba(0, 0, 0, 0.07)',
    borderRadius: '8px'
  },
  table: {
    display: 'flex',
    flexDirection: 'column'
  },
  tableHeader: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  tableBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  headerCell: {
    color: colors.bluePrimary,
    textTransform: 'uppercase',
    fontWeight: 'normal',
    fontSize: '11px'
  },
  tableRow: {
    flex: 1,
    display: 'flex',
    border: 'none',
    paddingLeft: '1vw',
    paddingRight: '1vw'
  },
  tableCell: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    backgroundColor: 'transparent',
    border: 'none',
    paddingTop: '15px',
    paddingBottom: '15px',
    fontFamily: 'Nunito-Light',
    fontSize: '14px'
  },
  emptyResultsContainer: {
    height: '60vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
};
function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state, props) => {
  const { fetchingRecords, records } = state.queryResults;

  return {
    ...props,
    fetchingRecords,
    records
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RecordDetailTable)
);
