import React, { useState, useEffect } from 'react';
import { Form, Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { getPlans } from '../../../../API/plans';

const RESTRICTIONS_RELATIONS = {
  default_user_plan: async () => {
    try {
      const plans = await getPlans();

      return plans.data.map(plan => ({
        key: `${plan.id}-${plan.name}`,
        text: plan.name,
        value: plan.id
      }));
    } catch (_e) {
      return [];
    }
  },
  default_site_plan: async () => {
    try {
      const plans = await getPlans();

      return plans.data.map(plan => ({
        key: `${plan.id}-${plan.name}`,
        text: plan.name,
        value: plan.id
      }));
    } catch (_e) {
      return [];
    }
  }
};

const ReferenceInput = ({ restriction, value, onChange }) => {
  const [references, setReferences] = useState([]);

  useEffect(() => {
    const getReferences = async () => {
      setReferences(await RESTRICTIONS_RELATIONS[restriction.key]());
    };
    getReferences();
  }, []);

  return (
    <div className="field">
      <Dropdown placeholder="Select one" fluid selection options={references} onChange={onChange} value={value} />
    </div>
  );
};

const RestrictionInput = ({ restriction, value, onChange }) => {
  switch (restriction.type) {
    case 'reference':
      return <ReferenceInput restriction={restriction} value={value} onChange={onChange} />;
    default:
      return <Form.Input type={restriction.type} value={value} onChange={onChange} />;
  }
};

RestrictionInput.propTypes = {
  restriction: PropTypes.object.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
};

ReferenceInput.propTypes = {
  restriction: PropTypes.object.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
};

export default RestrictionInput;
