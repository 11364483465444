import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actionCreators';

import ControlsWrapper from '../../presentational/ControlsWrapper/ControlsWrapper';
import ButtonText from '../../presentational/ButtonText/ButtonText';
import BEDropdownMulti from '../../presentational/BEDropdownMulti/BEDropdownMulti';
import PeriodicTableFilter from '../../presentational/PeriodicTableFilter';

function FilterContainer({ filters, filterKeys, r_toggleFilterOption, r_queryFormulary, r_resetFilters, history }) {
  const dropdowns = filterKeys.map(filterKey => {
    if (filterKey === 'molecular_formula_api') {
      const selectedOptions = Object.values(filters[filterKey].filterOptions)
        .filter(el => el.selected)
        .map(el => el.id);
      return (
        <PeriodicTableFilter
          key={filterKey}
          options={filters[filterKey].filterOptions}
          placeholder={selectedOptions.length ? JSON.stringify(selectedOptions) : filters[filterKey].displayName}
          toggleOption={async optionId => {
            await r_toggleFilterOption(filterKey, optionId);
          }}
          applySelection={() => r_queryFormulary(history)}
        />
      );
    }

    // Create an array of option IDS, sorted by the option name
    const sortedIds = Object.values(filters[filterKey].filterOptions)
      .sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        return 1;
      })
      .map(value => value.id);

    return (
      <BEDropdownMulti
        key={filterKey}
        options={filters[filterKey].filterOptions}
        optionIds={sortedIds}
        placeholder={filters[filterKey].displayName}
        style={{ marginTop: '15px' }}
        toggleOption={async optionId => {
          await r_toggleFilterOption(filterKey, optionId);
        }}
        applySelection={() => r_queryFormulary(history)}
      />
    );
  });

  return (
    <ControlsWrapper title="Filter">
      <ButtonText
        onClick={async () => {
          await r_resetFilters();
          r_queryFormulary(history);
        }}
        disabled={false}
      >
        Clear all filters
      </ButtonText>

      {dropdowns}
    </ControlsWrapper>
  );
}

FilterContainer.propTypes = {
  filters: PropTypes.object.isRequired,
  filterKeys: PropTypes.array.isRequired,
  r_toggleFilterOption: PropTypes.func.isRequired,
  r_queryFormulary: PropTypes.func.isRequired,
  r_resetFilters: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state, props) => {
  const { filters, filterKeys } = state.query;

  return {
    ...props,
    filters,
    filterKeys
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FilterContainer)
);
