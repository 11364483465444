import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { readString, CSVDownloader } from 'react-papaparse';

import BEDropdownSingle from '../../../../../components/presentational/BEDropdownSingle/BEDropdownSingle';
import colors from '../../../../../styles/colors';
import SearchBar from './SearchBar';

const accept = '.csv';

const SEARCH_BY_OPTIONS = [
  { key: 'email', text: 'Email', value: 'email' },
  { key: 'account_number', text: 'Customer #', value: 'account_number' },
  { key: 'site_id', text: 'Trilogy Site Id', value: 'site_id' }
];

const handleUploadFile = (file, setFileFilters) => {
  const reader = new FileReader();

  reader.onload = () => {
    const csvData = readString(reader.result).data;
    const headers = csvData.shift();
    const result = {};
    headers.forEach((header, idx) => {
      result[header.toLowerCase().replace(/\s/g, '_')] = csvData.map(row => row[idx]);
    });
    setFileFilters(result);
  };
  reader.readAsText(file);
};

const Filter = ({ filters, onFilter, onMainSearch, orgs }) => {
  const setFileFilters = file => {
    onFilter({ ...filters, file });
    onMainSearch({ ...filters, file }, 1);
  };
  const onDropAccepted = useCallback(acceptedFiles => {
    handleUploadFile(acceptedFiles[0], setFileFilters);
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDropAccepted,
    accept,
    noClick: true,
    noKeyboard: true
  });

  const orgOptions = Object.keys(orgs).map(orgId => ({
    key: orgId,
    value: orgId,
    text: orgs[orgId]
  }));

  return (
    <div
      {...getRootProps({
        className: `dropzone search-container ${isDragActive ? 'active' : ''}`
      })}
    >
      <div>
        <input {...getInputProps()} />
        {!!orgOptions.length && (
          <>
            <label>Organization: </label>
            <BEDropdownSingle
              value={filters.organization || ''}
              onSelect={async organization => {
                onFilter({ ...filters, organization });
                onMainSearch({ ...filters, organization }, 1);
              }}
              options={[{ key: 'all', value: '', text: 'All' }, ...orgOptions]}
              width={180}
              btnStyle={{ backgroundColor: colors.blueTwo }}
              titleStyle={{ color: colors.white }}
              iconStyle={{ color: colors.white }}
            />
          </>
        )}
      </div>

      {!isDragActive && !filters.file && (
        <div className="search-bar">
          <SearchBar
            search={(filterName, filterValue) => {
              onMainSearch(
                {
                  ...filters,
                  filterName,
                  filterValue
                },
                1
              );
            }}
            setName={filterName => onFilter({ ...filters, filterName })}
            setValue={filterValue => onFilter({ ...filters, filterValue })}
            name={filters.filterName || 'email'}
            value={filters.filterValue || ''}
            dropdownOption={SEARCH_BY_OPTIONS}
          />
          <div className="upload-container">
            <button type="button" onClick={open} className="upload-btn" style={{ color: colors.blueThree }}>
              Upload CSV
            </button>
            <span>or Drag and Drop the file here.</span>
            <CSVDownloader
              filename="user-filter-csv-template"
              data={[
                {
                  email: '(Optional) user email',
                  account_number: '(Optional) site customer #',
                  site_id: '(Optional) site id'
                }
              ]}
              style={{ cursor: 'pointer' }}
            >
              {' '}
              Download the Filter Template
            </CSVDownloader>
          </div>
        </div>
      )}
      {isDragActive && (
        <div className="hint-message">
          <label>Drop the file here to use it to filter user...</label>
        </div>
      )}
      {!isDragActive && filters.file && (
        <div className="hint-message">
          <label>Filtered by file</label>
          {Object.keys(filters.file).map(key => (
            <div>
              {key}: {filters.file[key].length}
            </div>
          ))}
          <button
            type="button"
            onClick={() => setFileFilters(null)}
            className="upload-btn"
            style={{ color: colors.blueThree }}
          >
            Clear
          </button>
        </div>
      )}
    </div>
  );
};

Filter.propTypes = {
  filters: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
  onMainSearch: PropTypes.func.isRequired,
  orgs: PropTypes.object.isRequired
};

export default Filter;
