import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import PeriodicTableFilterModal from './Modal';
import './styles.scss';

const PeriodicTableFilter = ({ options, placeholder, toggleOption, applySelection }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedElements = Object.keys(options).filter(key => options[key].selected);

  return (
    <div className="filter-container">
      <div
        className="dropdown-button"
        onClick={() => setIsOpen(true)}
        onKeyPress={() => setIsOpen(true)}
        role="button"
        tabIndex="0"
      >
        <p>{placeholder}</p>
        <Icon name="chevron down" size="small" />
      </div>
      {isOpen && (
        <PeriodicTableFilterModal
          onClose={() => setIsOpen(false)}
          onSearch={() => applySelection()}
          toggleOption={toggleOption}
          selectedElements={selectedElements}
        />
      )}
    </div>
  );
};

PeriodicTableFilter.propTypes = {
  options: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  toggleOption: PropTypes.func.isRequired,
  applySelection: PropTypes.func.isRequired
};

export default PeriodicTableFilter;
