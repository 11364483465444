import React, { useState, useEffect } from 'react';

import Breadcrumb from './Breadcrumb';
import ListView from './ListView';
import Edit from './Edit';
import Create from './Create';
import colors from '../../../../styles/colors';
import ButtonText from '../../../../components/presentational/ButtonText/ButtonText';
import { getPlans, createPlan, updatePlan, getPlanRestrictions } from '../../../../API/plans';

import './Plan.scss';
import '../../ControlCenter.scss';

const PlansView = () => {
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [isCreatePlan, setIsCreatePlan] = useState(false);
  const [plans, setPlans] = useState([]);
  const [restrictions, setRestrictions] = useState([]);

  const onUpdate = async (id, data) => {
    setLoadingData(true);

    try {
      await updatePlan(id, data);
      setSelectedItem(null);
      await loadPlans();
    } catch (_e) {
      setError('Something went wrong, please try again');
    }

    setLoadingData(false);
  };

  const onCreatePlan = async data => {
    setLoadingData(true);

    try {
      await createPlan(data);
      setIsCreatePlan(false);
      await loadPlans();
    } catch (_e) {
      setError('Something went wrong, please try again');
    }

    setLoadingData(false);
  };

  const loadPlans = async () => {
    setLoadingData(true);

    try {
      const planResponse = await getPlans();
      const restrictionResponse = await getPlanRestrictions();
      setPlans(planResponse.data);
      setRestrictions(restrictionResponse.data);
    } catch (_e) {
      setError('Something went wrong, please try again');
    }

    setLoadingData(false);
  };

  useEffect(() => {
    loadPlans();
  }, []);

  return (
    <div className={`plan-container control-center-view-container ${loadingData ? 'loading' : ''}`}>
      <Breadcrumb
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
        isCreatePlan={isCreatePlan}
        setIsCreatePlan={setIsCreatePlan}
      />
      {!!error && <div className="error">{error}</div>}
      {!selectedItem && !isCreatePlan && (
        <>
          <ButtonText
            className="submit-btn"
            customStyles={{
              backgroundColor: colors.blueThree,
              color: colors.white
            }}
            onClick={() => setIsCreatePlan(true)}
          >
            Add new
          </ButtonText>
          {!!plans.length && <ListView data={plans} selectItem={setSelectedItem} />}
        </>
      )}
      {!!selectedItem && <Edit data={selectedItem} onUpdate={onUpdate} restrictions={restrictions} />}
      {isCreatePlan && <Create onCreatePlan={onCreatePlan} restrictions={restrictions} />}
    </div>
  );
};

export default PlansView;
