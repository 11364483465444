import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import colors from '../../../styles/colors';
import birdseyeLoginLogo from '../../../Assets/Images/birds-eye-login-logo.png';

import BELoginContainer from '../BELoginContainer/BELoginContainer';
import BEResetPasswordContainer from '../BEResetPasswordContainer/BEResetPasswordContainer';
import BEConfigureAccountContainer from '../BEConfigureAccountContainer/BEConfigureAccountContainer';

class BEAuthContainer extends Component {
  constructor(props) {
    super(props);
    const host = process.env.REACT_APP_HOST;
    this.state = {
      isProduction: host !== 'localhost:3000'
    };
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser().then(user => {
      // The user is already authenticated! Go to root.
      this.props.history.push('/');
    });
    // .catch(() => {
    //   // console.log('BEAuthContainer pushing sign in');
    //   this.props.history.push('/auth/signIn');
    // });
  }

  render() {
    return (
      <div style={styles.background}>
        <div style={styles.centerContent}>
          <div style={styles.logoContainer}>
            <img alt="birdseye logo" src={birdseyeLoginLogo} width={164} />
          </div>
          <div style={styles.authComponent}>
            <Switch>
              <Route exact path="/auth/signIn" component={BELoginContainer} />
              <Route
                exact
                path="/auth/resetPassword"
                component={BEResetPasswordContainer}
              />
              <Route
                exact
                path="/auth/configureAccount"
                component={BEConfigureAccountContainer}
              />
              <Route
                path="/auth"
                render={() => <Redirect to="/auth/signIn" />}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

BEAuthContainer.propTypes = {
  history: PropTypes.object.isRequired
};

const styles = {
  background: {
    minHeight: '100vh',
    overflow: 'auto',
    width: '100%',
    backgroundColor: colors.white,
    paddingTop: '10vh',
    display: 'flex',
    justifyContent: 'center'
  },
  centerContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logoContainer: {
    height: '120px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  authComponent: {
    width: '370px',
    padding: '45px',
    boxShadow: '0 2px 56px rgba(0, 0, 0, 0.07)',
    border: `1px solid #e3e7f2`,
    borderRadius: '8px'
  }
};
export default withRouter(BEAuthContainer);
