import { types } from '../actionCreators'; // Import the actions types constant we defined in our actions
import filterDisplayNames from '../../filterDisplayNames';

export const query = {
  orgs: [],
  fetchingOrgs: false,
  orgId: 'all',

  sites: [],
  fetchingSites: false,
  siteId: 'all',

  view: 'table',
  search_by: 'all', // can be ndc, upc, product_name, substance_name, or pill_identifier
  fetchingSearchSuggestions: false,
  searchSuggestions: [],
  search_query: '', // the search input box
  search_query_all: {}, // search query object to allow for searching by more than one column when filter is set to "all"
  filters: null, // object representing selected filters
  group_by: Object.keys(filterDisplayNames)[0] // Default group_by is the first filter option
};

export default (state = {}, action) => {
  switch (action.type) {
    case types.FETCHING_ORGS:
      return {
        ...state,
        fetchingOrgs: true
      };
    case types.FETCHED_ORGS:
      return {
        ...state,
        orgs: action.data.orgs,
        fetchingOrgs: false
      };
    case types.SET_ORG_ID:
      return {
        ...state,
        orgId: action.data.orgId
      };

    case types.FETCHING_SITES:
      return {
        ...state,
        fetchingSites: true
      };
    case types.SET_SITES:
      return {
        ...state,
        sites: action.data.sites,
        fetchingSites: false
      };
    case types.SET_SITE_ID:
      return {
        ...state,
        siteId: action.data.siteId
      };

    case types.SET_VIEW:
      return {
        ...state,
        view: action.data.view
      };

    case types.SET_SEARCH_BY:
      return {
        ...state,
        search_by: action.data.search_by
      };
    case types.SET_SEARCH_QUERY:
      return {
        ...state,
        search_query: action.data.search_query
      };
    case types.SET_SEARCH_QUERY_ALL:
      return {
        ...state,
        search_query_all: action.data.search_query_all
      };

    case types.FETCHING_SEARCH_SUGGESTIONS:
      return {
        ...state,
        fetchingSearchSuggestions: true
      };

    case types.FETCHED_SEARCH_SUGGESTIONS:
      return {
        ...state,
        fetchingSearchSuggestions: false,
        searchSuggestions: action.data.searchSuggestions
      };

    case types.APPLY_FILTERS: {
      const filters = JSON.parse(JSON.stringify(action.data.filters));
      const keys = Object.keys(filters);
      return {
        ...state,
        filters: action.data.filters,
        filterKeys: keys
      };
    }

    case types.TOGGLE_FILTER_OPTION: {
      const currentlySelected =
        state.filters[action.data.filterKey].filterOptions[action.data.optionId]
          .selected;

      return {
        ...state,
        filters: {
          ...state.filters,
          [action.data.filterKey]: {
            ...state.filters[action.data.filterKey],
            filterOptions: {
              ...state.filters[action.data.filterKey].filterOptions,
              [action.data.optionId]: {
                ...state.filters[action.data.filterKey].filterOptions[
                  action.data.optionId
                ],
                selected: !currentlySelected
              }
            }
          }
        }
      };
    }

    case types.SET_FILTER_OPTION:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.data.filterKey]: {
            ...state.filters[action.data.filterKey],
            filterOptions: {
              ...state.filters[action.data.filterKey].filterOptions,
              [action.data.optionId]: {
                ...state.filters[action.data.filterKey].filterOptions[
                  action.data.optionId
                ],
                selected: true
              }
            }
          }
        }
      };

    case types.SET_GROUP_BY:
      return {
        ...state,
        group_by: action.data.group_by
      };
    case types.SIGN_OUT:
      return { ...query };
    default:
      return state;
  }
};
