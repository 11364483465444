import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

import ModalWrapper from '../../../../components/presentational/ModalWrapper/ModalWrapper';
import ModalHeader from '../../../../components/presentational/ModalHeader/ModalHeader';
import ModalBody from '../../../../components/presentational/ModalBody/ModalBody';
import ModalFooter from '../../../../components/presentational/ModalFooter/ModalFooter';
import ButtonText from '../../../../components/presentational/ButtonText/ButtonText';
import colors from '../../../../styles/colors';
import { getDate, mapPlanToSelect, dateToString } from '../../helpers';
import { getPlans, assignMemberPlan, grantMemberFullAccess } from '../../../../API/plans';

const BulkUserPlanModal = ({ users, orgs, onClose, onUpdate }) => {
  const [error, setError] = useState();
  const [plan, setPlan] = useState({});
  const [plans, setPlans] = useState([]);

  const orgOptions = Object.keys(orgs).map(orgId => ({
    key: orgId,
    value: orgId,
    text: orgs[orgId]
  }));

  const loadPlans = async () => {
    try {
      const response = await getPlans('member');
      setPlans(mapPlanToSelect(response.data));
    } catch (_e) {
      setError('Something went wrong, please try again!');
    }
  };

  const updatePlan = async ({ cognito_id, email }) => {
    try {
      if (!plan.plan_id || !plan.start_at) {
        setError('Please fill all required field');
        return;
      }
      await assignMemberPlan(plan.organization_id, cognito_id, plan);
    } catch (e) {
      setError(`Something went wrong updating the plan of ${email}, please try again!`);
    }
  };

  const removePlan = async ({ cognito_id, email }) => {
    try {
      await grantMemberFullAccess(plan.organization_id, cognito_id);
    } catch (e) {
      setError(`Something went wrong updating the plan of ${email}, please try again!`);
    }
  };

  const processPlan = async (users, counter, isRemove) => {
    if (!users.length) {
      return;
    }

    isRemove ? await removePlan(users.shift()) : await updatePlan(users.shift());
    onUpdate(counter + 1);
    await processPlan(users, counter + 1, isRemove);
  };

  useEffect(() => {
    loadPlans();
  }, []);

  return (
    <ModalWrapper className="dark-modal" customStyles={{ modal: { backgroundColor: colors.blueFive } }}>
      <ModalHeader title="User plan" onClose={onClose} />
      <ModalBody confirmation={false}>
        {!!error && <div className="error">{error}</div>}
        <Form className="dark-form" inverted>
          <Form.Select
            fluid
            label="Plan"
            options={plans}
            placeholder="Plan"
            value={plan.plan_id}
            onChange={(_e, { value }) => setPlan({ ...plan, plan_id: value })}
          />
          <Form.Group widths="equal">
            <Form.Field className="datepicker">
              <label>Start Date</label>
              <SemanticDatepicker
                onChange={(_e, { value }) => setPlan({ ...plan, start_at: dateToString(value) })}
                value={plan.start_at && getDate(plan.start_at)}
              />
            </Form.Field>
            <Form.Field className="datepicker">
              <label>End Date (Empty to Unlimited)</label>
              <SemanticDatepicker
                onChange={(_e, { value }) => setPlan({ ...plan, end_at: dateToString(value) })}
                value={plan.end_at && getDate(plan.end_at)}
                minDate={(plan.start_at && getDate(plan.start_at)) || Date.now()}
              />
            </Form.Field>
          </Form.Group>
          <Form.Select
            fluid
            label="Organization"
            options={orgOptions}
            placeholder="Organization"
            value={plan.organization_id}
            onChange={(_e, { value }) => setPlan({ ...plan, organization_id: value })}
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <div className="flex justify-content">
          <ButtonText className="submit-btn" onClick={onClose}>
            Cancel
          </ButtonText>
          <ButtonText
            className="submit-btn"
            customStyles={{
              backgroundColor: colors.blueThree,
              color: colors.white
            }}
            onClick={() => processPlan([...users], 0, false)}
          >
            Save
          </ButtonText>
          <ButtonText
            className="submit-btn big"
            customStyles={{
              backgroundColor: colors.green,
              color: colors.white
            }}
            onClick={() => processPlan([...users], 0, true)}
          >
            Grant Full Access
          </ButtonText>
        </div>
      </ModalFooter>
    </ModalWrapper>
  );
};

BulkUserPlanModal.propTypes = {
  users: PropTypes.array.isRequired,
  orgs: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
};

export default BulkUserPlanModal;
