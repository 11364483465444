import API from './base';

export const getOrgs = (data, page) => API.managmentPost(`organizations/search/page/${page}`, data);

export const getOrgsList = () => API.managmentGet(`organizations/all`);

export const getAllSites = (data, page) => API.managmentPost(`organizations/all/sites/search/page/${page}`, data);

export const getOrgSites = (orgId, data, page) =>
  API.managmentPost(`organizations/${orgId}/sites/search/page/${page}`, data);

export const removeOrgSite = (orgId, siteId, queryString) =>
  API.managmentDelete(`organizations/${orgId}/sites/${siteId}?${queryString}`);

export const getOrg = orgId => API.managmentGet(`organizations/${orgId}`);

/** Allowed Email Domains */
export const getOrgAllowedEmailDomains = orgId => API.managmentGet(`organizations/${orgId}/emailDomains`);

export const addOrgAllowedEmailDomain = (orgId, data) => API.managmentPost(`organizations/${orgId}/emailDomains`, data);

export const removeOrgAllowedEmailDomain = (orgId, domainId) =>
  API.managmentDelete(`organizations/${orgId}/emailDomains/${domainId}`);

/** Organization Address */
export const refreshOrganizationAddresses = () => API.managmentPost(`organizations/address/refresh`, {});

export const getOrgTypes = () => API.managmentGet('organizations/types');

export const createOrg = data => API.managmentPost('organizations', data);

export const updateOrg = (orgId, data) => API.managmentPatch(`organizations/${orgId}`, data);

export const updateDomain = data => API.managmentPost('domains', data);
