import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import colors from '../../styles/colors';

const StyledNavLink = styled(NavLink)`
  color: ${colors.white};
  display: block;
  font-family: Nunito-Bold;
  font-size: 12px;
  line-height: 28px;
  padding: 8px 0px 8px 15px;

  &:hover {
    color: ${colors.white};
  }
`;

const SidebarLink = ({ to, children }) => {
  return (
    <div className="SidebarLink">
      <StyledNavLink
        activeStyle={{ backgroundColor: colors.blueThree }}
        to={to}
      >
        {children}
      </StyledNavLink>
    </div>
  );
};

SidebarLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired
};

export default SidebarLink;
