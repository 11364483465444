import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as Actions from '../../../../../redux/actionCreators';
/** Components */
import ButtonText from '../../../../../components/presentational/ButtonText/ButtonText';
/** Services */
import { getCurrentDate } from '../../../../../services';
/** Styling */
import colors from '../../../../../styles/colors';

function MasterDBExport({ query }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const exportDB = async () => {
    try {
      setIsLoading(true);
      console.log({ query });
      const sendByEmail = true;
      await Actions.exportCSVFormulary(query, `master_DB_Export_${getCurrentDate()}.csv`, sendByEmail);
    } catch (err) {
      setError(err.message || JSON.stringify(err));
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  return (
    <div className="control-center-view-container">
      <div className="admin-configuration-container">
        <h1>Export Master Database:</h1>

        {!!error && <h2 className="error">{error}</h2>}

        <div className="config-form-container">
          <ButtonText
            isLoading={isLoading}
            customStyles={{
              backgroundColor: colors.blueThree,
              color: colors.white,
              borderColor: colors.blueThree,
              padding: '.78571429em 1.5em .78571429em',
              borderRadius: 5,
              float: 'right'
            }}
            onClick={exportDB}
          >
            <span className="button__text">Export</span>
          </ButtonText>
        </div>
      </div>
    </div>
  );
}

MasterDBExport.propTypes = {
  query: PropTypes.objectOf(PropTypes.any).isRequired
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state, props) => {
  const { query } = state;
  return {
    ...props,
    query
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MasterDBExport)
);
