import React, { useState, useEffect } from 'react';

import Breadcrumb from './Breadcrumb';
import ListView from './ListView';
import { getOrgs, getOrg, getOrgTypes, createOrg, updateOrg, updateDomain } from '../../../../API/organizations';
import OrgDetailView from './OrgDetailView';
import Edit from './Edit';
import Create from './Create';

import '../Customers/Customer.scss';
import '../../ControlCenter.scss';
import { ORG_ORDER_FIELDS } from '../Customers/mappers';

const CustomersPendingView = () => {
  const [error, setError] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const [orgs, setOrgs] = useState({});
  const [orgTypes, setOrgTypes] = useState([]);
  const [orderBy, setOrderBy] = useState();
  const [mainFilterValue, setMainFilterValue] = useState('');
  const [mainFilterName, setMainFilterName] = useState('all');
  const [organizationDetail, setOrganizationDetail] = useState();
  const [isCreate, setCreate] = useState(false);
  const [isEdit, setEdit] = useState(false);

  const searchOrgs = async (dataFilter, page) => setOrgs((await getOrgs(dataFilter, page)).data);
  const getOrgDetail = async orgId => setOrganizationDetail((await getOrg(orgId)).data);

  const onMainSearch = async (filters, page) => {
    setError();
    setLoadingData(true);

    try {
      const [orderName, orderValue] = (orderBy || '').split(',');
      const dataFilter = { ...filters, perPage: 10, pending: true };

      const orgOrder = Object.values(ORG_ORDER_FIELDS).includes(orderName)
        ? { orderBy: `${orderName} ${orderValue}` }
        : {};

      searchOrgs({ ...dataFilter, ...orgOrder }, page);
    } catch (e) {
      setError('Something went wrong, please try again!');
    }

    setLoadingData(false);
  };

  const onSort = async key => {
    const [orderName, orderValue] = (orderBy || '').split(',');
    let type = 'asc';
    if (key === orderName) type = orderValue === 'asc' ? 'desc' : 'asc';

    await setOrderBy(`${key},${type}`);
    document.getElementById('force-search-customers').click();
  };

  const onUpdate = async (id, data) => {
    try {
      setError();
      await updateOrg(id, data);
      onMainSearch({}, 1);
      setEdit(false);
    } catch (e) {
      if (e.response && e.response.data && e.response.data[0]) {
        setError(e.response.data[0].message || 'Something went wrong, please try again!');
      } else {
        setError('Something went wrong, please try again!');
      }
    }
    setEdit(false);
  };

  const onSave = async data => {
    try {
      setError();
      await createOrg(data);
      onMainSearch({}, 1);
      setCreate(false);
    } catch (e) {
      if (e.response && e.response.data && e.response.data[0]) {
        setError(e.response.data[0].message || 'Something went wrong, please try again!');
      } else {
        setError('Something went wrong, please try again!');
      }
    }
  };

  const onAddDomain = async data => {
    try {
      setError();
      await updateDomain(data);
      setSelectedItem();
      onMainSearch({}, 1);
    } catch (e) {
      setError('Something went wrong, please try again!');
    }
  };

  useEffect(() => {
    const getTypes = async () => {
      try {
        const types = await getOrgTypes();
        setOrgTypes(types.data);
      } catch (e) {
        setError('Something went wrong, please try again!');
      }
    };
    getTypes();
    onMainSearch({}, 1);
  }, []);

  return (
    <div className={`customer-container control-center-view-container ${loadingData ? 'loading' : ''}`}>
      <Breadcrumb
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
        isCreate={isCreate}
        isEdit={isEdit}
        setEdit={setEdit}
        setCreate={setCreate}
        setError={setError}
      />
      {!!error && <h1 className="error">{error}</h1>}
      {!selectedItem && !isCreate && (
        <ListView
          selectItem={setSelectedItem}
          listResults={orgs}
          onSearch={onMainSearch}
          filterValue={mainFilterValue}
          setFilterValue={setMainFilterValue}
          filterName={mainFilterName}
          setFilterName={setMainFilterName}
          onSort={onSort}
          orderBy={orderBy}
          setCreate={setCreate}
        />
      )}
      {!!selectedItem && !isEdit && (
        <OrgDetailView
          data={selectedItem}
          organization={organizationDetail}
          getOrgDetail={getOrgDetail}
          setEdit={setEdit}
          onAddDomain={onAddDomain}
        />
      )}

      {isEdit && <Edit data={organizationDetail} onUpdate={onUpdate} orgTypes={orgTypes} />}
      {isCreate && <Create onSave={onSave} orgTypes={orgTypes} />}
    </div>
  );
};

export default CustomersPendingView;
