import React from 'react';
import PropTypes from 'prop-types';
import { Table, Loader } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actionCreators';

import filterDisplayNames from '../../../filterDisplayNames';
import colors from '../../../styles/colors';
import Visualization from '../../presentational/Visualization/Visualization';
import BEDropdownSingle from '../../presentational/BEDropdownSingle/BEDropdownSingle';

function VisualContainer({
  fetchingStats,
  group_by,
  r_queryFormulary,
  r_setGroupBy,
  r_setFilterOption,
  r_toggleView,
  stats,
  history,
  match
}) {
  const isTablet = useMediaQuery({ query: '(max-width: 1224px)' });
  const containerWidth = `calc(100vw - 3vw - 3vw${
    isTablet ? '' : ' - 288px - 32px'
  })`;

  const searchByOptions = stats.map((f, index) => {
    const displayText =
      filterDisplayNames[f.label] ||
      f.label
        .toLowerCase()
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

    return {
      key: f.label,
      text: displayText,
      value: f.label
    };
  });

  let displayData = [];

  if (!fetchingStats) {
    const filter = stats.find(x => x.label === group_by);
    if (filter) {
      let visualizationData = filter.statistic;
      // Remove nulls from array
      visualizationData = visualizationData.filter(x => x[group_by] != null);

      let total = 0;

      visualizationData.forEach(x => {
        total += x.total;
      });

      if (total === 0) {
        total = 1;
      }

      displayData = visualizationData.map(x => {
        const segmentName = x[group_by][group_by];
        const optionId = x[group_by].id;
        return {
          optionId,
          type: segmentName,
          percentage: (x.total / total) * 100,
          angle: x.total,
          label: segmentName
        };
      });
      displayData.sort((a, b) => {
        if (a.angle < b.angle) {
          return 1;
        }
        if (b.angle < a.angle) {
          return -1;
        }
        return 0;
      });
    }
  }

  // data = [
  //   {
  //     type: 'RAD',
  //     number: 3
  //   },
  //   {
  //     type: 'NHAZ',
  //     number: 5
  //   }
  // ];

  const rows = displayData.map((x, index) => {
    const cells = [
      <Table.Cell
        key={1}
        style={{
          ...styles.tableCell,
          fontFamily: 'Nunito-Bold',
          color: colors.black
        }}
      >
        {x.type}
      </Table.Cell>,
      <Table.Cell key={2} style={styles.tableCell}>
        {`${
          x.percentage >= 10 ? x.percentage.toFixed(0) : x.percentage.toFixed(1)
        }%`}
      </Table.Cell>,
      <Table.Cell key={3} style={styles.tableCell}>
        {x.angle}
      </Table.Cell>
    ];

    return (
      <Table.Row
        key={x.type}
        style={{
          ...styles.tableRow,
          backgroundColor: index % 2 === 0 ? colors.grayLight : colors.white,
          borderBottomLeftRadius:
            index === displayData.length - 1 ? '8px' : '0px',
          borderBottomRightRadius:
            index === displayData.length - 1 ? '8px' : '0px'
        }}
        onClick={() => {
          r_setFilterOption(group_by, x.optionId);
          r_queryFormulary(history);
          r_toggleView(history, match);
        }}
      >
        {cells}
      </Table.Row>
    );
  });

  const visualization = (fetchingStats && (
    <div style={{ ...styles.emptyResultsContainer, width: containerWidth }}>
      <Loader active inline="centered">
        Fetching records...
      </Loader>
    </div>
  )) ||
    (!displayData.length && (
      <div style={styles.emptyResultsContainer}>
        <h4>No Results</h4>
        <p>Try a different search or removing filters.</p>
      </div>
    )) || (
      <Visualization
        data={displayData}
        centerText={
          filterDisplayNames[group_by] ||
          group_by
            .split('_')
            .map(s => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ')
        }
      />
    );

  return (
    <>
      <div style={{ ...styles.dataContainer, width: containerWidth }}>
        <div style={styles.dropdownRow}>
          <BEDropdownSingle
            value={group_by}
            onSelect={value => {
              r_setGroupBy(value, history);
            }}
            options={searchByOptions}
          />
        </div>
        <div style={styles.graphRow}>{visualization}</div>
      </div>

      {!fetchingStats && displayData.length > 0 && (
        <>
          <div style={styles.sectionTitle}>
            <h2>Breakdown</h2>
          </div>

          <div style={styles.dataContainer}>
            <Table
              style={styles.table}
              columns={3}
              selectable
              striped
              size="small"
              compact
              singleLine
              basic="very"
              className="table"
            >
              <Table.Header style={styles.tableHeader}>
                <Table.Row style={styles.tableRow}>
                  <Table.HeaderCell
                    style={{ ...styles.tableCell, ...styles.headerCell }}
                    rowSpan="1"
                  >
                    TYPE
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{ ...styles.tableCell, ...styles.headerCell }}
                    rowSpan="1"
                  >
                    PERCENTAGE
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{ ...styles.tableCell, ...styles.headerCell }}
                    rowSpan="1"
                  >
                    # OF RECORDS
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body style={styles.tableBody}>{rows}</Table.Body>
            </Table>
          </div>
        </>
      )}
    </>
  );
}

VisualContainer.propTypes = {
  group_by: PropTypes.string.isRequired,
  r_queryFormulary: PropTypes.func.isRequired,
  r_setGroupBy: PropTypes.func.isRequired,
  r_setFilterOption: PropTypes.func.isRequired,
  r_toggleView: PropTypes.func.isRequired,
  fetchingStats: PropTypes.bool.isRequired,
  stats: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

const styles = {
  dataContainer: {
    backgroundColor: colors.white,
    boxShadow: '0 2px 56px rgba(0, 0, 0, 0.07)',
    borderRadius: '8px'
  },
  dropdownRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '24px',
    paddingRight: '24px'
  },
  graphRow: {},
  emptyResultsContainer: {
    height: '60vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  sectionTitle: {
    marginTop: '44px',
    marginBottom: '18px'
  },
  table: {
    display: 'flex',
    flexDirection: 'column'
  },
  tableHeader: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  tableBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  headerCell: {
    color: colors.bluePrimary,
    textTransform: 'uppercase',
    fontWeight: 'normal',
    fontSize: '11px'
  },
  tableRow: {
    flex: 1,
    display: 'flex',
    border: 'none',
    paddingLeft: '1vw',
    paddingRight: '1vw',
    cursor: 'pointer'
  },
  tableCell: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    backgroundColor: 'transparent',
    border: 'none',
    paddingTop: '15px',
    paddingBottom: '15px',
    fontFamily: 'Nunito-Light',
    fontSize: '14px'
  }
};
function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state, props) => {
  const { fetchingStats, stats } = state.queryResults;
  const { group_by } = state.query;

  return {
    ...props,
    fetchingStats,
    group_by,
    stats
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VisualContainer)
);
