/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Table, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import colors from '../../../styles/colors';

import './styles.scss';

const ControlCenterTable = ({ headers, data, onSort, orderBy, className, availablesOrders }) => {
  const tableID = (Math.random() + 1).toString(36).substring(7);
  const [orderName, orderValue] = (orderBy || '').split(',');
  const selectedHeader = Object.keys(availablesOrders)[
    Object.values(availablesOrders).findIndex(el => el === orderName)
  ];

  return (
    <Table basic="very" compact size="small" className={`table main-table ${className}`}>
      <Table.Header className="header">
        <Table.Row className="row">
          {headers.map(headerText => (
            <Table.HeaderCell
              key={`${headerText}-${tableID}`}
              className="cell header-cell"
              style={{ backgroundColor: colors.blueTwo, color: colors.white }}
              rowSpan="1"
              onClick={() => availablesOrders && availablesOrders[headerText] && onSort(availablesOrders[headerText])}
            >
              {selectedHeader === headerText &&
                (orderValue === 'asc' ? <Icon name="angle up" /> : <Icon name="angle down" />)}
              {headerText}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body className="body">
        {data.map((row, idx) => (
          <Table.Row
            key={`row-${tableID}-${idx}`}
            className="row link"
            onClick={event => {
              if (row.onSelect) row.onSelect(row, event);
            }}
          >
            {row.cells.map((value, i) => (
              <Table.Cell
                key={`cell-${tableID}-${idx}-${i}`}
                className="cell"
                style={{
                  backgroundColor: colors.blueDarkTwo,
                  color: colors.white
                }}
              >
                {value}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

ControlCenterTable.defaultProps = {
  onSort: () => {},
  orderBy: null
};

ControlCenterTable.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onSort: PropTypes.func,
  orderBy: PropTypes.string,
  className: PropTypes.string.isRequired,
  availablesOrders: PropTypes.object.isRequired
};

export default ControlCenterTable;
