import React from 'react';
import { Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const OrgForm = ({ data, setData, orgTypes }) => {
  return (
    <div>
      <Form inverted>
        <Form.Group widths="equal">
          <Form.Input
            label="Name"
            type="text"
            value={data.name}
            onChange={(_e, { value }) => setData({ ...data, name: value })}
          />
          <Form.Select
            fluid
            label="Type"
            options={orgTypes.map(type => ({
              key: `${type.account_type_id}-${type.name}`,
              text: `${type.name}(${type.abbreviation})`,
              value: type.account_type_id
            }))}
            placeholder="Type"
            value={data.account_type_id}
            onChange={(_e, { value }) => setData({ ...data, account_type_id: value })}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            label="Tax ID #"
            type="text"
            value={data.tax_id_num}
            onChange={(_e, { value }) => setData({ ...data, tax_id_num: value })}
          />
          <Form.Input
            label="Facility Name"
            type="text"
            value={data.facility_name}
            onChange={(_e, { value }) => setData({ ...data, facility_name: value })}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            label="Phone"
            type="text"
            value={data.phone_main}
            onChange={(_e, { value }) => setData({ ...data, phone_main: value })}
          />
          <Form.Input
            label="Trilogy Org #"
            type="text"
            value={data.trilogy_org_num}
            onChange={(_e, { value }) => setData({ ...data, trilogy_org_num: value })}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            label="Parent Org 1"
            type="text"
            value={data.parent_org_1}
            onChange={(_e, { value }) => setData({ ...data, parent_org_1: value })}
          />
          <Form.Input
            label="Parent Org 2"
            type="text"
            value={data.parent_org_2}
            onChange={(_e, { value }) => setData({ ...data, parent_org_2: value })}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input
            label="Address Line 1"
            type="text"
            value={data.address_line_1}
            onChange={(_e, { value }) => setData({ ...data, address_line_1: value })}
          />
          <Form.Input
            label="Address Line 2"
            type="text"
            value={data.address_line_2}
            onChange={(_e, { value }) => setData({ ...data, address_line_2: value })}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            label="City"
            type="text"
            value={data.city}
            onChange={(_e, { value }) => setData({ ...data, city: value })}
          />
          <Form.Input
            label="State"
            type="text"
            value={data.state}
            onChange={(_e, { value }) => setData({ ...data, state: value })}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            label="Zip Code"
            type="text"
            value={data.zip_code}
            onChange={(_e, { value }) => setData({ ...data, zip_code: value })}
          />
          <Form.Input
            label="Country"
            type="text"
            value={data.country}
            onChange={(_e, { value }) => setData({ ...data, country: value })}
          />
        </Form.Group>
      </Form>
    </div>
  );
};

OrgForm.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  orgTypes: PropTypes.array.isRequired
};

export default OrgForm;
