import { types } from '../actionCreators'; // Import the actions types constant we defined in our actions

export const errors = {
  hasError: false, // when true, a full-screen error message will display
  statusCode: null,
  message: null,
  action: null
};

export default (state = {}, action) => {
  switch (action.type) {
    case types.ERROR:
      if (state.hasError) {
        return state;
      }
      return {
        ...state,
        hasError: true,
        statusCode: action.data.statusCode,
        message: action.data.message,
        action: action.data.action
      };
    case types.CLEAR_ERRORS:
      return {
        ...state,
        ...errors
      };
    case types.SIGN_OUT:
      return { ...errors };
    default:
      return state;
  }
};
