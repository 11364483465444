import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import ButtonText from '../../components/presentational/ButtonText/ButtonText';
import SidebarLink from './SidebarLink';
import colors from '../../styles/colors';

const AccordionHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 8px 15px 8px 15px;
`;

const AccordionHeaderText = styled.p`
  color: ${colors.white};
  font-family: Nunito-Bold;
  font-size: 12px;
  line-height: 28px;
  text-transform: uppercase;
`;

const StyledIcon = styled(Icon)`
  color: ${colors.white};
`;

function SidebarAccordion({ mainText, location, items, pathnameSegment }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(prevIsExpanded => !prevIsExpanded);
  };

  useEffect(() => {
    if (location.pathname.includes(pathnameSegment)) {
      setIsExpanded(true);
    }
  }, [location, pathnameSegment]);

  const chevronDirection = isExpanded ? 'down' : 'up';

  return (
    <div className="SidebarAccordion">
      <ButtonText
        onClick={handleClick}
        customStyles={{
          width: '100%',
          textAlign: 'left'
        }}
      >
        <AccordionHeader>
          <AccordionHeaderText>{mainText}</AccordionHeaderText>
          <StyledIcon name={`chevron ${chevronDirection}`} size="small" />
        </AccordionHeader>
      </ButtonText>
      {isExpanded &&
        items.map(item => (
          <SidebarLink key={item.text} to={item.to}>
            -- {item.text}
          </SidebarLink>
        ))}
    </div>
  );
}

SidebarAccordion.propTypes = {
  mainText: PropTypes.string.isRequired,
  location: PropTypes.any.isRequired,
  items: PropTypes.any.isRequired,
  pathnameSegment: PropTypes.string.isRequired
};

export default withRouter(SidebarAccordion);
