import { types } from '../actionCreators'; // Import the actions types constant we defined in our actions

export const defaults = {
  fetchingDefaultFilters: false,
  defaultFilters: null // the default filters object with no filters selected
};

export default (state = {}, action) => {
  switch (action.type) {
    case types.FETCHING_DEFAULT_FILTERS:
      return {
        ...state,
        fetchingDefaultFilters: true
      };
    case types.FETCHED_DEFAULT_FILTERS:
      return {
        ...state,
        fetchingDefaultFilters: false,
        defaultFilters: action.data.defaultFilters
      };
    case types.SIGN_OUT:
      return { ...defaults };
    default:
      return state;
  }
};
