import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter, BrowserRouter as Router } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// Redux
import { connect } from 'react-redux';
import * as Actions from '../../redux/actionCreators';

import AuthTimeoutWrapper from '../../components/container/AuthTimeoutWrapper/AuthTimeoutWrapper';
import BEAppWrapper from '../../components/container/BEAppWrapper/BEAppWrapper';
import BENavBar, { styles as BENavBarStyles } from '../../components/presentational/BENavBar/BENavBar';
import ErrorRenderer from '../../components/container/ErrorRenderer/ErrorRenderer';
import FormularyNdcBounce from './Apps/Formulary/NdcBounce';
import CustomersView from './Apps/Customers';
import DashboardView from './Apps/Dashboard';
import CustomersPendingView from './Apps/CustomersPending';
import PlansView from './Apps/Plans';
import UsersView from './Apps/Users';
import SidebarLink from './SidebarLink';
import MasterDBExport from './Apps/Formulary/MasterDBExport';
import SidebarSectionHeader from './SidebarSectionHeader';
import SidebarAccordion from './SidebarAccordion';
import LoadingScreen from '../../components/presentational/LoadingScreen/LoadingScreen';

import useInterval from '../../Hooks/useInterval';

import colors from '../../styles/colors';

export const controlCenterPath = '/control-center';

const mainNavSidebarItems = [
  { to: `${controlCenterPath}/analytics`, text: 'analytics' },
  {
    text: 'customers',
    path: '/customers',
    items: [
      { text: 'Customers Production', to: `${controlCenterPath}/customers/production` },
      { text: 'Customers Pending', to: `${controlCenterPath}/customers/pending` }
    ]
  },
  { to: `${controlCenterPath}/dashboard`, text: 'dashboard' },
  { to: `${controlCenterPath}/prospects`, text: 'prospects' },
  { to: `${controlCenterPath}/report-builder`, text: 'report builder' },
  { to: `${controlCenterPath}/revenue`, text: 'revenue' },
  { to: `${controlCenterPath}/sales-team`, text: 'sales team' },
  { to: `${controlCenterPath}/user-management`, text: 'user management' },
  { to: `${controlCenterPath}/plans`, text: 'plans' }
];

function ControlCenter({ history, r_signOut }) {
  const [showLoading, setShowLoading] = useState(true);
  const [userName, setUserName] = useState('');

  const capitalize = name => (name ? `${name.charAt(0).toUpperCase()}${name.slice(1)}` : '');

  const setCurrentUsername = async () => {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
    const userInfo =
      user && user.attributes && user.attributes.given_name ? capitalize(user.attributes.given_name) : '';

    setUserName(userInfo);
  };

  const isContentLoaded = () => {
    const element = document.getElementById('SidebarSectionHeader');
    return !!element;
  };

  useInterval(
    () => {
      if (isContentLoaded()) {
        setShowLoading(false);
      }
    },
    showLoading ? 500 : null
  );

  useEffect(() => {
    setCurrentUsername();
  }, []);

  const handleSignout = async () => {
    try {
      await Auth.signOut();
      r_signOut(history);
    } catch (error) {
      console.error({ error });
    }
  };

  return (
    <>
      <ErrorRenderer>
        <AuthTimeoutWrapper>
          <BENavBar signOut={() => handleSignout()} />
          <BEAppWrapper style={{ backgroundColor: colors.blueFive }}>
            <Router>
              <div
                style={{
                  display: 'flex',
                  minHeight: `calc(100vh - ${BENavBarStyles.menu.height})`
                }}
              >
                <div
                  style={{
                    width: '250px',
                    background: colors.blueDarkTwo,
                    paddingTop: '20px'
                  }}
                >
                  <SidebarSectionHeader>Main Navigation</SidebarSectionHeader>
                  {mainNavSidebarItems.map(item => {
                    return item.to ? (
                      <SidebarLink key={item.to} to={item.to}>
                        <span style={{ textTransform: 'uppercase' }}>{item.text}</span>
                      </SidebarLink>
                    ) : (
                      <SidebarAccordion
                        key={item.path}
                        pathnameSegment={item.path}
                        mainText={item.text}
                        items={item.items}
                      />
                    );
                  })}
                  <SidebarSectionHeader>Apps</SidebarSectionHeader>
                  <SidebarAccordion
                    pathnameSegment="/formulary"
                    mainText="Formulary App"
                    items={[
                      {
                        to: `${controlCenterPath}/formulary/ndc-bounce`,
                        text: 'NDC Bounce'
                      },
                      {
                        to: `${controlCenterPath}/formulary/customer-import`,
                        text: 'Customer Import'
                      },
                      {
                        to: `${controlCenterPath}/formulary/master-db-import`,
                        text: 'Master DB Import'
                      },
                      {
                        to: `${controlCenterPath}/formulary/master-db-export`,
                        text: 'Master DB Export'
                      }
                    ]}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <Switch>
                    <Route path={`${controlCenterPath}/analytics`}>
                      <h3>analytics</h3>
                    </Route>
                    <Route path={`${controlCenterPath}/customers/production`}>
                      <CustomersView />
                    </Route>
                    <Route path={`${controlCenterPath}/customers/pending`}>
                      <CustomersPendingView />
                    </Route>
                    <Route path={`${controlCenterPath}/dashboard`}>
                      <DashboardView />
                    </Route>
                    <Route path={`${controlCenterPath}/prospects`}>
                      <h3>prospects</h3>
                    </Route>
                    <Route path={`${controlCenterPath}/report-builder`}>
                      <h3>report-builder</h3>
                    </Route>
                    <Route path={`${controlCenterPath}/revenue`}>
                      <h3>revenue</h3>
                    </Route>
                    <Route path={`${controlCenterPath}/sales-team`}>
                      <h3>sales team</h3>
                    </Route>
                    <Route path={`${controlCenterPath}/user-management`}>
                      <UsersView />
                    </Route>
                    <Route path={`${controlCenterPath}/plans`}>
                      <PlansView />
                    </Route>
                    <Route path={`${controlCenterPath}/formulary/ndc-bounce`}>
                      <FormularyNdcBounce />
                    </Route>
                    <Route path={`${controlCenterPath}/formulary/customer-import`}>
                      <h3>customer-import</h3>
                    </Route>
                    <Route path={`${controlCenterPath}/formulary/master-db-import`}>
                      <h3>master-db-import</h3>
                    </Route>
                    <Route path={`${controlCenterPath}/formulary/master-db-export`}>
                      <MasterDBExport />
                    </Route>
                  </Switch>
                </div>
              </div>
            </Router>
          </BEAppWrapper>
        </AuthTimeoutWrapper>
      </ErrorRenderer>
      {showLoading ? (
        <LoadingScreen random inline="centered">
          <h1>Welcome {userName}</h1>
          <span>Loading your experience…</span>
        </LoadingScreen>
      ) : null}
    </>
  );
}

ControlCenter.propTypes = {
  history: PropTypes.object.isRequired,
  r_signOut: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    ...props
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { r_signOut: Actions.r_signOut }
  )(ControlCenter)
);
