import { types } from '../actionCreators'; // Import the actions types constant we defined in our actions

export const queryResults = {
  lastQuery: '', // a string representation of the last query made. Used to prevent re-fetches of the same query
  fetchingRecords: false,
  fetchingStats: false,
  // Formulary records to be displayed in the table view
  records: {
    data: []
  },
  // Stats: displayed in visual view
  stats: []
};

export default (state = {}, action) => {
  switch (action.type) {
    case types.FETCHING_FORMULARY_RECORDS:
      return {
        ...state,
        fetchingRecords: true
      };
    case types.FETCHED_FORMULARY_RECORDS:
      return {
        ...state,
        records: action.data.result,
        fetchingRecords: false,
        lastQuery: action.data.lastQuery
      };
    case types.FETCHING_FORMULARY_STATS:
      return {
        ...state,
        fetchingStats: true
      };
    case types.FETCHED_FORMULARY_STATS:
      return {
        ...state,
        stats: action.data.stats,
        fetchingStats: false
      };
    case types.SIGN_OUT:
      return { ...queryResults };
    default:
      return state;
  }
};
