import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../styles/colors';
import sassStyles from './ButtonText.module.scss';

function ButtonText({ children, disabled, onClick, customStyles, className, isLoading, type }) {
  return (
    <button
      type={type}
      disabled={disabled}
      style={{
        ...styles.button,
        ...customStyles,
        ...(disabled && { color: colors.grayDark }),
        cursor: disabled ? 'auto' : 'pointer'
      }}
      className={`${sassStyles.ButtonText} ${className} ${isLoading ? 'button--loading' : ''}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

const styles = {
  button: {
    fontSize: '15px',
    fontFamily: 'Nunito-Medium',
    color: colors.bluePrimary,
    border: 0,
    padding: 0,
    backgroundColor: 'transparent'
  }
};
ButtonText.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  customStyles: PropTypes.object,
  isLoading: PropTypes.bool
};

ButtonText.defaultProps = {
  type: 'button',
  className: undefined,
  disabled: false,
  customStyles: {},
  isLoading: false
};

export default ButtonText;
