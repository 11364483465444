const toPrettyPercent = (numerator, denominator) => {
  try {
    return ((numerator / denominator) * 100).toFixed(1);
  } catch (e) {
    console.error('Error calculating percentage: ', e);
    return '--';
  }
};

export default { toPrettyPercent };
