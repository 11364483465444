import axios from 'axios';
import { Auth, Storage } from 'aws-amplify';
import filterDisplayNames from '../filterDisplayNames';
import { SetS3Config } from '../services';
import periodicTableElementsData from '../utils/periodicTableElementsData';
import { getAppSites } from '../API/sites';

const formularyHost = process.env.REACT_APP_FORMULARY_API_URL;
const birdseyeHost = process.env.REACT_APP_BIRDSEYE_API_URL;

export const types = {
  // defaults
  FETCHING_DEFAULT_FILTERS: 'FETCHING_DEFAULT_FILTERS',
  FETCHED_DEFAULT_FILTERS: 'FETCHED_DEFAULT_FILTERS',

  // query
  FETCHING_ORGS: 'FETCHING_ORGS',
  FETCHED_ORGS: 'FETCHED_ORGS',
  SET_ORG_ID: 'SET_ORG_ID',

  FETCHING_SITES: 'FETCHING_SITES',
  SET_SITES: 'SET_SITES',
  SET_SITE_ID: 'SET_SITE_ID',

  SET_VIEW: 'SET_VIEW',
  SET_SEARCH_BY: 'SET_SEARCH_BY',
  SET_SEARCH_QUERY: 'SET_SEARCH_QUERY',
  SET_SEARCH_QUERY_ALL: 'SET_SEARCH_QUERY_ALL',
  FETCHING_SEARCH_SUGGESTIONS: 'FETCHING_SEARCH_SUGGESTIONS',
  FETCHED_SEARCH_SUGGESTIONS: 'FETCHED_SEARCH_SUGGESTIONS',
  APPLY_FILTERS: 'APPLY_FILTERS',
  TOGGLE_FILTER_OPTION: 'TOGGLE_FILTER_OPTION',
  SET_FILTER_OPTION: 'SET_FILTER_OPTION',
  SET_GROUP_BY: 'SET_GROUP_BY',

  // queryResults
  FETCHING_FORMULARY_RECORDS: 'FETCHING_FORMULARY_RECORDS',
  FETCHED_FORMULARY_RECORDS: 'FETCHED_FORMULARY_RECORDS',
  FETCHING_FORMULARY_FILTERS: 'FETCHING_FORMULARY_FILTERS',
  FETCHED_FORMULARY_FILTERS: 'FETCHED_FORMULARY_FILTERS',
  FETCHING_FORMULARY_STATS: 'FETCHING_FORMULARY_STATS',
  FETCHED_FORMULARY_STATS: 'FETCHED_FORMULARY_STATS',

  // recordDetail
  FETCHING_RECORD_DATA: 'FETCHING_RECORD_DATA',
  FETCHED_RECORD_DATA: 'FETCHED_RECORD_DATA',
  SET_CURRENT_GROUP: 'SET_CURRENT_GROUP',
  GETTING_DROPDOWN_OPTIONS: 'GETTING_DROPDOWN_OPTIONS',
  SET_DROPDOWN_OPTIONS: 'SET_DROPDOWN_OPTIONS',
  SET_EDITING_ATTRIBUTE: 'SET_EDITING_ATTRIBUTE',
  CREATING_ATTRIBUTE: 'CREATING_ATTRIBUTE',
  CREATED_ATTRIBUTE: 'CREATED_ATTRIBUTE',
  SAVING_ATTRIBUTE: 'SAVING_ATTRIBUTE',
  SAVED_ATTRIBUTE: 'SAVED_ATTRIBUTE',
  ERROR_SAVING_ATTRIBUTE: 'ERROR_SAVING_ATTRIBUTE',
  CLEAR_ATTRIBUTE_ERROR: 'CLEAR_ATTRIBUTE_ERROR',

  // view
  TOGGLE_CONTROL_PANEL: 'TOGGLE_CONTROL_PANEL',
  TOGGLE_SCANNING_ACTIVE: 'TOGGLE_SCANNING_ACTIVE',
  TOGGLE_REQUEST_NDC_MODAL: 'TOGGLE_REQUEST_NDC_MODAL',
  TOGGLE_UPLOAD_CSV_MODAL: 'TOGGLE_UPLOAD_CSV_MODAL',
  TOGGLE_EXPORT_MODAL: 'TOGGLE_EXPORT_MODAL',

  // errors
  ERROR: 'ERROR',
  CLEAR_ERRORS: 'CLEAR_ERRORS',

  // ALL REDUCERS
  SIGN_OUT: 'SIGN_OUT',

  // Control Center > NDC Bounce
  FETCHING_NDC_BOUNCE_RESULTS: 'FETCHING_NDC_BOUNCE_RESULTS',
  FETCHED_NDC_BOUNCE_RESULTS: 'FETCHED_NDC_BOUNCE_RESULTS',
  UPLOADING_NDC_BOUNCE_CSV: 'UPLOADING_NDC_BOUNCE_CSV',
  UPLOADED_NDC_BOUNCE_CSV: 'UPLOADED_NDC_BOUNCE_CSV'
};

// Helper functions
const getErrorData = error => {
  if (error.response) {
    return {
      statusCode: error.response.status,
      message: error.response.data.message
    };
  }
  return {
    message: error.message
  };
};

// Default formulary filters
const defaultFilterNames = [
  'waste_class_id',
  'waste_stream_id',
  'disposal_grouping_id',
  'main_reason_id',
  'epa_waste_code_id',
  'route_id',
  'manufacturer_id',
  'dea_schedule_id',
  'dosage_form_id',
  'patent_expiration_date_id',
  'end_marketing_date_id',
  'preserverative_id',
  'glass_or_plastic_value_id',
  'uses_syringe_value_id',
  'storage_temperature_range_id',
  'pregnancy_category_id',
  'dermal_ld_50_id',
  'inhalation_ld_50_id',
  'intravenous_ld_50_id',
  'ld_50_id',
  'dot_class_id',
  'is_wte_non_compatible',
  'wte_main_reason_id',
  'is_wa_state_dangerous'
];

// Default columns to return when querying formulary
const defaultReturnAttributes = [
  'upc',
  'ndc_10_spaces',
  'product_name_id',
  'substance_name_id',
  'manufacturer_id',
  'waste_class_id',
  'main_reason_id'
];

export const r_fetchNdcBounceResults = prospectName => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.FETCHING_NDC_BOUNCE_RESULTS
      });

      const pollS3 = async tries => {
        console.log(`Polling! ${tries}`);

        const formattedProspectName = prospectName.replace(/-/g, ' ');
        const filename = `prospect-${formattedProspectName}-ndc-bounce-report.json`;

        const bucketName = process.env.REACT_APP_CLIENT_NDC_BOUNCE_BUCKET;
        SetS3Config(bucketName, 'public');

        try {
          const result = await Storage.get(filename, { download: true });
          const body = JSON.parse(result.Body.toString());
          dispatch({
            type: types.FETCHED_NDC_BOUNCE_RESULTS,
            data: body
          });
        } catch (e) {
          if (e.name === 'NoSuchKey') {
            console.log(`${e.name}: ${e.message}`);
          } else {
            console.error(`${e.name}: ${e.message}`);
          }

          if (tries < 15) {
            setTimeout(pollS3, 1000 + tries * 500, tries + 1);
          } else {
            dispatch({
              type: types.ERROR,
              data: {
                message: 'Sorry, something went wrong. Please try again!'
              }
            });
            dispatch({
              type: types.FETCHED_NDC_BOUNCE_RESULTS,
              data: {}
            });
          }
        }
      };

      setTimeout(pollS3, 2000, 0);
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

/*
  QUERY ACTIONS - For deriving query state from the URL and storing search,
  filter, & group by inputs
*/

export const r_fetchOrgs = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.FETCHING_ORGS
      });

      const session = await Auth.currentSession();
      const results = await axios.get(`${birdseyeHost}/${'v0'}/organizations`, {
        headers: {
          Authorization: session.idToken.jwtToken
        }
      });

      // Initially set filters = defaultFilters
      dispatch({
        type: types.FETCHED_ORGS,
        data: { orgs: results.data } // JSON.parse(JSON.stringify(defaultFilters)) } // appliedFilters must be an array of
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

export const r_fetchSites = () => {
  return async (dispatch, getState) => {
    const { orgId } = getState().query;

    try {
      if (orgId === 'all') {
        dispatch({ type: types.SET_SITES, data: { sites: [] } });
      } else {
        dispatch({ type: types.FETCHING_SITES });
        const appId = 1; // App ID for Formulary is 1
        const results = await getAppSites(orgId, appId, 1, 100);
        const sites = { 1: results.data.rows };
        if (results.data.pages.lastPage > 1) {
          const promises = [...Array(results.data.pages.lastPage - 1).keys()].map(async currentPage => {
            const pageResults = await getAppSites(orgId, appId, currentPage + 2, 100);
            sites[currentPage + 2] = pageResults.data.rows;
          });
          await Promise.all(promises);
        }

        dispatch({
          type: types.SET_SITES,
          data: { sites: Object.values(sites).flat() }
        });
      }
    } catch (e) {
      console.error(e);
      if (e.response.status === 404) {
        dispatch({
          type: types.ERROR,
          data: {
            statusCode: 404,
            message: `No Formulary records exists for Organization ${orgId}`
          }
        });
        dispatch({
          type: types.SET_ORG_ID,
          data: { orgId: 'all' }
        });
        dispatch({
          type: types.SET_SITE_ID,
          data: { siteId: 'all' }
        });
      } else {
        dispatch({
          type: types.ERROR,
          data: getErrorData(e)
        });
      }
    }
  };
};

export const r_setOrg = orgId => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.SET_ORG_ID,
        data: { orgId }
      });

      dispatch({
        type: types.SET_SITE_ID,
        data: { siteId: 'all' }
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

export const r_setSite = siteId => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.SET_SITE_ID,
        data: { siteId }
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

export const r_fetchDefaultFilters = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.FETCHING_DEFAULT_FILTERS
      });

      const { orgId } = getState().query;

      const session = await Auth.currentSession();
      const results = await axios.post(
        `${formularyHost}/${'v0'}/filters/organization/${orgId}`,
        {
          filter: {
            requested_filters: defaultFilterNames
          },
          return_attributes: ['upc']
        },
        {
          headers: {
            Authorization: session.idToken.jwtToken
          }
        }
      );

      // Reformat the returned filters array into an object that the
      // filters panel can use
      const defaultFilters = getFiltersObject(results.data.filters);

      // Initially set filters = defaultFilters
      dispatch({
        type: types.APPLY_FILTERS,
        data: { filters: defaultFilters } // JSON.parse(JSON.stringify(defaultFilters)) } // appliedFilters must be an array of
      });

      dispatch({
        type: types.FETCHED_DEFAULT_FILTERS,
        data: { defaultFilters: JSON.parse(JSON.stringify(defaultFilters)) }
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

export const r_setSearchQuery = searchString => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.SET_SEARCH_QUERY,
        data: { search_query: searchString }
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

export const r_setSearchQueryAll = searchPairs => {
  return async (dispatch, _getState) => {
    try {
      dispatch({
        type: types.SET_SEARCH_QUERY_ALL,
        data: { search_query_all: searchPairs }
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

export const r_setSearchBy = searchBy => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.SET_SEARCH_BY,
        data: { search_by: searchBy }
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

export const r_getSearchSuggestions = (searchBy, searchQuery) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.FETCHING_SEARCH_SUGGESTIONS
      });

      let searchSuggestions = [];

      if (searchQuery.length > 1) {
        const { orgId } = getState().query;
        const session = await Auth.currentSession();
        const results = await axios.get(
          `${formularyHost}/${'v0'}/getSearchSuggestions/organization/${orgId}?searchBy=${searchBy}&searchQuery=${searchQuery}`,
          {
            headers: {
              Authorization: session.idToken.jwtToken
            }
          }
        );

        searchSuggestions = results.data;
      }

      dispatch({
        type: types.FETCHED_SEARCH_SUGGESTIONS,
        data: { searchSuggestions }
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

export const r_setQueryStateFromUrl = (history, location, match) => {
  return async (dispatch, getState) => {
    try {
      const params = new URLSearchParams(location.search);
      const urlQuery = {
        orgId: params.get('orgId'),
        siteId: params.get('siteId'),
        search_by: params.get('search_by'),
        search_query: params.get('search_query'),
        filters: params.get('filters'),
        group_by: params.get('group_by')
      };

      if (urlQuery.orgId) {
        dispatch({
          type: types.SET_ORG_ID,
          data: {
            orgId:
              urlQuery.orgId === 'all'
                ? urlQuery.orgId
                : parseInt(urlQuery.orgId)
          }
        });
      }

      if (urlQuery.siteId) {
        // First verify the context:
        if (urlQuery.siteId !== 'all') {
          try {
            const session = await Auth.currentSession();
            await axios.get(
              `${birdseyeHost}/${'v0'}/organizations/${urlQuery.orgId}/sites/${
                urlQuery.siteId
              }`,
              {
                headers: {
                  Authorization: session.idToken.jwtToken
                }
              }
            );
          } catch (e) {
            console.error(e);
            if (e.response.status === 404) {
              dispatch({
                type: types.ERROR,
                data: {
                  statusCode: 404,
                  message: `Invalid search parameters: orgId=${urlQuery.orgId}&siteId=${urlQuery.siteId}`
                }
              });
            } else {
              dispatch({
                type: types.ERROR,
                data: getErrorData(e)
              });
            }
          }
        }

        let value;
        if (
          // if orgId is 'all', set siteId to 'all' as well
          (urlQuery.orgId && urlQuery.orgId === 'all') ||
          urlQuery.siteId === 'all'
        ) {
          value = 'all';
        } else {
          // otherwise set it to the specififed value
          value = parseInt(urlQuery.siteId);
        }
        dispatch({
          type: types.SET_SITE_ID,
          data: {
            siteId: value
          }
        });
      }

      const { view } = match.params;
      if (view === 'table' || view === 'visual') {
        dispatch({
          type: types.SET_VIEW,
          data: { view }
        });
      }

      if (urlQuery.search_by) {
        dispatch({
          type: types.SET_SEARCH_BY,
          data: { search_by: urlQuery.search_by }
        });
      }
      if (urlQuery.search_query) {
        dispatch({
          type: types.SET_SEARCH_QUERY,
          data: { search_query: urlQuery.search_query }
        });
      }
      if (urlQuery.filters) {
        const filtersFromUrl = filtersURLToObj(urlQuery.filters);
        const { query } = getState();
        const modifiedFilters = { ...query.filters };

        Object.entries(filtersFromUrl).forEach(([filterName, optionIds]) => {
          optionIds.forEach(id => {
            if (
              filterName in modifiedFilters &&
              id in modifiedFilters[filterName].filterOptions
            ) {
              modifiedFilters[filterName].filterOptions[id].selected = true;
            }
          });
        });

        dispatch({
          type: types.APPLY_FILTERS,
          data: { filters: modifiedFilters } // appliedFilters must be an array of
        });
      }
      if (urlQuery.group_by) {
        dispatch({
          type: types.SET_GROUP_BY,
          data: { group_by: urlQuery.group_by }
        });
      }
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

const filtersURLToObj = urlString => {
  const filtersObj = {};
  const filters = urlString.split(';');
  filters.forEach(f => {
    // where f is something like waste_class=1,4

    if (!f || !f.length) return; // Do nothing if f is undefined or an empty string

    const keyValues = f.split('=');
    const filterKey = keyValues[0];
    const filterOptions = keyValues[1];
    const filterOptionsArray = filterOptions.split(',');
    filtersObj[filterKey] = filterOptionsArray;
  });

  return filtersObj;
};

export const r_toggleFilterOption = (filterKey, optionId) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.TOGGLE_FILTER_OPTION,
        data: { filterKey, optionId }
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

export const r_setFilterOption = (filterKey, optionId) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.SET_FILTER_OPTION,
        data: { filterKey, optionId }
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

export const r_resetFilters = () => {
  return async (dispatch, getState) => {
    try {
      const { defaults } = getState();
      if (defaults.defaultFilters) {
        dispatch({
          type: types.APPLY_FILTERS,
          data: { filters: defaults.defaultFilters }
        });
      }
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

export const r_toggleView = (history, match) => {
  return async (dispatch, getState) => {
    try {
      const { query } = getState();
      const newView = query.view === 'table' ? 'visual' : 'table';
      dispatch({
        type: types.SET_VIEW,
        data: { view: newView }
      });

      history.replace({
        pathname: `/formulary-admin/${newView}/results`,
        search: history.location.search
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

export const r_setGroupBy = (groupBy, history) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.SET_GROUP_BY,
        data: { group_by: groupBy }
      });

      setTimeout(() => {
        // Update the URL:
        const sections = history.location.search.split('&');
        const groupBySection = sections[sections.length - 1];
        const newString = `group_by=${groupBy}`;

        if (groupBySection.includes('group_by')) {
          sections[sections.length - 1] = newString;
        } else {
          sections.push(newString);
        }

        history.push({
          pathname: history.location.pathname, // TODO: Make visual vs table dynamic!
          search: sections.join('&')
        });
      }, 1000);
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

const getSearchBody = (query, isSuggestion) => {
  let search;
  if (Object.keys(query.search_query_all).length > 0) {
    search = {
      name: 'all',
      value: '',
      valueAll: query.search_query_all
    };
  } else {
    search = {
      name: query.search_by || 'ndc',
      value: query.search_query || '',
      valueAll: {}
    };
  }

  const selectedFilters = {};
  Object.entries(query.filters).forEach(([filterName, filterObject]) => {
    const selectedOptionIds = [];
    Object.entries(filterObject.filterOptions).forEach(([id, option]) => {
      if (option.selected) {
        selectedOptionIds.push(id.match(/\d+/) ? parseInt(id) : id);
      }
    });
    if (selectedOptionIds.length) {
      selectedFilters[`${filterName}_id`] = selectedOptionIds;
    }
  });

  const filter = {
    selected_filters: selectedFilters,
    requested_filters: defaultFilterNames
  };

  let searchParam;
  if (search.value.length || Object.keys(search.valueAll).length > 0) {
    searchParam = { search };
  }

  return {
    ...searchParam,
    filter,
    exact: isSuggestion,
    return_attributes: defaultReturnAttributes
  };
};

/**
 * We need only implement the previous query to server but with
 * "exportCSV" property in the body
 */
export const exportCSVFormulary = async (query, fileName, sendByEmail = false) => {
  const { orgId } = query;
  const session = await Auth.currentSession();
  const body = getSearchBody(query);

  await axios.post(
    `${formularyHost}/v0/search/organization/${orgId}/page/1`,
    { ...body, exportCSV: true, fileName, sendByEmail },
    {
      headers: {
        Authorization: session.idToken.jwtToken
      }
    }
  );
};

/*
  QUERY RESULTS ACTIONS - Fetching data + interactions with the API
*/

// FOR NOW, r_queryFormulary sets the URL to match the query state AND performs
// the query
export const r_queryFormulary = (
  history,
  { page, isSuggestion } = { isSuggestion: false }
) => {
  return async (dispatch, getState) => {
    try {
      const { query, queryResults } = getState();
      const newUrl = queryToUrl(query);

      // If the query doesn't have a page parameter and it
      // isn't different than the current one, do nothing
      if (!page && newUrl === queryResults.lastQuery) {
        return;
      }

      // Scroll to the top of the page
      // window.scrollTo(0, 0);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

      // Otherwise fetch formulary records with new query
      dispatch({
        type: types.FETCHING_FORMULARY_RECORDS
      });

      history.push({
        pathname: `/formulary-admin/${query.view}/results`, // TODO: Make visual vs table dynamic!
        search: newUrl
      });

      const body = getSearchBody(query, isSuggestion);
      const { orgId } = getState().query;
      const session = await Auth.currentSession();

      const searchQuery = await axios.post(
        `${formularyHost}/${'v0'}/search/organization/${orgId}/page/${page ||
          1}`,
        { ...body },
        {
          headers: {
            Authorization: session.idToken.jwtToken
          }
        }
      );
      dispatch({
        type: types.FETCHED_FORMULARY_RECORDS,
        data: {
          lastQuery: newUrl,
          result: searchQuery.data.result
        }
      });

      // Now fetch the statistics
      dispatch({
        type: types.FETCHING_FORMULARY_STATS
      });

      const statsQuery = await axios.post(
        `${formularyHost}/${'v0'}/statistics/organization/${orgId}`,
        { ...body },
        {
          headers: {
            Authorization: session.idToken.jwtToken
          }
        }
      );

      dispatch({
        type: types.FETCHED_FORMULARY_STATS,
        data: {
          stats: statsQuery.data.statistics
        }
      });

      // If group_by hasn't been set use the first filter in the filters array
      if (!query.group_by) {
        const firstFilter =
          statsQuery.data.statistics.length &&
          statsQuery.data.statistics[0].label;
        dispatch({
          type: types.SET_GROUP_BY,
          data: { group_by: firstFilter }
        });
      }

      return;
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

/* The getFiltersObject() function takes the filters array returned from
   the API and converts it into an object that the filter panel can use.
   The resulting format looks like:
   {
     waste_class: {
       key: 'waste_class',
       displayName: 'Waste Class',
       filterOptions: {
         '0': {
           name: 'RCRA',
           selected: false
         },
         '1': {
           name: 'RAD, RCRA',
           selected: false
         },

         ... etc.
   }

*/

const getFiltersObject = filtersArray => {
  const filtersObj = {};
  filtersArray.forEach(filter => {
    const key = filter.label;

    const displayName =
      filterDisplayNames[filter.label] ||
      filter.label
        .toLowerCase()
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

    const filterOptions = {};
    filter.filter.forEach(option => {
      if (option[key] != null) {
        const optionId = option[key].id;
        const optionName = option[key][key];
        filterOptions[optionId] = {
          id: optionId,
          name: optionName,
          selected: false // TODO: Here you could optionally apply the specified filters from the URL
        };
      }
    });

    filtersObj[key] = {
      key,
      displayName,
      filterOptions
    };
  });

  const periodicElementsOptions = {};
  periodicTableElementsData
    .flat()
    .filter(element => element)
    .forEach(element => {
      periodicElementsOptions[element.symbol] = {
        id: element.symbol,
        name: element.name,
        selected: false
      };
    });

  filtersObj.molecular_formula_api = {
    key: 'molecular_formula_api',
    displayName: filterDisplayNames.molecular_formula_api,
    filterOptions: periodicElementsOptions
  };

  return filtersObj;
};

const queryToUrl = query => {
  // TODO: Run through a validator

  const { orgId, siteId, search_by, search_query, filters, group_by } = query;
  const querySections = [];

  if (orgId) {
    querySections.push(`orgId=${orgId}`);
  }
  if (siteId) {
    querySections.push(`siteId=${siteId}`);
  }
  if (search_by) {
    querySections.push(`search_by=${search_by}`);
  }
  if (search_query) {
    querySections.push(`search_query=${search_query}`);
  }
  if (
    filters && // filters object is not null && not an empty object
    !(Object.entries(filters).length === 0 && filters.constructor === Object)
  ) {
    const filtersString = filterObjToUrl(filters);
    if (filtersString) {
      querySections.push(filtersString);
    }
  }
  if (group_by) {
    querySections.push(`group_by=${group_by}`);
  }

  return querySections.length ? `?${querySections.join('&')}` : '';
};

const filterObjToUrl = filters => {
  const filterSections = [];

  Object.entries(filters).forEach(([filterName, filterObject]) => {
    const selectedOptionIds = [];
    Object.entries(filterObject.filterOptions).forEach(([id, option]) => {
      if (option.selected) {
        selectedOptionIds.push(id.match(/\d+/) ? parseInt(id) : id);
      }
    });
    if (selectedOptionIds.length) {
      filterSections.push(`${filterName}=${selectedOptionIds.join(',')}`);
    }
  });

  if (filterSections.length) {
    return `filters=${filterSections.join(';')}`;
  }
  return '';
};

/*
  RECORD DETAIL ACTIONS
*/

const recordDetailIncludeValues = [
  'alcohol_percentage',
  'color',
  'date_added',
  'dea_number',
  'dea_schedule',
  'disposal_acronym',
  'disposal_grouping',
  'dosage_form',
  'dot_class',
  'end_marketing_date',
  'epa_waste_code',
  'flash_point',
  'glass_or_plastic_value',
  'ld_50',
  'dermal_ld_50',
  'inhalation_ld_50',
  'intravenous_ld_50',
  'main_reason',
  'manufacturer',
  'patent_expiration_date',
  'permitted_temperature_excursions',
  'ph_range',
  'plastic_type',
  'pregnancy_category',
  'preserverative',
  'product_name',
  'regulatory_return_policy',
  'regulatory_return_policy_reason',
  'route',
  'scored_value',
  'shape',
  'shippable_value',
  'single_or_multi_dose_value',
  'size',
  'start_marketing_date',
  'storage_temperature_range',
  'strength',
  'substance_name',
  'uses_syringe_value',
  'wte_main_reason',
  'molecular_formula_api',
  'waste_class',
  'waste_stream'
];

export const r_fetchRecordDetails = (orgId, siteId, upc) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.FETCHING_RECORD_DATA
      });

      const session = await Auth.currentSession();
      const results = await axios.post(
        `${formularyHost}/${'v0'}/find/organization/${orgId}/site/${siteId}/upc/${upc}`,
        {
          include_values: recordDetailIncludeValues
        },
        {
          headers: {
            Authorization: session.idToken.jwtToken
          }
        }
      );

      dispatch({
        type: types.FETCHED_RECORD_DATA,
        data: { recordDetailData: results.data }
      });
    } catch (e) {
      console.error(e);
      if (e.response.status === 404) {
        dispatch({
          type: types.ERROR,
          data: {
            statusCode: 404,
            message: `No record exists with UPC ${upc} within Organization ${orgId} and Site ${siteId}`
          }
        });
      } else {
        dispatch({
          type: types.ERROR,
          data: getErrorData(e)
        });
      }
    }
  };
};

export const r_setCurrentGroup = groupName => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.SET_CURRENT_GROUP,
        data: { currentGroup: groupName }
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

export const r_setEditingAttribute = attributeKey => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.SET_DROPDOWN_OPTIONS,
        data: { dropdownOptions: [] }
      });
      dispatch({
        type: types.SET_EDITING_ATTRIBUTE,
        data: { editingAttribute: attributeKey }
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

export const r_fetchDropdownOptions = (
  attributeName,
  tableName,
  searchQuery
) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.GETTING_DROPDOWN_OPTIONS
      });

      const session = await Auth.currentSession();
      const results = await axios.get(
        `${formularyHost}/${'v0'}/attributes/table/${tableName}/${attributeName}/?searchQuery=${searchQuery}`,
        // `${formularyHost}/${'v0'}/attributes/table/${tableName}`,
        {
          headers: {
            Authorization: session.idToken.jwtToken
          }
        }
      );

      const dropdownOptions = results.data.slice(0, 6).map(x => ({
        key: x.id,
        value: x.id,
        text: x[attributeName]
      }));

      dispatch({
        type: types.SET_DROPDOWN_OPTIONS,
        data: { dropdownOptions }
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

export const r_createAttribute = (
  upc,
  tableName,
  columnName,
  attributeName
) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.CREATING_ATTRIBUTE
      });

      const session = await Auth.currentSession();
      const results = await axios.post(
        `${formularyHost}/${'v0'}/attributes/upc/${upc}/table/${tableName}`,
        {
          key: columnName,
          value: attributeName
        },
        {
          headers: {
            Authorization: session.idToken.jwtToken
          }
        }
      );

      dispatch({
        type: types.CREATED_ATTRIBUTE
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

export const r_saveRecordAttribute = (
  upc,
  attributeName,
  newValue,
  context,
  urlParams
) => {
  return async (dispatch, getState) => {
    try {
      const { savingAttribute } = getState().recordDetail;

      // If currently saving, do nothing
      if (savingAttribute) {
        return;
      }

      const { orgId, siteId } = urlParams;

      // PATCH the member with new value
      dispatch({
        type: types.SAVING_ATTRIBUTE,
        data: { savingAttribute: attributeName }
      });

      const session = await Auth.currentSession();

      let endpoint;
      switch (context) {
        case 'site':
          endpoint = `${formularyHost}/${'v0'}/disposal/organization/${orgId}/site/${siteId}/upc/${upc}`;
          break;
        case 'org':
          endpoint = `${formularyHost}/${'v0'}/disposal/organization/${orgId}/upc/${upc}`;
          break;
        case 'master':
          endpoint = `${formularyHost}/${'v0'}/attributes/upc/${upc}`;
          break;
        default:
      }

      await axios.patch(
        endpoint,
        {
          [attributeName]: newValue
        },
        {
          headers: {
            Authorization: session.idToken.jwtToken
          }
        }
      );

      dispatch({
        type: types.FETCHING_RECORD_DATA
      });

      const results = await axios.post(
        `${formularyHost}/${'v0'}/find/organization/${orgId}/site/${siteId}/upc/${upc}`,
        {
          include_values: recordDetailIncludeValues
        },
        {
          headers: {
            Authorization: session.idToken.jwtToken
          }
        }
      );

      dispatch({
        type: types.FETCHED_RECORD_DATA,
        data: { recordDetailData: results.data }
      });

      // If all is successful, consider the member field saved
      dispatch({
        type: types.SAVED_ATTRIBUTE
      });
      // dispatch({
      //   type: types.SET_REFRESH_MEMBERS,
      //   data: { refreshMembers: true }
      // });
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR_SAVING_ATTRIBUTE,
        data: {
          attribute: attributeName,
          errorValue: newValue,
          message: 'ERROR'
        }
      });
    }
  };
};

export const r_clearAttributeError = () => {
  return async (dispatch, getState) => {
    try {
      const { field } = getState().recordDetail.error;
      if (field) {
        dispatch({
          type: types.CLEAR_ATTRIBUTE_ERROR
        });
      }
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

/*
  VIEW ACTIONS
*/

export const r_scanningActive = active => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.TOGGLE_SCANNING_ACTIVE,
        data: { scanningActive: active }
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

export const r_showControls = show => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.TOGGLE_CONTROL_PANEL,
        data: { showControls: show }
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

export const r_showRequestNDCModal = show => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.TOGGLE_REQUEST_NDC_MODAL,
        data: { showRequestNDCModal: show }
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

export const r_showUploadCSVModal = show => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.TOGGLE_UPLOAD_CSV_MODAL,
        data: { showUploadCSVModal: show }
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

export const r_showExportModal = show => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.TOGGLE_EXPORT_MODAL,
        data: { showExportModal: show }
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

/*
  ERROR ACTIONS
*/

export const r_clearErrors = history => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.CLEAR_ERRORS
      });

      history.push('/');
    } catch (e) {
      console.error(e);
      dispatch({
        type: types.ERROR,
        data: getErrorData(e)
      });
    }
  };
};

// SIGN OUT
export const r_signOut = history => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.SIGN_OUT
      });
      history.push('/auth/signIn');

      // const host = process.env.REACT_APP_HOST;
      // if (host !== 'localhost:3000') {
      //   const domains = window.location.hostname.split('.');
      //   const subdomain = domains[0];
      //   const url = `https://${subdomain}.${host}/home`;
      //   // app is in production - go to the BE Admin App sign-in page
      //   window.location.href = url;
      // } else {
      //   // developing locally... go to the local sign-in flow
      //   // (which will never be accessible in production)
      //   alert('In, production, send user to Admin App Auth...');
      //   history.push('/auth/signIn');
      // }
    } catch (e) {
      console.log(e);
    }
  };
};
