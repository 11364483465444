import React, { useState, useEffect } from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import colors from '../../../../styles/colors';
import ButtonText from '../../../../components/presentational/ButtonText/ButtonText';
import { getUserOrgs } from '../../../../API/users';
import { getMemberPlans } from '../../../../API/plans';
import ConfirmationModal from '../../../../components/presentational/ConfirmationModal';

const UserDetailView = ({ user, setShowPlanModal, onDelete }) => {
  const [currentPlans, setCurrentPlans] = useState([]);
  const [memberOrganizations, setMemberOrganizations] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const loadUserData = async () => {
    const organization = await getUserOrgs(user.cognito_id);
    const plans = await getMemberPlans(user.cognito_id);
    setMemberOrganizations(organization.data);
    setCurrentPlans(plans.data);
  };

  useEffect(() => {
    loadUserData();
  }, []);

  return (
    <div>
      <div className="user-detail actions">
        <ButtonText
          className="submit-btn"
          customStyles={{
            backgroundColor: colors.red,
            color: colors.white,
            width: '180px'
          }}
          onClick={() => setShowDeleteConfirmation(true)}
        >
          Delete
        </ButtonText>
      </div>

      {memberOrganizations &&
        memberOrganizations.map(org => {
          const plan = currentPlans.find(p => p.organization_id === org.id);
          return (
            <div className="user-detail actions">
              <span>
                {org.name} Plan: {plan ? plan.plan.name : 'Full Access'}
              </span>
              <ButtonText
                className="submit-btn"
                customStyles={{
                  backgroundColor: colors.blueThree,
                  color: colors.white
                }}
                onClick={() =>
                  setShowPlanModal(
                    true,
                    plan || {
                      organization_id: org.id,
                      user_id: user.cognito_id
                    }
                  )
                }
              >
                Set plan
              </ButtonText>
            </div>
          );
        })}
      <div>
        <Table basic="very" className="table main-table" columns={3} compact singleLine size="small">
          <Table.Body className="body">
            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                ID
              </Table.HeaderCell>
              <Table.Cell
                className="cell"
                style={{
                  backgroundColor: colors.blueDarkTwo,
                  color: colors.white
                }}
              >
                {user.cognito_id}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                Email
              </Table.HeaderCell>
              <Table.Cell
                className="cell"
                style={{
                  backgroundColor: colors.blueDarkTwo,
                  color: colors.white
                }}
              >
                {user.email}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        {showDeleteConfirmation && (
          <ConfirmationModal onClose={() => setShowDeleteConfirmation(false)} onAccept={() => onDelete(user)}>
            Are you sure you want to remove this user?
          </ConfirmationModal>
        )}
      </div>
    </div>
  );
};

UserDetailView.propTypes = {
  user: PropTypes.object.isRequired,
  setShowPlanModal: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default UserDetailView;
