import React from 'react';
import PropTypes from 'prop-types';

import colors from '../../../styles/colors';
import sassStyles from './BEToggle.module.scss';

function BEToggle({ options, value, toggle, width }) {
  const toggledLeft = options[0].value === value;

  return (
    <div style={{ ...styles.container, width }}>
      <div
        className={sassStyles.Section}
        style={{
          ...styles.section,
          width: width / 2,
          color: toggledLeft ? colors.white : colors.grayDark
        }}
        onClick={() => {
          if (!toggledLeft) {
            toggle();
          }
        }}
        onKeyPress={() => {
          if (!toggledLeft) {
            toggle();
          }
        }}
        role="button"
        tabIndex="0"
      >
        {options[0].text}
      </div>
      <div
        className={sassStyles.Section}
        style={{
          ...styles.section,
          width: width / 2,
          color: toggledLeft ? colors.grayDark : colors.white
        }}
        onClick={() => {
          if (toggledLeft) {
            toggle();
          }
        }}
        onKeyPress={() => {
          if (toggledLeft) {
            toggle();
          }
        }}
        role="button"
        tabIndex="0"
      >
        {options[1].text}
      </div>

      <div
        className={sassStyles.ToggleSwitch}
        style={{
          ...styles.toggleSwitch,
          width: width / 2,
          left: `${toggledLeft ? 0 : width / 2}px`
        }}
      />
    </div>
  );
}

const styles = {
  container: {
    height: '34px',
    borderRadius: '17px',
    backgroundColor: colors.white,
    boxShadow: '0 7px 16px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0px 5px',
    position: 'relative'
  },
  section: {
    height: '34px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    color: colors.grayDark,
    cursor: 'pointer'
  },
  toggleSwitch: {
    height: '34px',
    borderRadius: '17px',
    backgroundColor: colors.bluePrimary,
    position: 'absolute'
  }
};

BEToggle.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string
    })
  ),
  value: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  width: PropTypes.number
};

BEToggle.defaultProps = {
  width: 200
};

export default BEToggle;
