import React from 'react';
import PropTypes from 'prop-types';

import ModalWrapper from '../ModalWrapper/ModalWrapper';
import ModalBody from '../ModalBody/ModalBody';
import ModalFooter from '../ModalFooter/ModalFooter';
import colors from '../../../styles/colors';
import ButtonText from '../ButtonText/ButtonText';

const ConfirmationModal = ({ onClose, children, onAccept }) => (
  <ModalWrapper
    className="dark-modal confirmation-modal"
    customStyles={{ modal: { backgroundColor: colors.blueFive } }}
  >
    <ModalBody confirmation>{children}</ModalBody>
    <ModalFooter confirmation>
      <div className="flex justify-content">
        <ButtonText className="submit-btn" onClick={onClose}>
          Close
        </ButtonText>
        <ButtonText
          className="submit-btn"
          onClick={() => {
            onAccept();
            onClose();
          }}
        >
          Accept
        </ButtonText>
      </div>
    </ModalFooter>
  </ModalWrapper>
);

ConfirmationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default ConfirmationModal;
