import React, { useState, useEffect } from 'react';

import Breadcrumb from './Breadcrumb';
import ListView from './ListView';
import UserDetailView from './UserDetail';
import UserPlanModal from './UserPlanModal';
import ProgessModal from '../../../../components/presentational/ProgessModal';
import BulkUserPlanModal from './BulkUserPlanModal';
import ConfirmationModal from '../../../../components/presentational/ConfirmationModal';

import { getUsers, removeUser } from '../../../../API/users';
import { getOrgsList } from '../../../../API/organizations';

import './User.scss';

const UsersView = () => {
  const [errors, setErrors] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [showMultiPlanModal, setShowMultiPlanModal] = useState(false);
  const [planModalData, setPlanModalData] = useState();
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [filters, setFilters] = useState({ filterName: 'email' });
  const [orgs, setOrgs] = useState({});
  const [showProcessModal, setShowProcessModal] = useState(false);
  const [currentProcess, setCurrentProcess] = useState(0);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const deleteUser = async ({ cognito_id, email }) => {
    try {
      await removeUser(cognito_id, 'all');
    } catch (_e) {
      setErrors([...errors, `Something went wrong removing ${email}, please try again!`]);
    }
  };

  const deleteUsers = async (usersToDelete, counter) => {
    if (!usersToDelete.length) {
      return;
    }

    await deleteUser(usersToDelete.shift());
    setCurrentProcess(counter + 1);
    await deleteUsers(usersToDelete, counter + 1);
  };

  const deleteSelectedUsers = async () => {
    setShowProcessModal(true);
    await deleteUsers([...selectedUsers], 0);
    onMainSearch(filters, 1);
  };

  const onMainSearch = async (searchFilters, page) => {
    setLoadingData(true);
    try {
      setUsers(await getUsers(searchFilters, page, 50));
    } catch (e) {
      setErrors(['Something went wrong please try again!']);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    const loadOrgs = async () => {
      setOrgs((await getOrgsList()).data);
    };

    onMainSearch({}, 1);
    loadOrgs();
  }, []);

  return (
    <div className={`users-container control-center-view-container ${loadingData ? 'loading' : ''}`}>
      <Breadcrumb setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
      {!selectedItem && (
        <ListView
          users={users && users.data}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          selectItem={setSelectedItem}
          setShowPlanModal={setShowMultiPlanModal}
          onMainSearch={onMainSearch}
          filters={filters}
          onFilter={setFilters}
          orgs={orgs}
          onDelete={() => setShowDeleteConfirmation(true)}
        />
      )}
      {!!selectedItem && !showPlanModal && (
        <UserDetailView
          user={selectedItem}
          onDelete={async user => {
            await deleteUser(user);
            onMainSearch(filters, 1);
            setSelectedItem(null);
          }}
          setShowPlanModal={(show, data) => {
            setShowPlanModal(show);
            setPlanModalData(data);
          }}
        />
      )}
      {!!showPlanModal && (
        <UserPlanModal
          onClose={() => setShowPlanModal(false)}
          currentPlan={planModalData}
          onUpdate={() => {
            setShowPlanModal(false);
          }}
        />
      )}

      {!!showMultiPlanModal && (
        <BulkUserPlanModal
          users={selectedUsers}
          orgs={orgs}
          setErrors={setErrors}
          errors={errors}
          onClose={() => setShowMultiPlanModal(false)}
          onUpdate={counter => {
            setShowMultiPlanModal(false);
            setShowProcessModal(true);
            setCurrentProcess(counter);
          }}
        />
      )}

      {(showProcessModal || errors.length) && (
        <ProgessModal
          errors={errors}
          total={selectedUsers.length}
          current={currentProcess}
          resourceName="users"
          onClose={() => {
            setShowProcessModal(false);
            setErrors([]);
            setSelectedUsers([]);
            setCurrentProcess(0);
          }}
        />
      )}

      {showDeleteConfirmation && (
        <ConfirmationModal onClose={() => setShowDeleteConfirmation(false)} onAccept={deleteSelectedUsers}>
          Are you sure you want to remove these users?
        </ConfirmationModal>
      )}
    </div>
  );
};

export default UsersView;
