import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ProgressRing from '../ProgressRing';

import useInterval from '../../../Hooks/useInterval';

import 'react-circular-progressbar/dist/styles.css';
import './LoadingScreen.scss';

function LoadingScreen({ children, random, type, interval }) {
  const [progress, setProgress] = useState(1);

  useInterval(() => {
    if (random && progress <= 90) {
      setProgress(progress + 5);
    }
  }, interval);

  const customStyle = type === 'relative' ? 'relativeContainer' : '';

  return (
    <div className={`loadingScreenContainer ${customStyle}`}>
      <ProgressRing progress={progress} size={100} />
      {children}
    </div>
  );
}

LoadingScreen.propTypes = {
  children: PropTypes.array,
  type: PropTypes.string,
  random: PropTypes.bool,
  interval: PropTypes.number
};

LoadingScreen.defaultProps = {
  children: 'Loading...',
  type: 'fullScreen',
  random: false,
  interval: 200
};

export default LoadingScreen;
