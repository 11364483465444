import React, { useState } from 'react';
import { Form, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import RestrictionInput from './RestrictionInput';

export const AVAILABLE_PLAN_TYPES = [
  { key: 'all', text: 'All', value: 'all' },
  { key: 'member', text: 'Member', value: 'member' },
  { key: 'site', text: 'Site', value: 'site' },
  { key: 'organization', text: 'Organization', value: 'organization' }
];

const PlanForm = ({ data, setData, restrictions }) => {
  const [error, setError] = useState();

  const restrictionName = key => {
    return (restrictions.filter(r => r.key === key)[0] || {}).name;
  };

  const currentRestriction = data.restrictions_config || {};
  const setRestriction = (restriction, value) => {
    setError();
    const restrictions_config = { ...(data.restrictions_config || {}) };
    if (value === false) {
      delete restrictions_config[restriction];
    } else {
      const restrictionData = restrictions.find(r => r.key === restriction);
      const currentRestrictionsKeys = Object.keys(currentRestriction);

      if (
        restrictionData.conflict_on &&
        restrictionData.conflict_on.split(',').some(key => currentRestrictionsKeys.includes(key))
      ) {
        setError(
          `This rule has conflicts with other already added rules: ${restrictionName(restrictionData.conflict_on)}`
        );
      } else {
        restrictions_config[restriction] = value;
      }
    }

    setData({ ...data, restrictions_config });
  };
  const setType = value => {
    setError();
    const restrictions_config = { ...(data.restrictions_config || {}) };
    restrictions
      .filter(restriction => !restriction.disable_on || restriction.disable_on.split(',').includes(value))
      .forEach(restriction => delete restrictions_config[restriction.key]);

    setData({ ...data, restrictions_config, plan_type: value });
  };

  return (
    <div>
      <Form inverted>
        <Form.Group widths="equal">
          <Form.Input
            label="Name"
            type="text"
            value={data.name}
            onChange={(_e, { value }) => setData({ ...data, name: value })}
          />
          <Form.Select
            fluid
            label="Type"
            options={AVAILABLE_PLAN_TYPES}
            placeholder="Type"
            value={data.plan_type}
            disabled={!!data.id}
            onChange={(_e, { value }) => setType(value)}
          />
        </Form.Group>

        <div className="restrictions-container">
          <div className="header">
            <h1>Plan Restrictions</h1>
            <div className="hint">
              <Icon name="info circle" />
              Add restrictions clicking on second box items
            </div>
          </div>
          <div className="items-container">
            {Object.keys(currentRestriction).length === 0 && <h2>There are not added restrictions.</h2>}
            {restrictions.map(restriction => {
              if (restriction.key in currentRestriction) {
                return (
                  <div className="flex active" key={restriction.key}>
                    <Icon name="times circle" onClick={() => setRestriction(restriction.key, false)} />
                    <div className={`restriction ${restriction.type}`}>{restriction.name}</div>
                    {restriction.type !== 'bool' && (
                      <RestrictionInput
                        restriction={restriction}
                        value={currentRestriction[restriction.key]}
                        onChange={(_e, { value }) => setRestriction(restriction.key, value)}
                      />
                    )}
                  </div>
                );
              }
              return null;
            })}
          </div>
          <hr />
          {!!error && <div className="error">{error}</div>}
          <div className="header">
            <h1>Available Restrictions</h1>
            <div className="hint">
              <div>
                <Icon name="info circle" />
                List of restriction not added to current plan
              </div>
              <div>
                <Icon name="info circle" />
                Add restriction clicking on it
              </div>
            </div>
          </div>
          <div className="items-container">
            {restrictions.map(restriction => {
              if (
                !(restriction.key in currentRestriction) &&
                !(restriction.disable_on && restriction.disable_on.split(',').includes(data.plan_type))
              ) {
                return (
                  <button
                    className="restriction pointer"
                    key={restriction.key}
                    onClick={() => setRestriction(restriction.key, restriction.type !== 'bool' ? 1 : true)}
                  >
                    {restriction.name}
                  </button>
                );
              }
              return null;
            })}
          </div>
        </div>
      </Form>
    </div>
  );
};

PlanForm.propTypes = {
  setData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  restrictions: PropTypes.array.isRequired
};

export default PlanForm;
