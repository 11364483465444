import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import { Button } from 'semantic-ui-react';
import { Auth } from 'aws-amplify';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actionCreators';

import birdseyeNavIcon from '../../../Assets/Images/birds-eye-nav-logo.png';

const minutesUntilModal = 720;
const secondsBeforeSignout = 60;
let timer;

class AuthTimeoutWrapper extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.onIdle = this.onIdle.bind(this);

    this.state = {
      showTimeout: false,
      remainingSeconds: secondsBeforeSignout,
      userFirstName: ''
    };

    this.removeTimeoutModal = this.removeTimeoutModal.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  async componentDidMount() {
    const user = await Auth.currentUserInfo();
    this.setState({
      userFirstName: user.attributes ? ` ${user.attributes.given_name}` : ''
    });
  }

  componentWillUnmount() {
    if (timer) {
      clearInterval(timer);
    }
  }

  openTimeoutModal() {
    if (this.state.showTimeout) {
      return; // If the timeout is already showing, do nothing
    }

    this.setState({ showTimeout: true });

    timer = setInterval(() => {
      if (this.state.remainingSeconds <= 0) {
        this.signOut();
      } else {
        this.setState({ remainingSeconds: this.state.remainingSeconds - 1 });
      }
    }, 1000);
  }

  removeTimeoutModal() {
    clearInterval(timer); // Clear the timer
    // Hide the timeout modal
    this.setState({
      showTimeout: false,
      remainingSeconds: secondsBeforeSignout
    });
  }

  async signOut() {
    try {
      this.removeTimeoutModal();
      await Auth.signOut();
      this.props.r_signOut(this.props.history); // clear the store

      // this.props.history.push({
      //   pathname: `/auth/signIn`
      // });
    } catch (e) {
      console.error('Attempted to sign out, but caught error: ', e);
    }
  }

  render() {
    return (
      <>
        {this.state.showTimeout && (
          <div style={styles.overlay}>
            <div style={styles.modal}>
              <img
                style={{ width: '85px' }}
                src={birdseyeNavIcon}
                alt="birdseye logo"
              />
              <div style={styles.textContainer}>
                <h3>{`Hi${this.state.userFirstName}, are you still working?`}</h3>
                <h6>
                  You will automatically log out in{' '}
                  <b>{this.state.remainingSeconds} seconds</b>
                </h6>
              </div>
              <div style={styles.buttonContainer}>
                <Button
                  primary
                  onClick={this.signOut}
                  style={{ margin: '0px 8px' }}
                >
                  Log Out
                </Button>
                <Button
                  secondary
                  onClick={this.removeTimeoutModal}
                  style={{ margin: '0px 8px' }}
                >
                  Still working
                </Button>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            ...(this.state.showTimeout && {
              filter: 'blur(2px)'
            })
          }}
        >
          <IdleTimer
            ref={ref => {
              this.idleTimer = ref;
            }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={3000}
            timeout={1000 * 60 * minutesUntilModal}
          />
          {this.props.children}
        </div>
      </>
    );
  }

  onIdle(e) {
    this.openTimeoutModal();
  }
}

const styles = {
  overlay: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    zIndex: 201,
    backgroundColor: 'rgba(52, 54, 66, 0.85)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modal: {
    height: '375px',
    width: '600px',
    padding: '50px 100px 60px 100px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 2px 56px rgba(0, 0, 0, 0.07)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '25px 0px'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
};

AuthTimeoutWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
  r_signOut: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state, props) => {
  return {
    ...props
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AuthTimeoutWrapper)
);
