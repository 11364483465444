import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Rollbar from 'rollbar';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actionCreators';

import ErrorScreen from '../../presentational/ErrorScreen/ErrorScreen';

class ErrorRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      codeError: false
    };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ codeError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  goToHomepage() {
    this.props.r_clearErrors(this.props.history);
  }

  async signOut() {
    await Auth.signOut();
    this.props.r_signOut(this.props.history); // clear the store

    // this.props.history.push({
    //   pathname: `/auth/signIn`
    // });
  }

  getErrorMessage() {
    switch (this.props.statusCode) {
      case 400:
        return 'Sorry, something went wrong. ';
      case 401:
        return 'Sorry, you are not authorized to view this page. ';
      case 403:
        return 'Sorry, something went wrong. ';
      case 404:
        return `${this.props.message}`;
      case 500:
        return 'Sorry, the request to the server failed. ';
      case 502:
        return `Sorry, we're experiencing an unusually high volume of activity. Please try again. `;
      case 504:
        return `Sorry, the request timed out. Please try again. `;
      default:
        return `You're experiencing the following error: ${this.props.message}`;
    }
  }

  render() {
    if (this.state.codeError) {
      return (
        <ErrorScreen
          title="Oops..."
          message="An error occured. Check the logs for details, and try re-launching the app."
          homepageHandler={() => this.goToHomepage()}
        />
      );
    }
    return this.props.hasError ? (
      <ErrorScreen
        title={this.props.statusCode.toString()}
        message={this.getErrorMessage()}
        homepageHandler={() =>
          this.props.statusCode === 401 ? this.signOut() : this.goToHomepage()
        }
      />
    ) : (
      <> {this.props.children} </>
    );
  }
}

ErrorRenderer.propTypes = {
  children: PropTypes.node.isRequired,
  hasError: PropTypes.bool.isRequired,
  statusCode: PropTypes.number,
  message: PropTypes.string,
  r_clearErrors: PropTypes.func.isRequired,
  r_signOut: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

ErrorRenderer.defaultProps = {
  statusCode: 'There was an error...',
  message: 'Try reloading the app.'
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

function reportErrorToRollbar(message, state) {
  new Rollbar({
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: process.env.REACT_APP_ROLLBAR_ENV
  }).error(message, state);
}

const mapStateToProps = (state, props) => {
  const { hasError, statusCode, message } = state.errors;

  if (hasError) {
    reportErrorToRollbar(message, state);
  }

  return {
    ...props,
    hasError,
    statusCode,
    message
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ErrorRenderer)
);
