import React, { useState, useRef, useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import ButtonCircular from '../ButtonCircular/ButtonCircular';
import colors from '../../../styles/colors';
import sassStyles from './BEDropdownIcon.module.scss';

/**
 * Hook that detects clicks outside of the passed ref
 */
function useOutsideClickDetector(ref, didClickOutside) {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      didClickOutside(event.target);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

/**
 * BEDropdownIcon Component
 */

function BEDropdownIcon({
  options,
  onSelect,
  value,
  width,
  icon,
  backgroundColor
}) {
  // options - an object of form id: {}
  // Declare a new state variable, which we'll call "count"
  const [isOpen, toggleOpen] = useState(false);

  const dropdownMenuRef = useRef(null);
  const buttonRef = useRef(null);

  // Called whenever user clicks outside the dropdown menu
  const onClickOutside = clickTarget => {
    const didClickDropdownButton = buttonRef.current.contains(clickTarget);
    if (!didClickDropdownButton) {
      // prevents a double toggle (the dropdown button will call the
      // toggleOpen() function as well)
      toggleOpen(false);
    }
  };

  useOutsideClickDetector(dropdownMenuRef, onClickOutside);

  const dropdownItems = options.map((option, index) => {
    // For now, INCLUDE the selected option in the dropdown list
    // const isSelected = option.value === value;
    // if (isSelected) {
    //   return null;
    // }
    return (
      <div
        style={styles.dropdownItem}
        className={sassStyles.DropdownItem}
        key={option.key}
        onClick={() => {
          onSelect(option.value);
          toggleOpen(false);
        }}
        onKeyPress={() => {
          onSelect(option.value);
          toggleOpen(false);
        }}
        role="button"
        tabIndex="0"
      >
        <p style={styles.dropdownItemText}>{option.text}</p>
      </div>
    );
  });

  return (
    <div style={{ ...styles.container, width: `${width}px` }}>
      <div
        ref={buttonRef}
        style={{
          ...styles.button,
          borderBottomLeftRadius: isOpen ? '0px' : '22px',
          borderBottomRightRadius: isOpen ? '0px' : '22px'
        }}
        className={sassStyles.Button}
        onClick={() => {
          toggleOpen(!isOpen);
        }}
        onKeyPress={() => toggleOpen(!isOpen)}
        role="button"
        tabIndex="0"
      >
        <div style={styles.iconContainer}>
          <ButtonCircular diameter={30}>
            <img alt="icon" style={{ width: '15px' }} src={icon} />
          </ButtonCircular>
        </div>

        <p style={styles.title}>
          {(options.length &&
            options.find(option => option.value === value) &&
            options.find(option => option.value === value).text) ||
            ''}
        </p>
        <Icon
          style={{
            ...styles.icon,
            ...(isOpen && { transform: 'rotate(180deg)' })
          }}
          name="chevron down"
          size="small"
        />
      </div>
      {isOpen && (
        <div
          ref={dropdownMenuRef}
          style={{
            ...styles.dropdown,
            ...(backgroundColor && {
              backgroundColor
            })
          }}
          className={sassStyles.DropDown}
        >
          {dropdownItems}
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    position: 'relative'
  },
  button: {
    backgroundColor: colors.blueDark,
    boxShadow: '0 7px 16px rgba(0, 0, 0, 0.05)',
    height: '44px',
    borderRadius: '22px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '20px',
    color: colors.grayDark,
    cursor: 'pointer'
  },
  title: {
    fontFamily: 'Nunito-Bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '10px',
    color: colors.white,
    flex: 1
  },
  icon: {
    color: colors.white
  },
  dropdown: {
    position: 'absolute',
    zIndex: 3,
    backgroundColor: colors.blueDark,
    // boxShadow: '0 7px 73px rgba(0, 0, 0, 0.12)',
    width: '100%',
    maxHeight: '250px',
    overflow: 'auto',
    borderBottomLeftRadius: '22px',
    borderBottomRightRadius: '22px'
  },
  iconContainer: {
    width: '42px',
    padding: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px 8px 0px',
    cursor: 'pointer'
  },

  dropdownItemText: {
    color: colors.white,
    marginLeft: '42px'
  }
};

BEDropdownIcon.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.number,
  backgroundColor: PropTypes.string,
  icon: PropTypes.string.isRequired
};

BEDropdownIcon.defaultProps = {
  width: 200,
  backgroundColor: ''
};

export default BEDropdownIcon;
