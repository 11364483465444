import React, { useState, useEffect } from 'react';

import Breadcrumb from './Breadcrumb';
import ListView from './ListView';
import LocationDetailView from './LocationDetailView';
import OrgDetailView from './OrgDetailView';

import { getOrgs, getAllSites, getOrg, getOrgSites } from '../../../../API/organizations';
import { ORG_ORDER_FIELDS, LOCATION_ORDER_FIELDS } from './mappers';

import './Customer.scss';
import '../../ControlCenter.scss';

const CustomersView = () => {
  const [error, setError] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const [mainFilterValue, setMainFilterValue] = useState('');
  const [mainFilterName, setMainFilterName] = useState('all');
  const [orgFilterValue, setOrgFilterValue] = useState('');
  const [orgFilterName, setOrgFilterName] = useState('all');
  const [organizationDetail, setOrganizationDetail] = useState();
  const [orgLocations, setOrgLocations] = useState({});
  const [orgs, setOrgs] = useState();
  const [sites, setSites] = useState();
  const [orderBy, setOrderBy] = useState();

  const searchOrgs = async (dataFilter, page) => setOrgs((await getOrgs(dataFilter, page)).data);
  const searchSites = async (dataFilter, page) => setSites((await getAllSites(dataFilter, page)).data);
  const getOrgDetail = async orgId => setOrganizationDetail((await getOrg(orgId)).data);
  const searchOrgSites = async (orgId, dataFilter, page) => {
    const locations = {};
    locations[orgId] = (await getOrgSites(orgId, dataFilter, page)).data;
    setOrgLocations(locations);
  };

  const onOrgSitesSearch = async (orgId, filters, page) => {
    setLoadingData(true);

    try {
      const [orderName, orderValue] = (orderBy || '').split(',');
      const dataFilter = { ...filters, perPage: 10 };
      const locationOrder = Object.values(LOCATION_ORDER_FIELDS).includes(orderName)
        ? { orderBy: `${orderName} ${orderValue}` }
        : {};

      await searchOrgSites(orgId, { ...dataFilter, ...locationOrder }, page);
    } catch (e) {
      setError('Something went wrong, please try again!');
    }

    setLoadingData(false);
  };

  const onMainSearch = async (filters, page, type) => {
    setLoadingData(true);

    try {
      const [orderName, orderValue] = (orderBy || '').split(',');
      const dataFilter = { ...filters, perPage: 10 };

      const orgOrder = Object.values(ORG_ORDER_FIELDS).includes(orderName)
        ? { orderBy: `${orderName} ${orderValue}` }
        : {};

      const locationOrder = Object.values(LOCATION_ORDER_FIELDS).includes(orderName)
        ? { orderBy: `${orderName} ${orderValue}` }
        : {};

      if (type !== 'orgs') await searchSites({ ...dataFilter, ...locationOrder }, page);
      if (type !== 'sites') await searchOrgs({ ...dataFilter, ...orgOrder }, page);
    } catch (e) {
      setError('Something went wrong, please try again!');
    }

    setLoadingData(false);
  };

  const onSort = async key => {
    const [orderName, orderValue] = (orderBy || '').split(',');
    let type = 'asc';
    if (key === orderName) type = orderValue === 'asc' ? 'desc' : 'asc';

    await setOrderBy(`${key},${type}`);
    document.getElementById('force-search-customers').click();
  };

  useEffect(() => {
    onMainSearch({}, 1);
  }, []);

  return (
    <div className={`customer-container control-center-view-container ${loadingData ? 'loading' : ''}`}>
      <Breadcrumb setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
      {!!error && <h1 className="error">{error}</h1>}
      {!selectedItem && (
        <ListView
          selectItem={setSelectedItem}
          listResults={{ orgs, sites }}
          onSearch={onMainSearch}
          filterValue={mainFilterValue}
          setFilterValue={setMainFilterValue}
          filterName={mainFilterName}
          setFilterName={setMainFilterName}
          onSort={onSort}
          orderBy={orderBy}
        />
      )}
      {!!selectedItem && selectedItem.organization && <LocationDetailView data={selectedItem} setError={setError} />}
      {!!selectedItem && !selectedItem.organization && (
        <OrgDetailView
          data={selectedItem}
          organization={organizationDetail}
          onSearch={onOrgSitesSearch}
          selectItem={setSelectedItem}
          getOrgDetail={getOrgDetail}
          locations={orgLocations[selectedItem.id]}
          filterValue={orgFilterValue}
          setFilterValue={setOrgFilterValue}
          filterName={orgFilterName}
          setFilterName={setOrgFilterName}
          onSort={onSort}
          orderBy={orderBy}
          setError={setError}
        />
      )}
    </div>
  );
};

export default CustomersView;
