import React from 'react';
import PropTypes from 'prop-types';

import colors from '../../../styles/colors';
import sassStyles from './ButtonCircular.module.scss';

function ButtonCircular({ children, onClick, style, diameter }) {
  return (
    <div
      className={sassStyles.Button}
      style={{
        ...styles.button,
        ...style,
        width: `${diameter}px`,
        height: `${diameter}px`
      }}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex="0"
    >
      {children}
    </div>
  );
}

const styles = {
  button: {
    borderRadius: '22px',
    backgroundColor: colors.white,
    boxShadow: '0 7px 16px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  }
};

ButtonCircular.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object,
  diameter: PropTypes.number
};

ButtonCircular.defaultProps = {
  onClick: () => {},
  style: {},
  diameter: 44
};

export default ButtonCircular;
