import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RadialChart } from 'react-vis';
import ResizeDetector from 'react-resize-detector';
import { Label } from 'semantic-ui-react';

import '../../../../node_modules/react-vis/dist/style.css';

import colors from '../../../styles/colors';

// NOTE: Take a look at the README.md file within this component folder.
// It explains how this component is built :)

function Visualization({ data, centerText }) {
  const [hoveredValue, setHoveredValue] = useState(null);

  let total = 0;
  data.forEach(x => {
    total += x.angle;
  });
  if (total === 0) {
    total = 1;
  }

  // Only show a lable if the slice makes up more than 3% of the circle
  const cleanedData = data.map(x => {
    return {
      ...x,
      label: x.percentage < 3 ? '' : x.label
    };
  });

  // Iterate through each section and render a label for each
  let hoverLabel = null;
  let startingAngle = 0;
  const labels = cleanedData.map(x => {
    const angleRange = (x.angle / total) * 360;
    const rotationAngle = startingAngle + angleRange / 2;
    const textRotationAngle =
      rotationAngle <= 180 ? rotationAngle - 90 : rotationAngle - 270;
    startingAngle += angleRange;

    // For small slices (< 3%), render a label when user hovers over slice
    if (x.percentage < 3 && hoveredValue && hoveredValue.type === x.type) {
      hoverLabel = (
        <div
          style={{
            ...styles.hoverLabelContainer,
            transform: `rotate(${rotationAngle}deg)
            translateY(-294px)
            rotate(${-rotationAngle}deg)
            rotate(${textRotationAngle}deg)`
          }}
        >
          <Label
            style={{ width: '80px' }}
            pointing={rotationAngle <= 180 ? 'left' : 'right'}
          >
            <div style={{ ...styles.hoverLabel, maxWidth: '80px' }}>
              {x.type}
            </div>
          </Label>
        </div>
      );
    }

    // Render the label on top of the RadialChart
    return (
      <div
        key={x.type}
        style={{
          ...styles.label,
          transform: `rotate(${rotationAngle}deg)
          translateY(-193px)
          rotate(${-rotationAngle}deg)
          rotate(${textRotationAngle}deg)`,
          maxWidth: '80px'
        }}
      >
        {x.label}
      </div>
    );
  });

  return (
    <ResizeDetector
      handleWidth
      handleHeight
      render={({ width, height }) => {
        if (!width) {
          return <div />;
        }

        // Set the chart's width & radius based on the size of the window
        const padding = width * 0.1;
        const centerBorderWidth = width * 0.015;
        const sectionStrokeWidth = width * 0.003;

        const chartWidth = width > 520 ? 500 : width - padding * 2; //* 0.3; // 2 * Math.round( / 2);
        const chartRadius = chartWidth / 2;
        const chartInnerRadius = chartRadius / 2;

        return (
          <div
            style={{
              ...styles.container,
              height: chartWidth + 80,
              padding: `0px ${padding}px ${padding}px ${padding}px`
            }}
          >
            <RadialChart
              animation
              color={colors.blueLight}
              data={cleanedData}
              style={{
                stroke: colors.white,
                strokeWidth: sectionStrokeWidth
              }}
              radius={chartRadius}
              innerRadius={chartInnerRadius}
              width={chartWidth}
              height={chartWidth}
              showLabels={false}
              labelsRadiusMultiplier={0.9}
              labelsStyle={{
                fontFamily: 'Nunito-Medium',
                fontSize: '15px',
                fill: colors.white
              }}
              onValueMouseOver={event => {
                setHoveredValue(event);
              }}
              onValueMouseOut={event => {
                setHoveredValue(null);
              }}
            />

            {cleanedData.length && (
              <div
                style={{
                  ...styles.centerCircle,
                  width: chartRadius - centerBorderWidth,
                  height: chartRadius - centerBorderWidth,
                  top: chartRadius / 2 + centerBorderWidth / 2,
                  borderRadius: chartRadius / 2
                }}
              >
                <h4 style={styles.centerText}>{centerText}</h4>
                {hoverLabel}
                {labels}
              </div>
            )}
          </div>
        );
      }}
    />
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '80px',
    position: 'relative'
  },
  centerCircle: {
    backgroundColor: colors.bluePrimary,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  centerText: {
    color: colors.white
  },
  label: {
    position: 'absolute',
    fontFamily: 'Nunito-Medium',
    fontSize: '15px',
    color: colors.grayLight,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  hoverLabelContainer: {
    position: 'absolute'
  },
  hoverLabel: {
    fontFamily: 'Nunito-Medium',
    fontSize: '10px',
    color: colors.black,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
};

Visualization.propTypes = {
  data: PropTypes.array.isRequired,
  centerText: PropTypes.string
};

Visualization.defaultProps = {
  centerText: ''
};

export default Visualization;
