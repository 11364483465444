/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Input, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import ButtonCircular from '../../../../../components/presentational/ButtonCircular/ButtonCircular';
import BEDropdownSingle from '../../../../../components/presentational/BEDropdownSingle/BEDropdownSingle';
import colors from '../../../../../styles/colors';

const SearchBar = ({
  search,
  setName,
  setValue,
  name,
  value,
  dropdownOption
}) => (
  <div className="input-container fade">
    <button
      style={{ display: 'none' }}
      id="force-search-customers"
      onClick={() => search(name, value)}
    />
    <Input
      className="search"
      iconPosition="left"
      labelPosition="right"
      style={{ backgroundColor: colors.white }}
      onKeyPress={e => e.key === 'Enter' && search(name, value)}
      label={
        <BEDropdownSingle
          value={name}
          onSelect={newName => {
            setName(newName);
            search(newName, value);
          }}
          options={dropdownOption}
          width={180}
          btnStyle={{ backgroundColor: colors.blueTwo }}
          titleStyle={{ color: colors.white }}
          iconStyle={{ color: colors.white }}
        />
      }
      input={
        <input
          style={{ color: colors.birdseyeBlueDark }}
          className="search-input"
          onChange={event => setValue(event.target.value)}
          value={value}
        />
      }
      icon={
        <div className="search-icon">
          <ButtonCircular
            onClick={() => {
              setValue('');
              search(name, null);
            }}
            style={{ backgroundColor: colors.blueTwo }}
            diameter={30}
          >
            <Icon
              name="times"
              style={{
                color: colors.white,
                paddingLeft: '3px',
                fontSize: '18px'
              }}
            />
          </ButtonCircular>
        </div>
      }
    />
  </div>
);

SearchBar.propTypes = {
  search: PropTypes.func.isRequired,
  setName: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  dropdownOption: PropTypes.array.isRequired
};

export default SearchBar;
