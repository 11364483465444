import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actionCreators';

import colors from '../../../styles/colors';
import sassStyles from './ControlsWrapper.module.scss';
import closeIcon from '../../../Assets/Images/icon-close.png';

function ControlsWrapper({ title, children, r_showControls }) {
  const isTablet = useMediaQuery({ query: '(max-width: 1224px)' });

  if (isTablet) {
    return (
      <div style={styles.mobileWrapper}>
        <div
          className={sassStyles.Button}
          onClick={() => r_showControls(false)}
          onKeyPress={() => r_showControls(false)}
          role="button"
          tabIndex="0"
          style={styles.closeButton}
        >
          <img src={closeIcon} width="16px" alt="close" />
        </div>
        <div style={styles.header}>
          <h2>{title}</h2>
        </div>
        {children}
      </div>
    );
  }
  return (
    <>
      <div style={styles.header}>
        <h2>{title}</h2>
      </div>
      <div style={styles.desktopWrapper}>{children}</div>
    </>
  );
}

ControlsWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  r_showControls: PropTypes.func.isRequired
};

const styles = {
  header: {
    display: 'flex',
    height: '38px',
    marginBottom: '14px'
  },
  desktopWrapper: {
    backgroundColor: colors.white,
    padding: '22px',
    borderRadius: '8px',
    marginBottom: '200px',
    boxShadow: '0 2px 56px rgba(0, 0, 0, 0.07)'
  },
  mobileWrapper: {
    backgroundColor: colors.white,
    height: '100vh',
    padding: '22px',
    position: 'relative',
    boxShadow: '0 2px 56px rgba(0, 0, 0, 0.07)'
  },
  closeButton: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    width: '60px',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  }
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state, props) => {
  return {
    ...props
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ControlsWrapper)
);
