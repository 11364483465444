import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actionCreators';

import colors from '../../../styles/colors';
import sassStyles from './RecordDetailContainer.module.scss';
import PageHeader from '../../presentational/PageHeader/PageHeader';
import PageContent from '../../presentational/PageContent/PageContent';
import ControlsResultsWrapper from '../../presentational/ControlsResultsWrapper/ControlsResultsWrapper';
import GroupSelectionContainer from '../../presentational/GroupSelectionContainer/GroupSelectionContainer';
import RecordDetailTable from '../../presentational/RecordDetailTable/RecordDetailTable';
import { groupings, displayNames, types, tableNames } from './dataMaps';
import EditableTableRow from '../../presentational/EditableTableRow/EditableTableRow';

class RecordDetailContainer extends Component {
  componentDidMount() {
    const { orgId, siteId, upc } = this.props.match.params;
    this.props.r_fetchRecordDetails(orgId, siteId, upc);
    this.props.r_setCurrentGroup('Birds Eye View');
  }

  backToFormulary() {
    const { history } = this.props;
    history.goBack();
  }

  getContext() {
    const { orgId, siteId } = this.props.match.params;

    if (siteId !== 'all') {
      return 'site';
    }
    if (orgId !== 'all') {
      return 'org';
    }
    return 'master';
  }

  static isDropdownType(key) {
    return types[key] === 'dropdown' || types[key] === 'dropdown_date';
  }

  renderTable() {
    const nonEditableFields = [
      'upc',
      'ndc_11',
      'ndc_11_spaces',
      'ndc_10_spaces',
      'ndc_10'
    ];

    const disposalFields = [
      'return_policy_details',
      'proper_disposal_1',
      'proper_disposal_2',
      'if_empty',
      'proper_disposal_special_instructions'
    ];

    const context = this.getContext(); // master, org, or site

    // TODO: Add Validators will have to be defined elsewhere, tied to the key

    const { currentGroup, data, defaultFilters } = this.props;
    const { upc } = this.props.match.params;

    if (!data) {
      return null;
    }

    const getValues = key => {
      const value = data[key];
      if (value === null) {
        return {
          value: '',
          displayValue: ''
        };
      }

      if (
        RecordDetailContainer.isDropdownType(key) &&
        typeof value === 'object'
      ) {
        return {
          value: value.id,
          displayValue: value[key]
        };
      }

      if (types[key] === 'bool' && typeof value === 'number') {
        return {
          value,
          displayValue: value === 1 ? 'True' : 'False'
        };
      }

      return {
        value,
        displayValue: value
      };
    };

    const { orgId, siteId } = this.props.match.params;

    const rows = groupings[currentGroup].map((key, i) => {
      let editable;
      let additionalSaveParams;
      if (context === 'master') {
        editable =
          !nonEditableFields.includes(key) && !disposalFields.includes(key);
      } else {
        editable =
          !nonEditableFields.includes(key) && disposalFields.includes(key);
      }
      const values = getValues(key);
      return (
        <EditableTableRow
          key={`${key}_${context}`}
          attributeName={key}
          index={i}
          displayName={displayNames[key]}
          type={types[key]}
          dropdownOptions={this.props.dropdownOptions}
          showAddOption={RecordDetailContainer.isDropdownType(key)}
          onAddOption={async attributeName => {
            await this.props.r_createAttribute(
              upc,
              tableNames[key],
              key,
              attributeName
            );
            await this.props.r_fetchRecordDetails(orgId, siteId, upc);
            this.props.r_setEditingAttribute('');
          }}
          value={values.value}
          displayValue={values.displayValue}
          editable={editable}
          // validator={row.validator}
          onEdit={() => {
            this.props.r_setEditingAttribute(key);
            // Fetch options if it's a dropdown

            if (RecordDetailContainer.isDropdownType(key)) {
              // If it's a dropdown_date, initially get dropdown options by
              // searching for '', because this will return (none) as an option
              const queryText =
                types[key] === 'dropdown_date'
                  ? ''
                  : values.displayValue.slice(0, 6).slice(0, -2);

              this.props.r_fetchDropdownOptions(
                key,
                tableNames[key],
                queryText
              );
            }
          }}
          onTextChange={textInput => {
            // Just doing this for now...
            if (RecordDetailContainer.isDropdownType(key)) {
              // Instead you'll want to get suggestions
              this.props.r_fetchDropdownOptions(
                key,
                tableNames[key],
                textInput
              );
            }
          }}
          editing={this.props.editingAttribute === key}
          onCancel={() => {
            this.props.r_setEditingAttribute('');
          }}
          onSave={async newValue => {
            const passedKey = RecordDetailContainer.isDropdownType(key)
              ? `${key}_id`
              : key;

            await this.props.r_saveRecordAttribute(
              upc,
              passedKey,
              newValue,
              context,
              this.props.match.params
            );
            // Once the r_saveRecordAttribute action creator has
            // completed, check if there's an error
            if (this.props.error.message) {
              return false;
            }
            this.props.r_setEditingAttribute('');

            return true;
          }}
          saving={this.props.savingField === key}
          errorMessage={
            this.props.error.attribute === key ? this.props.error.message : ''
          }
          onClearError={() => this.props.r_clearAttributeError()}
          customStyles={{
            ...(i === 0 && {
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px'
            }),
            ...(i === groupings[currentGroup].length - 1 && {
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px'
            })
          }}
        />
      );
    });

    return rows;
  }

  // validator={row.validator}
  // onSave={async newValue => {
  //   await row.save(row.key, newValue);
  //   // Once the r_saveLocationField action creator has
  //   // completed, check if there's an error
  //   if (this.props.error.message) {
  //     return false;
  //   }
  //   return true;
  // }}
  // saving={this.props.savingMemberField === row.key}
  // errorMessage={
  //   this.props.error.locationField === row.key
  //     ? this.props.error.message
  //     : ''
  // }
  // onClearError={() => this.props.r_clearMemberDetailError()}

  // onEdit={row.onEdit}

  render() {
    const { fetchingRecordData, currentGroup, data } = this.props;
    const groupingKeys = Object.keys(groupings);
    let currentNdc = '';
    if (data) {
      currentNdc = `(${data.ndc_10_spaces})`;
    }

    return (
      <>
        <PageHeader
          headerContent={
            <div style={styles.headerContainer}>
              <div
                style={styles.backRow}
                className={sassStyles.BackButton}
                onClick={() => this.backToFormulary()}
                onKeyPress={() => this.backToFormulary()}
                role="button"
                tabIndex="0"
              >
                <Icon
                  name="arrow left"
                  style={{
                    color: colors.white,
                    paddingRight: '10px',
                    paddingBottom: '18px'
                  }}
                />
                <h6 style={styles.backText}>Back to formulary</h6>
              </div>
              <div style={styles.headerRow}>
                <h1 style={styles.headerText}>Formulary</h1>
                <h6 style={styles.ndcText}>{currentNdc}</h6>
              </div>
            </div>
          }
        />
        <PageContent>
          <ControlsResultsWrapper
            filterComponent={
              <GroupSelectionContainer
                isAdmin={!!this.props.isSuperAdmin}
                groupings={groupingKeys}
                currentGroup={currentGroup}
              />
            }
            resultsComponent={
              <>
                <div style={styles.filterHeader}>
                  <h2>{this.props.currentGroup}</h2>
                </div>
                <div style={styles.tableContainer}>{this.renderTable()}</div>
              </>
            }
          />
        </PageContent>
      </>
    );
  }
}

const styles = {
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '24px 0px'
  },
  filterHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '38px',
    marginBottom: '14px'
  },
  backRow: {
    // height: '40px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer'
  },
  headerRow: {
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  backText: {
    color: colors.white,
    fontFamily: 'Nunito-Light'
  },
  headerText: {
    color: colors.white,
    marginRight: '10px',
    lineHeight: '0px'
  },
  ndcText: {
    color: colors.white,
    fontFamily: 'Nunito-Light'
  },
  tableContainer: {
    boxShadow: '0 2px 56px rgba(0, 0, 0, 0.07)',
    borderRadius: '8px'
  }
};

RecordDetailContainer.propTypes = {
  fetchingRecordData: PropTypes.bool.isRequired,
  r_fetchRecordDetails: PropTypes.func.isRequired,
  r_setCurrentGroup: PropTypes.func.isRequired,
  currentGroup: PropTypes.string.isRequired,
  data: PropTypes.object,
  isSuperAdmin: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  defaultFilters: PropTypes.object.isRequired,
  editingAttribute: PropTypes.string.isRequired,
  r_fetchDropdownOptions: PropTypes.func.isRequired,
  r_createAttribute: PropTypes.func.isRequired,
  fetchingDropdownOptions: PropTypes.bool.isRequired,
  dropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  savingField: PropTypes.string.isRequired,
  error: PropTypes.object.isRequired,
  r_setEditingAttribute: PropTypes.func.isRequired,
  r_saveRecordAttribute: PropTypes.func.isRequired,
  r_clearAttributeError: PropTypes.func.isRequired
};

RecordDetailContainer.defaultProps = {
  data: null
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state, props) => {
  const {
    fetchingRecordData,
    currentGroup,
    data,
    editingAttribute,
    fetchingDropdownOptions,
    dropdownOptions,
    savingField,
    error
  } = state.recordDetail;
  const { defaultFilters } = state.defaults;

  return {
    ...props,
    fetchingRecordData,
    currentGroup,
    data,
    defaultFilters,
    editingAttribute,
    fetchingDropdownOptions,
    dropdownOptions,
    savingField,
    error
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RecordDetailContainer)
);
