import styled from 'styled-components';

import colors from '../../styles/colors';

const Text = styled.p`
  color: ${props => props.color || colors.white};
  display: block;
  font-family: ${props => props.fontFamily || 'Nunito-Bold'};
  font-size: ${props => props.fontSize || '12px'};
  line-height: ${props => props.lineHeight || '28px'};
  text-transform: ${props => props.textTransform || 'uppercase'};
`;

export default Text;
