import React from 'react';
import PropTypes from 'prop-types';

function ModalWrapper({ children, confirmation, customStyles, className }) {
  return (
    <div style={styles.overlay}>
      <div
        className={className}
        style={{
          ...styles.modal,
          ...(confirmation && { minHeight: '250px' }),
          ...(customStyles.modal && customStyles.modal)
        }}
      >
        {children}
      </div>
    </div>
  );
}

const styles = {
  overlay: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    zIndex: 1001,
    backgroundColor: 'rgba(52, 54, 66, 0.85)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modal: {
    minHeight: '600px',
    maxHeight: '94vh',
    overflow: 'auto',
    width: '700px',
    backgroundColor: 'white',
    boxShadow: '0 2px 56px rgba(0, 0, 0, 0.07)',
    display: 'flex',
    flexDirection: 'column'
  }
};

ModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  confirmation: PropTypes.bool,
  customStyles: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string
};

ModalWrapper.defaultProps = {
  confirmation: false,
  customStyles: {},
  className: ''
};

export default ModalWrapper;
