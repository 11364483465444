import React, { useState } from 'react';
import PropTypes from 'prop-types';

import colors from '../../../../styles/colors';
import ButtonText from '../../../../components/presentational/ButtonText/ButtonText';
import PlanForm from './PlanForm';

const Edit = ({ data, onUpdate, restrictions }) => {
  const [newData, setNewData] = useState({ ...data });
  return (
    <div className="dark-form plan-form">
      <PlanForm data={newData} setData={setNewData} restrictions={restrictions} />
      <ButtonText
        className="submit-btn"
        customStyles={{
          backgroundColor: colors.blueThree,
          color: colors.white
        }}
        onClick={() => onUpdate(data.id, newData)}
      >
        Save
      </ButtonText>
    </div>
  );
};

Edit.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  restrictions: PropTypes.array.isRequired
};

export default Edit;
