export const getDate = date => {
  const offset = new Date().getTimezoneOffset() * 60 * 1000;
  return new Date(Date.parse(date) + offset);
};

export const mapPlanToSelect = plans =>
  plans.map(item => ({
    key: item.id,
    text: item.name,
    value: item.id
  }));

export const dateToString = date => date && date.toJSON().split('T')[0];
