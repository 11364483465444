import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import colors from '../../../styles/colors';

function ErrorScreen({ title, message, homepageHandler }) {
  return (
    <div style={styles.background}>
      <h1 style={styles.title}>{title}</h1>
      <p style={styles.errorMessage}>{message}</p>
      {homepageHandler && (
        <>
          <p style={styles.standardMessage}>
            Click the link below to be sent back to the homepage!
          </p>

          <Button primary onClick={homepageHandler}>
            Go Home
          </Button>
        </>
      )}
    </div>
  );
}

const styles = {
  background: {
    backgroundColor: colors.white,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10vh 5vw'
  },
  title: {
    color: colors.birdseyeBlue,
    marginBottom: '50px',
    fontFamily: 'Nunito-ExtraBold',
    fontSize: '100px'
  },
  errorMessage: {
    color: colors.black,
    textAlign: 'center',
    fontFamily: 'Nunito-SemiBold',
    fontSize: '14px'
  },
  standardMessage: {
    color: colors.black,
    textAlign: 'center',
    marginBottom: '50px',

    fontSize: '14px'
  }
};

ErrorScreen.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  homepageHandler: PropTypes.func
};

ErrorScreen.defaultProps = {
  title: 'Error',
  message: `This URL doesn't exist.`
};

export default ErrorScreen;
