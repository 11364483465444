import React from 'react';
import PropTypes from 'prop-types';

import { RadialProgress } from 'react-radial-progress-indicator';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import ModalBody from '../ModalBody/ModalBody';
import ModalFooter from '../ModalFooter/ModalFooter';
import colors from '../../../styles/colors';
import ButtonText from '../ButtonText/ButtonText';

const getText = (_steps, proportion) => {
  return <div>{`${(Math.round(proportion * 1000) / 10).toFixed(1)}%`}</div>;
};

const ProgessModal = ({ onClose, total, current, errors, resourceName }) => {
  const complete = total === current;

  return (
    <ModalWrapper
      className={`dark-modal progress-modal ${complete ? 'complete' : ''}`}
      customStyles={{ modal: { backgroundColor: colors.blueFive } }}
    >
      <ModalBody confirmation={false}>
        {complete && errors.length && (
          <>
            <p>
              {total - errors.length} {resourceName} processed successfully
            </p>
            <p>{errors.length} errors:</p>
            <ol>
              {errors.map(error => (
                <li key={error}>{error}</li>
              ))}
            </ol>
          </>
        )}
        {complete && !errors.length && (
          <h1>
            {total} {resourceName} processed successfully.
          </h1>
        )}
        <RadialProgress
          backgroundTransparent
          duration={1000}
          fontRatio={4}
          height={225}
          ringBgColour={colors.grayLightTwo}
          ringFgColour={colors.blueThree}
          ringIntermediateColour={colors.blueThree}
          ringThickness={0.2}
          showIntermediateProgress
          startStep={current}
          step={complete ? current : current + 1}
          steps={total}
          text={getText}
          width={225}
        />
      </ModalBody>
      {complete && (
        <ModalFooter>
          <div className="flex">
            <ButtonText className="submit-btn" onClick={onClose}>
              Close
            </ButtonText>
          </div>
        </ModalFooter>
      )}
    </ModalWrapper>
  );
};

ProgessModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  errors: PropTypes.array.isRequired,
  resourceName: PropTypes.string
};

ProgessModal.defaultProps = {
  resourceName: 'resources'
};

export default ProgessModal;
