import { types } from '../../actionCreators';
import utils from '../utils';

export const ndcBounceDefaultState = {
  fetchingNdcBounceResults: false,
  duplicateNdcCount: null,
  duplicateNdcReportS3Location: null,
  matchingNdcCount: null,
  matchingNdcCountGroups: {
    wasteClassName: {},
    isWteNonCompatible: {},
    isWaStateDangerous: {},
    usesSyringeValue: {}
  },
  missingNdcCount: null,
  missingNdcReportS3Location: null,
  ndcCount: null,
  duplicateNdc: [],
  matchingNdc: [],
  missingNdc: [],
  addedNdc: [],
  addedNdcCount: null
};

export default (state = {}, action) => {
  switch (action.type) {
    case types.FETCHING_NDC_BOUNCE_RESULTS:
      return {
        ...state,
        ...ndcBounceDefaultState,
        fetchingNdcBounceResults: true
      };
    case types.FETCHED_NDC_BOUNCE_RESULTS:
      return {
        ...state,
        ...action.data,
        fetchingNdcBounceResults: false
      };
    case types.SIGN_OUT:
      return { ...ndcBounceDefaultState };
    default:
      return state;
  }
};

export const matchingNdcPercentSelector = state => {
  if (state.ndcCount == null) {
    return null;
  }

  try {
    const { matchingNdcCount, ndcCount, duplicateNdcCount } = state;
    return matchingNdcCount / (ndcCount - duplicateNdcCount);
  } catch (e) {
    console.error('Error calculating percent match', e);
    return null;
  }
};

const sortByName = entries => entries.sort((a, b) => (a[0] > b[0] ? 1 : -1));

export const matchingNdcWasteClassSelector = state => {
  const { matchingNdcCountGroups, matchingNdcCount } = state;

  const reducer = (acc, currVal) => {
    const [name, count] = currVal;
    const percent = `${utils.toPrettyPercent(count, matchingNdcCount)}%`;
    const rowData = { name, count, percent };
    acc.push(rowData);
    return acc;
  };

  return sortByName(Object.entries(matchingNdcCountGroups.wasteClassName)).reduce(reducer, []);
};
