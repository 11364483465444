import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CustomTable from '../../../../components/presentational/ControlCenterTable';
import Paginator from '../../../../components/presentational/Pagination';
import SearchBar from './SearchBar';
import {
  LOCATION_HEADERS,
  locationToTable,
  ORG_HEADERS,
  orgToTable,
  ORG_ORDER_FIELDS,
  LOCATION_ORDER_FIELDS
} from './mappers';
import ButtonText from '../../../../components/presentational/ButtonText/ButtonText';
import colors from '../../../../styles/colors';
import { refreshOrganizationAddresses } from '../../../../API/organizations';

const SEARCH_BY_OPTIONS = [
  { key: 'all', text: 'All', value: 'all' },
  { key: 'parent', text: 'Parent Name', value: 'parent' },
  { key: 'name', text: 'Name of site', value: 'name' },
  { key: 'address', text: 'Address', value: 'address' },
  { key: 'city', text: 'City', value: 'city' },
  { key: 'state', text: 'State', value: 'state' },
  { key: 'customer', text: 'Trilogy Customer #', value: 'customer' },
  { key: 'siteId', text: 'Trilogy Site ID', value: 'siteId' }
];

const ListView = ({
  selectItem,
  listResults,
  onSearch,
  filterValue,
  setFilterValue,
  filterName,
  setFilterName,
  onSort,
  orderBy
}) => {
  const [isRefreshingAddress, setIsRefreshingAddress] = useState(false);

  const search = (name, value) => {
    const params = value ? { search: { name, value } } : {};
    onSearch(params, 1);
  };

  const paginate = (type, page) => {
    const params = filterValue ? { search: { name: filterName, value: filterValue } } : {};
    onSearch(params, page, type);
  };

  const refreshAddresses = async () => {
    try {
      setIsRefreshingAddress(true);
      await refreshOrganizationAddresses();
      onSearch({}, 1, 'orgs');
    } catch (error) {
      console.log({ error });
    } finally {
      setIsRefreshingAddress(false);
    }
  };

  return (
    <>
      <SearchBar
        search={search}
        setName={setFilterName}
        setValue={setFilterValue}
        name={filterName}
        value={filterValue}
        dropdownOption={SEARCH_BY_OPTIONS}
      />
      {listResults.orgs && listResults.orgs.data && (
        <div className="list-container fade">
          <div style={styles.tableOptionsContainer}>
            <h2>Organizations: ({listResults.orgs.total})</h2>
            <ButtonText
              className="formulary-link"
              isLoading={isRefreshingAddress}
              customStyles={styles.refreshAddressesButton}
              onClick={refreshAddresses}
            >
              <span className="button__text">Refresh Addresses</span>
            </ButtonText>
          </div>
          <CustomTable
            headers={ORG_HEADERS}
            onSort={onSort}
            orderBy={orderBy}
            availablesOrders={ORG_ORDER_FIELDS}
            className="sortable"
            data={listResults.orgs.data.map(row => ({
              cells: orgToTable(row),
              onSelect: () => selectItem(row)
            }))}
          />
          <Paginator
            onNext={() => paginate('orgs', listResults.orgs.currentPage + 1)}
            onPrev={() => paginate('orgs', listResults.orgs.currentPage - 1)}
            page={listResults.orgs.currentPage}
            totalPages={listResults.orgs.totalPages}
          />
        </div>
      )}

      {listResults.sites && listResults.sites.data && (
        <div className="list-container fade">
          <h2>Locations: ({listResults.sites.total})</h2>
          <CustomTable
            headers={LOCATION_HEADERS}
            onSort={onSort}
            orderBy={orderBy}
            className="sortable locations"
            availablesOrders={LOCATION_ORDER_FIELDS}
            data={listResults.sites.data.map(row => ({
              cells: locationToTable(row),
              onSelect: () => selectItem(row)
            }))}
          />
          <Paginator
            onNext={() => paginate('sites', listResults.sites.currentPage + 1)}
            onPrev={() => paginate('sites', listResults.sites.currentPage - 1)}
            page={listResults.sites.currentPage}
            totalPages={listResults.sites.totalPages}
          />
        </div>
      )}
    </>
  );
};

const styles = {
  tableOptionsContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  refreshAddressesButton: {
    backgroundColor: colors.blueThree,
    color: colors.white,
    marginLeft: '1em',
    padding: '0 1em',
    width: 'auto'
  }
};

ListView.propTypes = {
  selectItem: PropTypes.func.isRequired,
  listResults: PropTypes.object.isRequired,
  onSearch: PropTypes.func.isRequired,
  filterValue: PropTypes.string.isRequired,
  setFilterValue: PropTypes.func.isRequired,
  filterName: PropTypes.string.isRequired,
  setFilterName: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired
};

export default ListView;
