import { types } from '../actionCreators'; // Import the actions types constant we defined in our actions

export const view = {
  showControls: false,
  scanningActive: false,
  showRequestNDCModal: false,
  showUploadCSVModal: false,
  showExportModal: false
};

export default (state = {}, action) => {
  switch (action.type) {
    case types.TOGGLE_SCANNING_ACTIVE:
      return {
        ...state,
        scanningActive: action.data.scanningActive
      };
    case types.TOGGLE_CONTROL_PANEL:
      return {
        ...state,
        showControls: action.data.showControls
      };
    case types.TOGGLE_REQUEST_NDC_MODAL:
      return {
        ...state,
        showRequestNDCModal: action.data.showRequestNDCModal
      };

    case types.TOGGLE_UPLOAD_CSV_MODAL:
      return {
        ...state,
        showUploadCSVModal: action.data.showUploadCSVModal
      };

    case types.TOGGLE_EXPORT_MODAL:
      return {
        ...state,
        showExportModal: action.data.showExportModal
      };

    case types.SIGN_OUT:
      return { ...view };
    default:
      return state;
  }
};
