import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';

import styles from './styles';
import utils from '../../../../../redux/reducers/utils';

const headerTexts = ['compatible', 'count', 'percentage'];

const headerCells = headerTexts.map(headerText => {
  const textAlign = headerText === 'field' ? 'left' : 'right';
  return (
    <Table.HeaderCell
      key={headerText}
      style={{ ...styles.tableCell, ...styles.headerCell }}
      rowSpan="1"
      textAlign={textAlign}
    >
      {headerText}
    </Table.HeaderCell>
  );
});

const AnalysisBooleanFields = ({ isWteNonCompatible, isWaStateDangerous, usesSyringeValue, matchingNdcCount }) => {
  return (
    <Table basic="very" className="table" columns={3} compact singleLine size="small" style={styles.table}>
      <Table.Header style={styles.tableHeader}>
        <Table.Row style={styles.tableRow}>{headerCells}</Table.Row>
      </Table.Header>

      {matchingNdcCount > 0 && (
        <Table.Body style={styles.tableBody}>
          <Table.Row style={styles.tableRow}>
            <Table.Cell style={styles.tableCell}>WTE Non Compatible</Table.Cell>
            <Table.Cell textAlign="right" style={styles.tableCell}>
              {isWteNonCompatible.X || 0}
            </Table.Cell>
            <Table.Cell textAlign="right" style={styles.tableCell}>
              {utils.toPrettyPercent(isWteNonCompatible.X || 0, matchingNdcCount)}%
            </Table.Cell>
          </Table.Row>
          <Table.Row style={styles.tableRow}>
            <Table.Cell style={styles.tableCell}>Syringe</Table.Cell>
            <Table.Cell textAlign="right" style={styles.tableCell}>
              {usesSyringeValue.YES || 0}
            </Table.Cell>
            <Table.Cell textAlign="right" style={styles.tableCell}>
              {utils.toPrettyPercent(usesSyringeValue.YES || 0, matchingNdcCount)}%
            </Table.Cell>
          </Table.Row>
          <Table.Row style={styles.tableRow}>
            <Table.Cell style={styles.tableCell}>WA State Dangerous</Table.Cell>
            <Table.Cell textAlign="right" style={styles.tableCell}>
              {isWaStateDangerous.X || 0}
            </Table.Cell>
            <Table.Cell textAlign="right" style={styles.tableCell}>
              {utils.toPrettyPercent(isWaStateDangerous.X || 0, matchingNdcCount)}%
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      )}
    </Table>
  );
};

AnalysisBooleanFields.propTypes = {
  isWteNonCompatible: PropTypes.object.isRequired,
  isWaStateDangerous: PropTypes.object.isRequired,
  usesSyringeValue: PropTypes.object.isRequired,
  matchingNdcCount: PropTypes.number.isRequired
};

const mapState = state => {
  const { matchingNdcCount, matchingNdcCountGroups } = state.ndcBounce;
  return { ...matchingNdcCountGroups, matchingNdcCount };
};

export default connect(mapState)(AnalysisBooleanFields);
