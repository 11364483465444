import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import colors from '../../../../styles/colors';

const Breadcrumb = ({ setSelectedItem, selectedItem, isCreate, isEdit, setEdit, setCreate, setError }) => (
  <div className="breadcrumb-container" style={{ color: colors.white }}>
    <button
      className="breadcrumb-item"
      style={{
        color: !selectedItem && !isCreate && !isEdit ? colors.white : colors.blueThree
      }}
      onClick={() => {
        setError();
        setSelectedItem(null);
        setCreate(false);
        setEdit(false);
      }}
    >
      Customers Pending
    </button>
    {!!selectedItem && (
      <>
        {isEdit && (
          <>
            <Icon name="chevron right" />
            <button
              className="breadcrumb-item"
              onClick={() => {
                setEdit(false);
                setError();
              }}
              style={{
                color: colors.blueThree
              }}
            >
              {selectedItem.name}
            </button>
          </>
        )}
        {!isEdit && (
          <>
            <Icon name="chevron right" />
            <span className="breadcrumb-item">{selectedItem.name}</span>
          </>
        )}
      </>
    )}
    {!!isCreate && (
      <>
        <Icon name="chevron right" />
        <span className="breadcrumb-item">Create Organization</span>
      </>
    )}
    {!!isEdit && (
      <>
        <Icon name="chevron right" />
        <span className="breadcrumb-item">Edit</span>
      </>
    )}
  </div>
);

Breadcrumb.defaultProps = {
  selectedItem: null
};

Breadcrumb.propTypes = {
  setSelectedItem: PropTypes.func.isRequired,
  selectedItem: PropTypes.object,
  isCreate: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
  setEdit: PropTypes.func.isRequired,
  setCreate: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
};

export default Breadcrumb;
