import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import birdseyeNavIcon from '../../../Assets/Images/birds-eye-nav-logo.png';

import BENavBarProfileDropdown from '../BENavBarProfileDropdown/BENavBarProfileDropdown';
import BENavBarDropdown from '../BENavBarDropdown/BENavBarDropdown';

import colors from '../../../styles/colors';

function BENavBar({ signOut, history }) {
  const navOptions = [
    {
      category: 'birdseye',
      key: 'app_control_center',
      value: 'app_control_center',
      text: 'Control Center',
      route: '/control-center',
      icon: birdseyeNavIcon
    },
    {
      category: 'birdseye',
      key: 'app_formulary_admin',
      value: 'app_formulary_admin',
      text: 'Formulary Admin',
      route: '/formulary-admin',
      icon: 'https://birdseye-app-icons.s3.amazonaws.com/formulary_160_160.png'
    }
  ];

  const { pathname } = history.location;
  const value = pathname.startsWith('/control-center') ? 'app_control_center' : 'app_formulary_admin';

  return (
    <div>
      <Menu style={styles.menu} fixed="top">
        <BENavBarDropdown value={value} options={navOptions} isDarkStyle/>

        <Menu.Menu position="right">
          <Menu.Item style={{ paddingTop: '0px', paddingBottom: '0px' }}>
            <BENavBarProfileDropdown onSignOut={signOut} />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    </div>
  );
}

export const styles = {
  menu: {
    backgroundColor: colors.blueDarkTwo,
    height: '70px'
  },
  logoContainer: {
    width: '100px'
  },
  logo: {
    maxHeight: '40px',
    maxWidth: '100px',
    display: 'block',
    margin: '0 auto'
  },
  itemText: {
    color: colors.white
  }
};

BENavBar.propTypes = {
  signOut: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(BENavBar);
