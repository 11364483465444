import React, { useState } from 'react';
import PropTypes from 'prop-types';

import colors from '../../../../styles/colors';
import ButtonText from '../../../../components/presentational/ButtonText/ButtonText';
import OrgForm from './OrgForm';

const DEFAULT_DATA = {
  name: '',
  tax_id_num: '',
  facility_name: '',
  phone_main: '',
  trilogy_org_num: '',
  parent_org_1: '',
  parent_org_2: '',
  logo_asset_url: '',
  address_line_1: '',
  address_line_2: '',
  city: '',
  state: '',
  zip_code: '',
  country: ''
};

const Create = ({ onSave, orgTypes }) => {
  const [newData, setNewData] = useState(DEFAULT_DATA);
  return (
    <div className="dark-form plan-form">
      <OrgForm data={newData} setData={setNewData} orgTypes={orgTypes} />
      <ButtonText
        className="submit-btn"
        customStyles={{
          backgroundColor: colors.blueThree,
          color: colors.white
        }}
        onClick={() => onSave(newData)}
      >
        Create
      </ButtonText>
    </div>
  );
};

Create.propTypes = {
  onSave: PropTypes.func.isRequired,
  orgTypes: PropTypes.array.isRequired
};

export default Create;
