import React from 'react';
import PropTypes from 'prop-types';

import colors from '../../styles/colors';

const SidebarSectionHeader = ({ children }) => {
  return (
    <div
      id="SidebarSectionHeader"
      className="SidebarSectionHeader"
      style={styles.wrapper}
    >
      {children}
    </div>
  );
};

const styles = {
  wrapper: {
    color: colors.blueTwo,
    display: 'block',
    fontFamily: 'Nunito-Bold',
    fontSize: '12px',
    lineHeight: '28px',
    padding: '8px 0px 8px 15px',
    textTransform: 'uppercase'
  }
};

SidebarSectionHeader.propTypes = {
  children: PropTypes.node.isRequired
};

export default SidebarSectionHeader;
