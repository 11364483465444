import { Auth } from 'aws-amplify';
import Axios from 'axios';

const formularyHost = process.env.REACT_APP_FORMULARY_API_URL;
const birdseyeHost = process.env.REACT_APP_BIRDSEYE_API_URL;

const MANAGMENT_API_VERSION = 'v0';
const API_VERSION = 'v0';

const getOptions = async options => {
  const session = await Auth.currentSession();
  return {
    ...(options || {}),
    headers: {
      Authorization: session.idToken.jwtToken,
      ...((options && options.headers) || {})
    }
  };
};

const API = {
  managmentGet: async (path, options) =>
    Axios.get(`${birdseyeHost}/${MANAGMENT_API_VERSION}/${path}`, await getOptions(options)),
  managmentPost: async (path, data, options) =>
    Axios.post(`${birdseyeHost}/${MANAGMENT_API_VERSION}/${path}`, data, await getOptions(options)),
  managmentPatch: async (path, data, options) =>
    Axios.patch(`${birdseyeHost}/${MANAGMENT_API_VERSION}/${path}`, data, await getOptions(options)),
  managmentDelete: async (path, options) =>
    Axios.delete(`${birdseyeHost}/${MANAGMENT_API_VERSION}/${path}`, await getOptions(options)),

  get: async (path, options) => Axios.get(`${formularyHost}/${API_VERSION}/${path}`, await getOptions(options)),
  post: async (path, data, options) =>
    Axios.post(`${formularyHost}/${API_VERSION}/${path}`, data, await getOptions(options)),
  patch: async (path, data, options) =>
    Axios.patch(`${formularyHost}/${API_VERSION}/${path}`, data, await getOptions(options)),
  delete: async (path, options) => Axios.delete(`${formularyHost}/${API_VERSION}/${path}`, await getOptions(options))
};

export default API;
