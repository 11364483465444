import React from 'react';
import PropTypes from 'prop-types';
import periodicTableElementsData from '../../../utils/periodicTableElementsData';
import './styles.scss';

const PeriodicTable = ({ setSeleted, selected }) => {
  const elementClass = element => {
    return selected.includes(element.symbol) ? 'element selected' : 'element';
  };

  const trClass = period => (period.length ? '' : 'empty');

  return (
    <table className="periodic-table">
      <tbody>
        {periodicTableElementsData.map(period => (
          <tr className={trClass(period)}>
            {period.map(element =>
              element ? (
                <td
                  className={elementClass(element)}
                  style={{ backgroundColor: element.color }}
                  onClick={() => setSeleted(element.symbol)}
                >
                  <span className="number">{element.number}</span>
                  <span className="symbol">{element.symbol}</span>
                  <span className="name">{element.name}</span>
                </td>
              ) : (
                <td className="element transparent" />
              )
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

PeriodicTable.propTypes = {
  setSeleted: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired
};

export default PeriodicTable;
