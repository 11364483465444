import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../redux/actionCreators';

import sassStyles from './ControlsResultsWrapper.module.scss';

function ControlsResultsWrapper({
  filterComponent,
  resultsComponent,
  showControls
}) {
  const isTablet = useMediaQuery({ query: '(max-width: 1224px)' });

  const mobileStyles = {
    position: 'fixed',
    top: '0px',
    left: !showControls && isTablet ? '-288px' : '0px',
    bottom: '0px',
    zIndex: 102
  };
  return (
    <>
      <div
        className={sassStyles.Panel}
        style={{ ...styles.filterSection, ...(isTablet && mobileStyles) }}
      >
        {filterComponent}
      </div>

      <div style={styles.resultsSection}>{resultsComponent}</div>
    </>
  );
}

ControlsResultsWrapper.propTypes = {
  filterComponent: PropTypes.node.isRequired,
  resultsComponent: PropTypes.node.isRequired,
  showControls: PropTypes.bool.isRequired
};

const styles = {
  filterSection: {
    width: '288px',
    marginRight: '32px'
  },
  resultsSection: {
    flex: 1
  }
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state, props) => {
  const { showControls } = state.view;

  return {
    ...props,
    showControls
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ControlsResultsWrapper);
