import React, { useState, useEffect, useCallback } from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import colors from '../../../../styles/colors';
import SitePlanModal from './SitePlanModal';
import ButtonText from '../../../../components/presentational/ButtonText/ButtonText';
import { getSitePlans } from '../../../../API/plans';

const LocationDetailView = ({ data, setError }) => {
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [currentPlan, setCurrentPlan] = useState();

  const getCurrentPlan = async () => {
    const plans = await getSitePlans(data.id);
    getSitePlans(data.id);
    setCurrentPlan(plans.data && plans.data[0]);
  };

  useEffect(() => {
    getCurrentPlan();
  }, []);

  return (
    <div>
      <div className="detail-header">
        <div>
          <ButtonText
            className="formulary-link"
            customStyles={{
              backgroundColor: colors.blueThree,
              color: colors.white
            }}
            onClick={() => {
              const { org_domain } = data.organization_subdomain;
              const hostParts = window.location.host.split('.');
              const baseHost = hostParts.slice(hostParts.length - 2).join('.');
              const path = `${data.id}/formulary`;

              window.open(`${window.location.protocol}//${org_domain}.${baseHost}/${path}`, '_blank');
            }}
          >
            Formulary App
          </ButtonText>
          <div className="plan-detail">
            <span>Plan: {currentPlan ? currentPlan.plan.name : 'Full Access'}</span>
            <ButtonText
              className="formulary-link"
              customStyles={{
                backgroundColor: colors.blueThree,
                color: colors.white
              }}
              onClick={() => setShowPlanModal(true)}
            >
              Set plan
            </ButtonText>
          </div>
        </div>
        <Table basic="very" columns={2} compact singleLine size="small" className="table main-table totals-table">
          <Table.Body className="body">
            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                Total Users
              </Table.HeaderCell>
              <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
                {data.member_count}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>

      <div className="list-container fade flex-2">
        <Table basic="very" className="table main-table" columns={3} compact singleLine size="small">
          <Table.Body className="body">
            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                Acronym
              </Table.HeaderCell>
              <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
                {data.acronym}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                Name
              </Table.HeaderCell>
              <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
                {data.name}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                Facility Name
              </Table.HeaderCell>
              <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
                {data.facility_name}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                Site Classifier
              </Table.HeaderCell>
              <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
                {data.site_classifier && data.site_classifier.name}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                Partnership Name
              </Table.HeaderCell>
              <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
                {data.partnership_name}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                Fax Num
              </Table.HeaderCell>
              <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
                {data.fax_num}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                Main Phone
              </Table.HeaderCell>
              <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
                {data.phone_main}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                GL Code
              </Table.HeaderCell>
              <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
                {data.gl_code}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                Is HQ
              </Table.HeaderCell>
              <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
                {data.is_hq ? 'Yes' : 'No'}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                Subscription Active
              </Table.HeaderCell>
              <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
                {data.subscription_active ? 'Yes' : 'No'}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                COST CENTER NUM
              </Table.HeaderCell>
              <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
                {data.cost_center_num}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Table basic="very" className="table main-table" columns={3} compact singleLine size="small">
          <Table.Body className="body">
            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                Address
              </Table.HeaderCell>
              <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
                {data.address.address_line_1} {data.address.address_line_2}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                City
              </Table.HeaderCell>
              <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
                {data.address.city}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                State
              </Table.HeaderCell>
              <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
                {data.address.state}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                Zip
              </Table.HeaderCell>
              <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
                {data.address.zip_code}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                Region Name
              </Table.HeaderCell>
              <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
                {data.region_name}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                County
              </Table.HeaderCell>
              <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
                {data.county}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                TAX ID #
              </Table.HeaderCell>
              <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
                {data.tax_id_num}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                BIRDEYES CUSTOMER #
              </Table.HeaderCell>
              <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
                {data.behc_site_num}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                TRILOGY CUSTOMER #
              </Table.HeaderCell>
              <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
                {data.trilogy_site_num}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="row">
              <Table.HeaderCell
                className="cell header-cell"
                style={{ backgroundColor: colors.blueTwo, color: colors.white }}
                rowSpan="1"
              >
                TRILOGY SITE ID
              </Table.HeaderCell>
              <Table.Cell className="cell" style={{ backgroundColor: colors.blueDarkTwo, color: colors.white }}>
                {data.trilogy_site_id}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
      {!!showPlanModal && (
        <SitePlanModal
          onClose={() => setShowPlanModal(false)}
          onUpdate={() => {
            getCurrentPlan();
            setShowPlanModal(false);
          }}
          currentPlan={currentPlan}
          siteId={data.id}
          orgId={data.org_id}
          setError={setError}
        />
      )}
    </div>
  );
};

LocationDetailView.propTypes = {
  data: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired
};

export default LocationDetailView;
