export const groupings = {
  'Birds Eye View': [
    'ndc_10_spaces',
    'product_name',
    'substance_name',
    'dosage_form',
    'route',
    'strength',
    'regulatory_return_policy',
    'regulatory_return_policy_reason',
    'return_policy_details',
    'proper_disposal_1',
    'proper_disposal_2',
    'if_empty',
    'proper_disposal_special_instructions',
    'waste_class',
    'main_reason',
    'dea_schedule'
  ],
  All: [
    'upc',
    'alt_upc_code',
    'ndc_11',
    'ndc_11_spaces',
    'ndc_10_spaces',
    'ndc_10',
    'dosage_form',
    'route',
    'strength',
    'product_name',
    'substance_name',
    'manufacturer',
    'has_patent',
    'patent_number',
    'patent_expiration_date',
    'linked_ndc',
    'waste_class',
    'epa_waste_code',
    'waste_stream',
    'main_reason',
    'packaging_description',
    'start_marketing_date',
    'end_marketing_date',
    'disposal_acronym',
    'single_or_multi_dose_value',
    'preserverative',
    'glass_or_plastic_value',
    'plastic_type',
    'uses_syringe_value',
    'storage_temperature_range',
    'permitted_temperature_excursions',
    'is_biohazardous',
    'is_carcinogen',
    'is_chemo',
    'is_endocrine_disruptor',
    'is_osha',
    'is_niosh_1',
    'is_niosh_2',
    'is_msds_indicated',
    'is_dea_control',
    'dea_schedule',
    'dea_number',
    'is_dea_list_1',
    'alcohol_percentage',
    'ph_range',
    'flash_point',
    'is_wte_non_compatible',
    'wte_main_reason',
    'is_wa_state_dangerous',
    'molecular_formula_api',
    'pill_imprint_1',
    'pill_imprint_2',
    'shape',
    'color',
    'size',
    'scored_value',
    'pregnancy_category',
    'dermal_ld_50',
    'inhalation_ld_50',
    'intravenous_ld_50',
    'ld_50',
    'is_bmp_selected',
    'dot_class',
    'notes',
    'regulatory_return_policy',
    'regulatory_return_policy_reason',
    'return_policy_details',
    'proper_disposal_1',
    'proper_disposal_2',
    'if_empty',
    'proper_disposal_special_instructions',
    'shippable_value',
    // 'picture_1',
    // 'picture_2',
    'date_added',
    'needs_review',
    'last_reviewed_date'
  ],
  'Regulatory Info': [
    'waste_class',
    'epa_waste_code',
    'waste_stream',
    'main_reason',
    'packaging_description',
    'start_marketing_date',
    'end_marketing_date',
    'disposal_acronym',
    'dot_class',
    'is_wte_non_compatible',
    'wte_main_reason',
    'is_wa_state_dangerous',
    'molecular_formula_api'
  ],
  'Product Info': [
    'product_name',
    'substance_name',
    'manufacturer',
    'has_patent',
    'patent_number',
    'patent_expiration_date',
    'linked_ndc'
  ],
  'UPC and NDC Codes': [
    'upc',
    'alt_upc_code',
    'ndc_11',
    'ndc_11_spaces',
    'ndc_10_spaces',
    'ndc_10'
  ],
  'Returns and Disposal Info': [
    'regulatory_return_policy',
    'regulatory_return_policy_reason',
    'return_policy_details',
    'proper_disposal_1',
    'proper_disposal_2',
    'if_empty',
    'proper_disposal_special_instructions',
    'shippable_value'
  ],
  'Product Characteristics': [
    'single_or_multi_dose_value',
    'preserverative',
    'glass_or_plastic_value',
    'plastic_type',
    'uses_syringe_value',
    'storage_temperature_range',
    'permitted_temperature_excursions',
    'is_biohazardous',
    'is_carcinogen',
    'is_chemo',
    'is_endocrine_disruptor',
    'is_osha',
    'is_niosh_1',
    'is_niosh_2',
    'is_msds_indicated',
    'alcohol_percentage',
    'ph_range',
    'flash_point',
    'pregnancy_category',
    'dermal_ld_50',
    'inhalation_ld_50',
    'intravenous_ld_50',
    'ld_50',
    'is_bmp_selected'
  ],
  'DEA Regulatory': [
    'is_dea_control',
    'dea_schedule',
    'dea_number',
    'is_dea_list_1'
  ],
  'How Supplied': ['dosage_form', 'route', 'strength'],
  'Pill Identifier': [
    'pill_imprint_1',
    'pill_imprint_2',
    'shape',
    'color',
    'size',
    'scored_value'
  ],
  Admin: ['date_added', 'needs_review', 'last_reviewed_date', 'notes']
};

export const displayNames = {
  upc: 'UPC Code',
  alt_upc_code: 'ALT UPC Code',
  ndc_11: '11 Digit NDC # (no spaces)',
  ndc_11_spaces: '11 Digit NDC # (w spaces)',
  ndc_10_spaces: '10 Digit NDC # (w spaces)',
  ndc_10: '10 Digit NDC # (no spaces)',
  dosage_form: 'Dosage Form',
  route: 'Route',
  strength: 'Strength',
  product_name: 'Product Name (Proprietary)',
  substance_name: 'Substance Name (Generic)',
  manufacturer: 'Manufacturer / Labeler Name',
  has_patent: 'Patent or Generic',
  patent_number: 'Patent Number',
  patent_expiration_date: 'Patent Expiration Date',
  linked_ndc: 'Source / Linked NDC #',
  waste_class: 'Waste Class',
  epa_waste_code: 'EPA Waste Code',
  waste_stream: 'Waste Stream',
  main_reason: 'Main Reason',
  packaging_description: 'Packaging Description',
  start_marketing_date: 'Start Marketing Date',
  end_marketing_date: 'End Marketing Date',
  disposal_acronym: 'Disposal Acronym',
  single_or_multi_dose_value: 'Single-Dose or Multi-Dose',
  preserverative: 'Preservative',
  glass_or_plastic_value: 'Glass or Plastic',
  plastic_type: 'Type of Plastic',
  uses_syringe_value: 'Syringe (Yes or No)',
  storage_temperature_range: 'Storage Temperature Range (F/C)',
  permitted_temperature_excursions: 'Permitted Temperature Excursions (F/C)',
  is_biohazardous: 'Biohazardous',
  is_carcinogen: 'Carcinogen',
  is_chemo: 'Chemo',
  is_endocrine_disruptor: 'Endocrine Disruptor',
  is_osha: 'OSHA',
  is_niosh_1: 'NIOSH 1',
  is_niosh_2: 'NIOSH 2',
  is_msds_indicated: 'MSDS Indicated',
  is_dea_control: 'DEA Control',
  dea_schedule: 'DEA Schedule',
  dea_number: 'DEA Number',
  is_dea_list_1: 'DEA List 1',
  is_wte_non_compatible: 'WTE Non-Compatible',
  is_wa_state_dangerous: 'WA State Dangerous',
  molecular_formula_api: 'Molecular Formula API',
  alcohol_percentage: '% Alcohol',
  ph_range: 'PH Range',
  flash_point: 'Flash Point',
  pill_imprint_1: 'Pill Imprint 1',
  pill_imprint_2: 'Pill Imprint 2',
  shape: 'Shape',
  color: 'Color',
  size: 'Size',
  scored_value: 'Scored (Yes or NO)',
  pregnancy_category: 'Pregnancy Category',
  dermal_ld_50: 'LD/50 Dermal',
  inhalation_ld_50: 'LD/50 Inhalation',
  intravenous_ld_50: 'LD/50 Intravenous',
  ld_50: 'LD/50 Oral',
  is_bmp_selected: 'BMP Selected',
  dot_class: 'DOT Class',
  wte_main_reason: 'WTE Main Reason',
  notes: 'Notes',
  regulatory_return_policy: 'Regulatory Return Policy',
  regulatory_return_policy_reason: 'Regulatory Return Policy Reason',
  return_policy_details: 'Return Policy Details',
  proper_disposal_1: 'Proper Disposal 1',
  proper_disposal_2: 'Proper Disposal 2',
  if_empty: 'If Empty',
  proper_disposal_special_instructions: 'Proper Disposal Special Instructions',
  shippable_value: 'Can this item be shipped in the Mail as waste (Yes or No)',
  picture_1: 'Picture 1 (Packaging / Label)',
  picture_2: 'Picture 2 (Unit Dose)',
  date_added: 'Date Added',
  needs_review: 'Needs Review',
  last_reviewed_date: 'Last Reviewed Date'
};

export const types = {
  upc: 'text',
  alt_upc_code: 'text',
  ndc_11: 'text',
  ndc_11_spaces: 'text',
  ndc_10_spaces: 'text',
  ndc_10: 'text',
  dosage_form: 'dropdown',
  route: 'dropdown',
  strength: 'dropdown',
  product_name: 'dropdown',
  substance_name: 'dropdown',
  manufacturer: 'dropdown',
  has_patent: 'bool',
  patent_number: 'text',
  patent_expiration_date: 'dropdown_date',
  linked_ndc: 'text',
  waste_class: 'dropdown',
  epa_waste_code: 'dropdown',
  waste_stream: 'dropdown',
  main_reason: 'dropdown',
  packaging_description: 'text',
  start_marketing_date: 'dropdown_date',
  end_marketing_date: 'dropdown_date',
  disposal_acronym: 'dropdown',
  single_or_multi_dose_value: 'dropdown',
  preserverative: 'dropdown',
  glass_or_plastic_value: 'dropdown',
  plastic_type: 'dropdown',
  uses_syringe_value: 'dropdown',
  storage_temperature_range: 'dropdown',
  permitted_temperature_excursions: 'dropdown',
  is_biohazardous: 'bool',
  is_carcinogen: 'bool',
  is_chemo: 'bool',
  is_endocrine_disruptor: 'bool',
  is_osha: 'bool',
  is_niosh_1: 'bool',
  is_niosh_2: 'bool',
  is_msds_indicated: 'bool',
  is_dea_control: 'bool',
  dea_schedule: 'dropdown',
  dea_number: 'dropdown',
  is_dea_list_1: 'bool',
  alcohol_percentage: 'dropdown',
  ph_range: 'dropdown',
  flash_point: 'dropdown',
  pill_imprint_1: 'text',
  pill_imprint_2: 'text',
  shape: 'dropdown',
  color: 'dropdown',
  size: 'dropdown',
  scored_value: 'dropdown',
  pregnancy_category: 'dropdown',
  dermal_ld_50: 'dropdown',
  inhalation_ld_50: 'dropdown',
  intravenous_ld_50: 'dropdown',
  ld_50: 'dropdown',
  is_bmp_selected: 'bool',
  dot_class: 'dropdown',
  wte_main_reason: 'dropdown',
  notes: 'text',
  regulatory_return_policy: 'dropdown',
  regulatory_return_policy_reason: 'dropdown',
  return_policy_details: 'text',
  proper_disposal_1: 'text',
  proper_disposal_2: 'text',
  if_empty: 'text',
  proper_disposal_special_instructions: 'text',
  shippable_value: 'dropdown',
  picture_1: 'text',
  picture_2: 'text',
  date_added: 'dropdown_date',
  needs_review: 'text',
  last_reviewed_date: 'date',
  is_wte_non_compatible: 'bool',
  is_wa_state_dangerous: 'bool',
  molecular_formula_api: 'dropdown',
};

export const tableNames = {
  dosage_form: 'dosage_forms',
  route: 'routes',
  strength: 'strengths',
  product_name: 'product_names',
  substance_name: 'substance_names',
  manufacturer: 'manufacturers',
  patent_expiration_date: 'patent_expiration_dates',
  waste_class: 'waste_classes',
  epa_waste_code: 'epa_waste_codes',
  waste_stream: 'waste_streams',
  main_reason: 'main_reasons',
  start_marketing_date: 'start_marketing_dates',
  end_marketing_date: 'end_marketing_dates',
  disposal_acronym: 'disposal_acronyms',
  single_or_multi_dose_value: 'single_or_multi_dose_values',
  preserverative: 'preserveratives',
  glass_or_plastic_value: 'glass_or_plastic_values',
  plastic_type: 'plastic_types',
  uses_syringe_value: 'uses_syringe_values',
  storage_temperature_range: 'storage_temperature_ranges',
  permitted_temperature_excursions: 'permitted_temperature_excursions',
  dea_schedule: 'dea_schedules',
  dea_number: 'dea_numbers',
  alcohol_percentage: 'alcohol_percentages',
  ph_range: 'ph_ranges',
  flash_point: 'flash_points',
  shape: 'shapes',
  color: 'colors',
  size: 'sizes',
  scored_value: 'scored_values',
  pregnancy_category: 'pregnancy_categories',
  ld_50: 'ld_50s',
  dermal_ld_50: 'dermal_ld_50s',
  inhalation_ld_50: 'inhalation_ld_50s',
  intravenous_ld_50: 'intravenous_ld_50s',
  dot_class: 'dot_classes',
  wte_main_reason: 'wte_main_reasons',
  molecular_formula_api: 'molecular_formula_apis',
  regulatory_return_policy: 'regulatory_return_policies',
  regulatory_return_policy_reason: 'regulatory_return_policy_reasons',
  shippable_value: 'shippable_values',
  date_added: 'dates_added'
};
