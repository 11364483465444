import React from 'react';
import PropTypes from 'prop-types';

function PageContent({ children }) {
  return <div style={styles.container}>{children}</div>;
}

PageContent.propTypes = {
  children: PropTypes.node.isRequired
};

const styles = {
  container: {
    padding: '28px 3vw 80px 3vw',
    display: 'flex'
  }
};
export default PageContent;
